import React from "react";
import PageLayout from "../../common/layouts/PageLayout";
import { Grid, Section, Text } from "pws-design-system/design-system";

type FourOhFourProps = any & {};

export default function FourOhFour({ props }: FourOhFourProps): React.ReactElement {
  return (
    <PageLayout>
      <Section minHeight="100vh">
        <Grid>
          <Text variant="headline">Page not found.</Text>
        </Grid>
      </Section>
    </PageLayout>
  );
}
