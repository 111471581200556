import React from "react";
import { Box, Flex, Heading, Text, Button } from "pws-design-system/design-system";
import { PlaceOuterLayout } from "./common/Layout";

interface DashboardPlace404Props {
  placeDisplayName: string;
}

export default function DashboardPlace404({
  placeDisplayName = "Location"
}: DashboardPlace404Props): React.ReactElement {
  return (
    <PlaceOuterLayout>
      <Box overflow="auto" pt="100px" color="black">
        <Heading variant="hero" mt={10}>
          {placeDisplayName.toUpperCase()} could not be found.
        </Heading>
        <Text mt={3}>We do not recognize this station ID or it hasn't sent us data yet.</Text>
        <Button mt={5}>Search for stations</Button>
      </Box>
    </PlaceOuterLayout>
  );
}
