import React, { useEffect } from "react";
import DashboardChart from "./DashboardChart";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

interface TempChartProps {
  collection: ChartObservations;
}

export default function PercentageChart({ collection, date, period, config }: TempChartProps) {
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;

  const labelConfigCallback = (value: string) => {
    return `${value}%`;
  };

  const datasetConfigs = [
    {
      data: collection.humidity,
      label: "Humidity",
      key: "humidity",
      borderColor: dataColors.humidity,
      pointBackgroundColor: dataColors.humidity
    },
    {
      data: collection.precipProb,
      label: "Precip Probability",
      key: "precipProb",
      borderColor: dataColors.pop,
      pointBackgroundColor: dataColors.pop
    },
    {
      data: collection.skyCover,
      label: "Sky Cover",
      key: "skyCover",
      borderColor: dataColors.skycover,
      pointBackgroundColor: dataColors.skycover
    }
  ];

  return (
    <DashboardChart
      period={period}
      date={date}
      chartConfig={config}
      datasetConfigs={datasetConfigs}
      labelConfigCallback={labelConfigCallback}
      collection={collection}
    />
  );
}
