import React, { useEffect } from "react";
import _merge from "lodash.merge";
import _cloneDeep from "lodash.clonedeep";
import Chart, { yAxisLabelBuilder, datasetConfigBuilder } from "./Chart";
import { addHours, startOfDay, addDays, format } from "date-fns";
import Collection from "../../../../../models/Collection";
import { CardPeriod } from "../../../../../types/enums";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

interface DashboardChartProps {
  collection: Collection;
  date: string;
  period: CardPeriod;
  chartConfig: any;
  labelConfigCallback: () => string;
  datasetConfigs: any;
  startsAtZero: boolean;
}

export default function DashboardChart({
  collection,
  date,
  period,
  chartConfig,
  labelConfigCallback,
  datasetConfigs,
  startsAtZero = false
}: DashboardChartProps) {
  const labelBuilder = {
    daily: () =>
      new Array(25)
        .fill(startOfDay(new Date()))
        .map((newDate, i) => format(addHours(newDate, i), "h a")),
    monthly: (startDate: string) =>
      new Array(31).fill("").map((_, i) => {
        return format(addDays(new Date(startDate), i), "MMM dd");
      })
  };

  let options = yAxisLabelBuilder(labelConfigCallback);
  if (startsAtZero) {
    options = _merge(_cloneDeep(options), {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0
            }
          }
        ]
      }
    });
  }
  if (datasetConfigs[0].key == "windDirection") {
    options = _merge(_cloneDeep(options), {
      scales: {
        yAxes: [
          {
            ticks: {
              max: 360,
              min: 0,
              stepSize: 90,
              maxTicksLimit: 5
            }
          }
        ]
      }
    });
  }

  const config = _merge(_cloneDeep(chartConfig), {
    options,
    data: {
      labelBuilder,
      // labels: labelBuilder[period](date),
      datasets: datasetConfigs.map((datasetConfig: any) => {
        const result = datasetConfigBuilder(datasetConfig);
        return result;
      })
    }
  });

  return <Chart period={period} date={date} config={config} collection={collection} />;
}
