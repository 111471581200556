import React, { useState } from "react";
import DropdownSelect from "../../../../common/components/dropdown-select";
import { navigate } from "gatsby";

export default function MobileNavDropdown({ path }: MobileNavDropdownProps): React.ReactElement {
  const items = Object.keys(path.mobileUrls).map(key => {
    return {
      key: key,
      text: key === "dashboard" ? "latest observations" : key,
      url: path.mobileUrls[key]
    };
  });

  const getActiveItem = key => {
    return items.filter(item => item.key === key)[0];
  };

  const handleSelect = (url: string) => {
    const newItem = getActiveItem(url);
    setActiveItem(newItem);
    navigate(newItem.url);
  };

  const [activeItem, setActiveItem] = useState(getActiveItem(path.activeUrl));
  return (
    <DropdownSelect
      items={items}
      place={path.place[0]}
      initialActiveItem={activeItem}
      selectHandler={handleSelect}
    />
  );
}
