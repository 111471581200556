import { useEffect, useState } from "react";
import aeris from "../../../../../../api/";
import Place from "../../../../../../models/aeris/place/";
import NearbyStations from "../../../../../../models/aeris/nearby-stations";
import _cloneDeep from "lodash.clonedeep";
import useFetchStateMachine from "../../../../../common/hooks/useFetchStateMachine";
import _get from "lodash.get";
import _every from "lodash.every";
import { RefreshRates } from "../../../../../../types/enums";

interface useQueryProps extends CardProps {
  placeId: string;
  initialPlace?: Place;
}

export default function useNearbyStationsQuery({
  placeId,
  initialPlace = new Place({ placeId })
}: useQueryProps) {
  const [place, setPlace] = useState(initialPlace);
  const [current, send] = useFetchStateMachine();

  const placeApi = aeris.api();
  const observationsApi = aeris.api();
  useEffect(() => {
    async function fetchPlace() {
      send("LOADING");
      const request = placeApi
        .endpoint("places")
        .place(placeId)
        // .fields("loc.lat,loc.long")
        .format("json")
        .radius("50mi")
        .limit(1);

      const response = await request.get();
      return response;
    }

    async function fetchObservations() {
      const request = observationsApi
        .endpoint("observations")
        .action("closest")
        .place(placeId)
        // .fields(
        //   "id,ob.tempF,ob.tempC,loc.lat,loc.long,ob.dewpointF,ob.dewpointC,ob.windMPH,ob.windKPH,ob.pressureMB,ob.pressureIN,ob.precipMM,ob.precipIN"
        // )
        .format("json")
        .radius("50mi")
        .filter("precise,allownosky,metar;pws;madis;ausbom")
        .limit(250);
      const response = await request.get();
      return response;
    }

    async function fetchData() {
      const [observations, placeResponse] = await Promise.all([fetchObservations(), fetchPlace()]);
      const place = new Place({
        placeId,
        data: placeResponse.data
      });

      const obsData =
        observations.data && Array.isArray(observations.data) ? observations.data : [];
      place.nearbyStations = new NearbyStations({
        placeId,
        data: obsData.length > 0 ? obsData.slice(1) : []
      });
      const successes = [observations, placeResponse].map(response =>
        _get(response, "response.data.success", false)
      );
      const success = _every(successes, success => success === true);
      if (success === true) {
        send("LOADED");
        setPlace(place);
      } else {
        send("ERROR");
      }
    }

    fetchData();
    const heartbeatHandle = window.setInterval(
      fetchData,
      RefreshRates.NearbyStationsCard * 60 * 1000
    );
    return () => window.clearInterval(heartbeatHandle);
  }, [placeId]);

  return [place, current];
}
