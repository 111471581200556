import React from "react";
import {
  Box,
  Col,
  Flex,
  Heading,
  Hide,
  Row,
  Text,
  Icon,
  Stack
} from "pws-design-system/design-system";
import ActiveFlag from "./dashboard/status-flag";
import Place from "../../../models/aeris/place";
import Time from "../../common/components/time/";
import UnitToggle from "../../common/components/unit-toggle/";
import ErrorBoundary from "../../common/components/error-boundary";
import { ThemeContainer } from "../../common/hooks/useTheme";
import { UnitStoreContainer } from "../../common/hooks/stores/useUnitStore";

interface StationDashboardHeaderProps {
  place: Place;
}

export default function StationDashboardHeader({
  place,
  type
}: StationDashboardHeaderProps): React.ReactElement {
  const { theme } = ThemeContainer.useContainer();
  const { units, unitType, setUnit, setUnitType } = UnitStoreContainer.useContainer();

  const elevationString =
    units.height === "ft"
      ? `${Math.round(place.elevationFT)} ft`
      : `${Math.round(place.elevationM)} m`;

  return (
    <Row pb={3} align="flex-end">
      <Col xs={12}>
        <Flex justify="space-between" align="center">
          <Box>
            {place.has("displayId") && (
              <Stack spacing={3} isInline>
                <Heading
                  variant="hero"
                  mb={0}
                  textTransform="uppercase"
                  color={theme.colors.text.base.primary}
                >
                  {place.placeType === "station" ? place.displayId : place.formattedPlaceShort}
                </Heading>
                {type === "station" && (
                  <ActiveFlag
                    id={place.type === "pws" ? place.displayId : place.id}
                    type={place.type}
                  />
                )}
              </Stack>
            )}

            {place.has("formattedPlace") && (
              <Text
                variant="title2"
                fontWeight="medium"
                color={theme.colors.text.base.secondary}
                mb={1}
              >
                {place.formattedPlace}
              </Text>
            )}
            <Stack spacing={4} isInline>
              {place.has("latAndLonString") && (
                <Text variant="title3" fontWeight="medium" color={theme.colors.text.base.secondary}>
                  {place.latAndLonString}
                </Text>
              )}
              {place.has("timezone") && (
                <Stack isInline>
                  <ErrorBoundary>
                    <Text variant="caption1" color={theme.colors.text.base.secondary}>
                      Local Time:{" "}
                    </Text>
                    <Text
                      variant="title3"
                      fontWeight="medium"
                      color={theme.colors.text.base.secondary}
                      ml={1}
                    >
                      <Time
                        config={{
                          hour: "numeric",
                          minute: "2-digit",
                          timeZone: place.timezone
                        }}
                        postProcessor={(val: string) => val.toLowerCase()}
                      />
                    </Text>
                  </ErrorBoundary>
                </Stack>
              )}
            </Stack>
            {place.has("elevationFT") && (
              <Text
                variant="title3"
                fontWeight="medium"
                color={theme.colors.text.base.secondary}
                mb={1}
              >
                Elev: {elevationString}
              </Text>
            )}
          </Box>
          {place.has("type") && (
            <Hide mobile>
              <Flex height={"100%"} align="center" justify="flex-end">
                <Box textAlign="right">
                  <Flex align="center">
                    <Text
                      mt="8px"
                      mr={2}
                      variant="title3"
                      color={theme.components.dashboard.header.stationTypeLabel.color}
                    >
                      {place.type.toUpperCase()}
                    </Text>
                    <Icon name={`station-${place.type}`} size="40px" />
                  </Flex>
                </Box>
              </Flex>
            </Hide>
          )}
        </Flex>
        <Hide tablet desktop>
          <Box my={2} bg="brand.gray.300" height="2px" />
          <Flex mb={3} align="center" justify="space-between">
            <Box textAlign="right">
              {place.has("type") && (
                <Flex align="center">
                  <Icon name={`station-${place.type}`} size="40px" />
                  <Hide xs>
                    <Text mt="8px" ml={2} variant="title3">
                      {place.type.toUpperCase()}
                    </Text>
                  </Hide>
                </Flex>
              )}
            </Box>
            <UnitToggle
              showSettings={true}
              context="components.dashboard.header.unitToggle"
            ></UnitToggle>
          </Flex>
        </Hide>
      </Col>
    </Row>
  );
}
