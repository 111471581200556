import React, { useState, useEffect } from "react";
import SiteFourOhFour from "./SitePlace404";
import FourOhFour from "../../pages/404/";
import DashboardPage from "./dashboard";
import { Redirect } from "@reach/router";
import _get from "lodash.get";
import _startcase from "lodash.startcase";
import _lowercase from "lodash.lowercase";
import _some from "lodash.some";
import pathFactory from "../../../models/path";
import MapPage from "../map/Map";
import SiteForecast from "./forecast/Forecast";
import HistoricalPage from "./historical-page/";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import State from "../../common/components/state/";
import aeris from "../../../api";
import Location from "../../../models/aeris/place/Location";
import Station from "../../../models/aeris/place/Station";
import { PlaceOuterLayout } from "./common/Layout";
import Response from "../../../models/aeris/response";
import { Providers } from "../../common/layouts/Layout";

interface DashboardProps {
  "*": string;
  location: Location;
}

const machine = Machine({
  id: "machine",
  initial: "start",
  on: {
    pageChange: "start"
  },
  states: {
    start: {
      on: {
        splatInvalid: "redirected",
        splatValid: "validatingPath"
      }
    },
    validatingPath: {
      on: {
        pathIsInvalid: "fourOhFour",
        pathValid: "placeLoading"
      }
    },
    fourOhFour: {},
    redirected: {},
    placeLoading: {
      on: {
        placeLoaded: "placeLoaded",
        placeErrored: "fourOhFour",
        pathChange: "validatingPath",
        pageChange: "start"
      }
    },
    placeLoaded: {
      on: {
        pathChange: "validatingPath"
      }
    }
  }
});

function PlaceDisplay(props: DashboardProps): React.ReactElement {
  const [path, setPath] = useState();
  const [loaded, setLoaded] = useState(false);
  const [place, setPlace] = useState();
  const [current, send] = useMachine(machine);

  const manifest = props.pageContext.manifest;
  manifest.start_url = props.location.href;
  manifest.icons = [
    {
      src: `${props.location.origin}/icons/icon-48x48.png`,
      sizes: "48x48",
      type: "image/png"
    },
    {
      src: `${props.location.origin}/icons/icon-72x72.png`,
      sizes: "72x72",
      type: "image/png"
    },
    {
      src: `${props.location.origin}/icons/icon-96x96.png`,
      sizes: "96x96",
      type: "image/png"
    },
    {
      src: `${props.location.origin}/icons/icon-144x144.png`,
      sizes: "144x144",
      type: "image/png"
    },
    {
      src: `${props.location.origin}/icons/icon-192x192.png`,
      sizes: "192x192",
      type: "image/png"
    },
    {
      src: `${props.location.origin}/icons/icon-256x256.png`,
      sizes: "256x256",
      type: "image/png"
    },
    {
      src: `${props.location.origin}/icons/icon-384x384.png`,
      sizes: "384x384",
      type: "image/png"
    },
    {
      src: `${props.location.origin}/icons/icon-512x512.png`,
      sizes: "512x512",
      type: "image/png"
    }
  ];

  function validateSplat() {
    const splat = props["*"];
    // if splat is empty, we're at a root page like /station or /local.
    if (splat === "") {
      send("splatInvalid");
    } else {
      send("splatValid");
    }
  }

  function buildPath() {
    const path = pathFactory(decodeURIComponent(window.location.pathname));
    setPath(path);
    if (path.isValid === false) {
      send("pathIsInvalid");
    } else {
      send("pathValid");
    }
  }

  async function fetchPlace() {
    try {
      send("placeLoading");
      const response = await aeris
        .api()
        .endpoint("places")
        .place(path.queryId)
        .get();

      const data = _get(response, "data");

      if (data.length === 0) {
        send("placeErrored");
        return;
      }

      const config = {
        placeId: path.queryId,
        type: path.type,
        data
      };

      if (path.type === "local") {
        // fetch an official station.  If we get a hit on this query,
        // we'll override the closest station id.
        const request = await aeris
          .api()
          .endpoint("observations")
          .action("closest")
          .place(path.queryId)
          .limit(1)
          .radius("8mi")
          .get();
        const result = new Response(request);
        if (result.hasData) {
          if (Array.isArray(result.data) && result.data.length > 0) {
            config.placeId = result.data[0].id;
          }
        }

        const location = new Location(config);
        setPlace(location);
      } else {
        const station = new Station(config);
        setPlace(station);
      }

      setLoaded(true);

      send("placeLoaded");
    } catch (err) {
      send("placeErrored");
    }
  }

  useEffect(() => {
    if (current.matches("start")) {
      validateSplat();
    }
    if (current.matches("validatingPath")) {
      buildPath();
    }
    if (current.matches("placeLoading")) {
      fetchPlace();
    }
  }, [current]);

  useEffect(() => {
    send("pageChange");
  }, [props["*"]]);

  function handleAutosuggestResultClick(station) {
    if (station.placeType === "location") {
      history.pushState({}, null, `/local/map/${station.url}`);
    } else {
      history.pushState({}, null, `/station/map/${station.url}`);
    }
    send("pathChange");
  }

  if (current.matches("redirected")) {
    return <Redirect to="/map/" />;
  }

  if (current.matches("fourOhFour")) {
    if (path.isValid === false) {
      return <FourOhFour />;
    }
    return <SiteFourOhFour placeDisplayName={path.displayName} />;
  }

  if (loaded) {
    return (
      <>
        {path.view === "dashboard" && (
          <DashboardPage place={place} path={path} manifest={props.pageContext.manifest} />
        )}
        {path.view === "historical" && (
          <HistoricalPage place={place} path={path} manifest={props.pageContext.manifest} />
        )}
        {path.view === "forecast" && (
          <SiteForecast place={place} path={path} manifest={props.pageContext.manifest} />
        )}
        {path.view === "map" && (
          <MapPage
            place={place}
            path={path}
            context="station"
            handleAutosuggestResultClick={handleAutosuggestResultClick}
          />
        )}
      </>
    );
  } else if (current.matches("placeLoaded")) {
    return (
      <State state={current} matches={["placeLoaded"]}>
        {path.view === "dashboard" && <DashboardPage place={place} path={path} />}
        {path.view === "historical" && <HistoricalPage place={place} path={path} />}
        {path.view === "forecast" && <SiteForecast place={place} path={path} />}
        {path.view === "map" && (
          <MapPage
            place={place}
            path={path}
            context="station"
            handleAutosuggestResultClick={handleAutosuggestResultClick}
          />
        )}
      </State>
    );
  }
  return <PlaceOuterLayout />;
}

export default function Place(props) {
  return (
    <Providers>
      <PlaceDisplay {...props}></PlaceDisplay>
    </Providers>
  );
}
