import { useEffect, useState } from "react";
import {
  startOfDay,
  addDays,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  format
} from "date-fns";
import aeris from "../../../../../../api";
import Summary from "../../../../../../models/aeris/summary/";
import Normal from "../../../../../../models/aeris/normal/";
import Response from "../../../../../../models/aeris/response";
import SummaryResponse from "../../../../../../models/aeris/response/SummaryResponse";
import useFetchStateMachine, { FetchState } from "../../../../../common/hooks/useFetchStateMachine";
import _get from "lodash.get";
import _set from "lodash.set";
import _isNil from "lodash.isnil";
import { UnitStoreContainer } from "../../../../../common/hooks/stores/useUnitStore";
import { RefreshRates } from "../../../../../../types/enums";
import { convertToTimeZone } from "date-fns-timezone";
import {
  endOfMonthDateString,
  endOfYearDateString,
  getTomorrowDateString,
  startOfMonthDateString,
  startOfYearDateString
} from "../../../../../../utils";

type DailySummaryQueryProps = {
  placeId: string;
  date: Date;
  period?: "daily" | "monthly" | "annual";
  initialSummary?: Summary;
};

export default function useDailySummaryQuery({
  placeId,
  date,
  timezone,
  period = "daily",
  initialSummary = new Summary()
}: DailySummaryQueryProps): [Summary, FetchState] {
  const [summary, setSummary] = useState(initialSummary);
  const [current, send] = useFetchStateMachine();
  const { units, unitTypes } = UnitStoreContainer.useContainer();

  let startDate = date;
  let endDate = getTomorrowDateString(startDate, timezone);

  if (period === "monthly") {
    startDate = startOfMonthDateString(startDate);
    endDate = endOfMonthDateString(startDate);
  } else if (period === "annual") {
    startDate = startOfYearDateString(startDate);
    endDate = endOfYearDateString(startDate);
  }

  const summaryRequest = aeris
    .api()
    .endpoint("observations/summary")
    .resetParams()
    .place(placeId)
    .filter("precise,allownosky,metar;pws;madis;ausbom")
    .from(startDate)
    .to(endDate)
    .plimit(9999);

  const normalRequest = aeris
    .api()
    .endpoint("normals")
    .resetParams()
    .place(placeId)
    .filter(period)
    .from(startDate)
    .to(endDate);

  useEffect(() => {
    async function fetchData() {
      send("LOADING");

      const [summarySdkResponse, normalSdkResponse] = await Promise.all([
        summaryRequest.get(),
        normalRequest.get()
      ]);

      const summaryResponse = new SummaryResponse(summarySdkResponse);
      const normalResponse = new Response(normalSdkResponse);
      if (summaryResponse.hasNoData === true) {
        send("NO_DATA");
        return;
      }
      if (summaryResponse.hasError !== true) {
        const summaryData = _get(summaryResponse, "data.periods[0].summary");

        // if period is not daily, we need to iterate through each summary model and determine the
        // min/max/avg across all models for the summary
        if (period !== "daily") {
          const periods = _get(summaryResponse, "data.periods");
          const totalPeriods = periods.length;
          let highTempAvg = 0;
          let lowTempAvg = 0;
          periods.forEach((period: any, index: number) => {
            const model = period.summary;
            Object.keys(model).forEach(prop => {
              Object.keys(model[prop]).forEach(key => {
                const keypath = `${prop}.${key}`;
                const val = model[prop][key];
                const prevVal = _get(summaryData, keypath);
                if (_isNil(val) === false) {
                  if (/^min/.test(key)) {
                    if (/^temp.minF/.test(keypath)) {
                      // console.log('<< MIN ', keypath, val, prevVal);
                      lowTempAvg = lowTempAvg + val;
                    }
                    _set(summaryData, keypath, Math.min(val, prevVal));
                  } else if (/^max/.test(key) || /^gust/.test(key)) {
                    if (/^temp.maxF/.test(keypath)) {
                      highTempAvg = highTempAvg + val;
                    }
                    _set(summaryData, keypath, Math.max(val, prevVal));
                  } else if (/^avg/.test(key)) {
                    if (index === 0) {
                      _set(summaryData, keypath, prevVal);
                    } else {
                      _set(summaryData, keypath, prevVal + val);
                    }
                    if (index === totalPeriods - 1) {
                      _set(summaryData, keypath, _get(summaryData, keypath) / totalPeriods);
                    }
                  } else if (/^total/.test(key)) {
                    if (index === 0) {
                      _set(summaryData, keypath, prevVal);
                    } else {
                      _set(summaryData, keypath, prevVal + val);
                    }
                  }
                }
              });
            });
          });

          _set(summaryData, "highTempAvg", highTempAvg / totalPeriods);
          _set(summaryData, "lowTempAvg", lowTempAvg / totalPeriods);
        }

        _set(summaryData, "period", period);
        const summary = new Summary({
          data: summaryData,
          rawData: summaryResponse
        });

        const normalsData = period === "daily" ? _get(normalResponse, "data.periods[0]", null) : {};

        //hack to use avg high/low temps for monthly data instead of normals
        if (period === "monthly") {
          _set(normalsData, "temp.maxF", summary.avgHighTempF);
          _set(normalsData, "temp.minF", summary.avgLowTempF);
        }
        summary.normal = new Normal({
          data: normalsData
        });
        send("LOADED");
        setSummary(summary);
      } else {
        send("ERROR");
      }
    }
    fetchData();
    const heartbeatHandle = window.setInterval(fetchData, RefreshRates.SummaryCard * 60 * 1000);
    return () => window.clearInterval(heartbeatHandle);
  }, [placeId, date, period]);

  return [summary, current];
}
