import React, { useEffect } from "react";
import ChartObservations from "./chart-model/ChartObservations";
import DashboardChart from "./DashboardChart";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { UnitCategory } from "../../../../../types/enums";
import { ThemeContainer } from "../../../../common/hooks/useTheme";
import { PressureTypeContainer } from "../../../../common/hooks/stores/usePressureTypeStore";

interface TempChartProps {
  collection: ChartObservations;
}

export default function PressureChart({ collection, date, period, config }: TempChartProps) {
  const { unitStore } = UnitStoreContainer.useContainer();
  const { pressureType } = PressureTypeContainer.useContainer();
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;

  const labelConfigCallback = (value: string) => {
    return `${
      unitStore[UnitCategory.Pressure] === "metric"
        ? parseFloat(value).toFixed(1)
        : parseFloat(value).toFixed(2)
    } ${unitStore[UnitCategory.Pressure] === "metric" ? "mb" : "in"}`;
  };

  const datasetConfigs = [
    {
      data: collection.pressure,
      label: "Pressure",
      key: "pressure",
      borderColor: dataColors.pressure,
      pointBackgroundColor: dataColors.pressure
    }
  ];

  return (
    <DashboardChart
      period={period}
      date={date}
      chartConfig={config}
      datasetConfigs={datasetConfigs}
      labelConfigCallback={labelConfigCallback}
      collection={collection}
    />
  );
}
