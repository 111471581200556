import { useState, useEffect } from "react";
import aeris from "../../../../../../api/";
import _cloneDeep from "lodash.clonedeep";
import IsMetric from "../../../../../common/hooks/useIsMetric";
import Response from "../../../../../../models/aeris/response/Response";
import Summary from "../../../../../../models/aeris/summary/Summary";
import Observation from "../../../../../../models/aeris/observation/Observation";
import useFetchStateMachine, {
  FetchEvent,
  FetchState
} from "../../../../../common/hooks/useFetchStateMachine";
import _get from "lodash.get";
import { UnitStoreContainer } from "../../../../../common/hooks/stores/useUnitStore";
import { RefreshRates } from "../../../../../../types/enums";
import { sign } from "crypto";

async function fetchStation(placeId: string, fields: string[]): Promise<Response> {
  const request = aeris
    .api()
    .endpoint("observations")
    // .fields(observation.buildFields(fields))
    .place(placeId)
    .filter("metar;pws;madis;ausbom,allownosky,precise")
    .limit(1);
  const response = await request.get();
  return new Response(response);
}

type ObservationQueryType = {
  placeId: string;
  fields?: string[];
  initialObservation?: Observation;
  initialSummary?: Summary;
};

export default function useObservationQuery({
  placeId,
  fields = [],
  initialObservation = new Observation({ placeId }),
  initialSummary = new Summary({ placeId })
}: ObservationQueryType): [Observation, Summary, FetchState] {
  const [observation, setObservation] = useState(initialObservation);
  const [summary, setSummary] = useState(initialSummary);

  UnitStoreContainer.useContainer(); // need for unit refreshing
  const [current, send] = useFetchStateMachine();

  const summaryRequest = aeris
    .api()
    .endpoint("observations/summary")
    .place(placeId)
    .from("today")
    .limit(1);

  useEffect(() => {
    async function fetchObservation() {
      send(FetchEvent.LOADING);
      try {
        const [summaryResult, observationResponse] = await Promise.all([
          summaryRequest.get(),
          fetchStation(placeId, fields)
        ]);
        const summaryResponse = new Response(summaryResult);
        const summaryKey = Array.isArray(summaryResponse.data)
          ? "data[0].periods[0].summary"
          : "data.periods[0].summary";
        send(FetchEvent.LOADED);
        const observation = new Observation({
          placeId,
          data: observationResponse.data
        });
        const summary = new Summary({
          placeId,
          data: _get(summaryResponse, summaryKey, null),
          rawData: summaryResponse
        });
        setObservation(observation);
        setSummary(summary);
      } catch (e) {
        send(FetchEvent.ERROR);
      }
    }

    fetchObservation();

    const heartbeatHandle = window.setInterval(
      fetchObservation,
      RefreshRates.LatestObservationCard * 60 * 1000
    );

    return () => window.clearInterval(heartbeatHandle);
  }, [placeId]);

  return [observation, summary, current];
}
