import React from "react";
import { Button, SegmentedControl } from "pws-design-system/design-system/";
import { DateRange } from "../types";
import { CardPeriod } from "../../../../../../../types/enums";

type DateRangeToggleProps = {
  changeHandler(val: DateRange): void;
  period?: CardPeriod;
};

const DateRangeToggle = ({
  changeHandler,
  period = CardPeriod.Daily
}: DateRangeToggleProps): React.ReactElement => {
  return (
    <SegmentedControl
      bg="brand.gray.100"
      p="2px"
      size="xs"
      spacing={0}
      ml={3}
      mt={[4, null, 0]}
      variant="link"
      variantColor="dark"
      selectedVariant="outline-fill"
      selectedVariantColor="dark"
      rounded="full"
      defaultValue={period}
      onChange={changeHandler}
    >
      <Button value="daily">Daily</Button>
      <Button value="monthly">Monthly</Button>
    </SegmentedControl>
  );
};

export default DateRangeToggle;
