import Measurement from "../Measurement";
import { unitStore } from "../../../react/common/hooks/stores/useUnitStore";
import { UnitCategory, UnitTypes, ApiProps, ApiPropGroups, Units } from "../../../types/enums";
import DataFormatter from "../../../models/aeris/observation/DataFormatter";

export default class Normal extends Measurement {
  public get minF(): string | null {
    return this.getData(`temp.minF`);
  }
  public get minC(): string | null {
    return this.getData(`temp.minC`);
  }
  public get minTemp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.minC : this.minF;
    return value;
  }
  public get minTempFormatted(): string | null {
    if (this.minTemp === null) {
      return null;
    }
    const dataFormatter = new DataFormatter(
      UnitCategory.Temp,
      unitStore[UnitCategory.Temp],
      this.minTemp
    );

    return dataFormatter.formattedValue;
  }

  public get maxTempF(): string | null {
    return this.getData(`temp.maxF`);
  }
  public get maxTempC(): string | null {
    return this.getData(`temp.maxC`);
  }
  public get maxTemp() {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.maxTempC : this.maxTempF;
    return value;
  }
  public get maxTempFormatted(): string | null {
    if (this.maxTemp === null) {
      return null;
    }
    const dataFormatter = new DataFormatter(
      UnitCategory.Temp,
      unitStore[UnitCategory.Temp],
      this.maxTemp
    );

    return dataFormatter.formattedValue;
  }

  public get dewptMinF(): string | null {
    return this.getData(`dewpt.minF`);
  }
  public get dewptMaxF(): string | null {
    return this.getData(`dewpt.maxF`);
  }
  public get dewptMaxC(): string | null {
    return this.getData(`dewpt.maxC`);
  }
  public get dewptMax(): string | null {
    return this.isMetric ? this.dewptMaxC : this.dewptMaxF;
  }
}
