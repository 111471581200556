import InteractiveMapApp from "@aerisweather/javascript-sdk/dist/apps/InteractiveMapApp";
import InteractiveMap from "@aerisweather/javascript-sdk/dist/maps/interactive/InteractiveMap";
import VectorSource from "@aerisweather/javascript-sdk/dist/maps/interactive/sources/VectorSource";
import _isNil from "lodash.isnil";
import {
  Absolute,
  Box,
  Button,
  Card,
  SegmentedControl,
  Theme
} from "pws-design-system/design-system";
import React, { useEffect, useState } from "react";
import aeris from "../../../../../../api/";
import Observation from "../../../../../../models/aeris/observation/Observation";
import Place from "../../../../../../models/aeris/place/Place";
import { buildStationPath } from "../../../../../../models/path";
import ErrorBoundary from "../../../../../common/components/error-boundary";
import MapController, {
  obsFields,
  stationMarkerConfigurator
} from "../../../../../common/components/map/MapController";
import { UnitStoreContainer } from "../../../../../common/hooks/stores/useUnitStore";
import MapPanel from "../../../../../common/components/map/MapPanel";
import { number as yupNumber, object as yupObject, array as yupArray } from "yup";
import { RefreshRates } from "../../../../../../types/enums";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";

enum ViewType {
  CURRENT = "current",
  NEARBY = "nearby"
}

// if the request returns no data, it doesnt have a lat long
// we still want to show a marker in this case.
let observationSchema = yupObject().shape({
  loc: yupObject()
    .required()
    .strict(true)
    .shape({
      lat: yupNumber().required(),
      long: yupNumber().required()
    })
});

const generateObsSource = (
  place: Place,
  map: InteractiveMap,
  type: ViewType,
  stationType: string = null
): any => {
  const source = new VectorSource(`obs-${type}`, {
    requiresBounds: false,
    data: {
      url: (params: any): string => {
        const request = aeris
          .api()
          .endpoint("observations")
          .place(place.placeId)
          .filter("precise,metar;pws;madis;ausbom,allownosky")
          .fields(obsFields.join(","));

        if (type === ViewType.NEARBY) {
          request.action("closest");
          request.radius("50mi");
          request.filter("metar;pws;madis;ausbom,precise");
          request.limit(250);
        } else {
          request.limit(1);
        }
        return request.url();
      },
      properties: {
        root: "response"
      },
      formatter: (data: any): any => {
        if (type === ViewType.CURRENT) {
          if (observationSchema.isValidSync(data) === false) {
            return [
              {
                loc: {
                  lat: place.lat,
                  long: place.lon
                }
              }
            ];
          }
          return [data];
        }
        // TODO: validate nearby?
        return data;
      }
    },
    style: {
      marker: (data: any): any => {
        const placeLabel =
          type === ViewType.CURRENT && stationType === "local" ? place.formattedPlaceShort : null;
        return stationMarkerConfigurator(data, map, "temps", false, placeLabel);
      }
    }
  });

  return source;
};

type StationMapCardProps = {
  place: Place;
  type?: "station" | "local";
};

const StationMapCard: React.FC<StationMapCardProps> = ({
  place,
  type = "station"
}): React.ReactElement => {
  const [activePlace, setActivePlace] = useState();
  const [activeObservation, setActiveObservation] = useState();
  const [viewType, setViewType] = useState(ViewType.CURRENT);
  const [mapRef, setMapRef] = useState();
  const [mapSources, setMapSources] = useState({ current: null, nearby: null });
  const { units, unitType } = UnitStoreContainer.useContainer();
  const { theme } = ThemeContainer.useContainer();

  const updateSourceForType = (type: ViewType) => {
    const map = mapRef;
    if (!map) return;

    if (type === ViewType.NEARBY) {
      const source = mapSources.nearby;
      const existing = mapSources.current;
      if (existing) {
        map.removeSource(existing);
      }
      if (source) {
        map.addSource(source, `obs-${ViewType.NEARBY}`);
      }
    } else {
      const source = mapSources.current;
      const existing = mapSources.nearby;
      if (existing) {
        map.removeSource(existing);
      }
      if (source) {
        map.addSource(source, `obs-${ViewType.CURRENT}`);
      }
    }
  };

  // set active place on initial map load
  useEffect(() => {
    if (mapRef && place) {
      setActivePlace(place);
    }
  }, [mapRef]);

  // update obs map sources on place change
  useEffect(() => {
    setActiveObservation(null);
    const map = mapRef;
    if (map) {
      // remove existing sources first if needed
      if (mapSources.current) {
        map.removeSource(mapSources.current);
      }
      if (mapSources.nearby) {
        map.removeSource(mapSources.nearby);
      }

      setMapSources({
        current: generateObsSource(activePlace, map, ViewType.CURRENT, type),
        nearby: generateObsSource(activePlace, map, ViewType.NEARBY, type)
      });
    }
  }, [activePlace]);

  useEffect(() => {
    if (viewType !== ViewType.CURRENT) {
      setViewType(ViewType.CURRENT);
    } else {
      updateSourceForType(viewType);
    }
  }, [mapSources]);

  // swap out map sources on view type change
  useEffect(() => {
    // center map on active place
    if (mapRef && activePlace && activePlace.hasAll(["lat", "lon"])) {
      mapRef.setCenter({ lat: activePlace.lat, lon: activePlace.lon });
    }
    setActiveObservation(null);
    updateSourceForType(viewType);
  }, [viewType]);

  // update markers on unit toggle
  useEffect(() => {
    const map = mapRef;
    if (!map) return;

    let markers: any[] = [];
    [mapSources.current, mapSources.nearby]
      .filter(source => source !== null)
      .forEach(source => {
        markers = markers.concat(source.markers);
      });

    markers
      .filter((marker: any) => marker && _isNil(marker.renderable) === false)
      .forEach((marker: any) => {
        const mapEl = marker.renderable;
        const placeLabel =
          viewType === ViewType.CURRENT && type === "local" ? place.formattedPlaceShort : null;
        if (mapEl) {
          map.strategy.updateMarker(mapEl, {
            style: stationMarkerConfigurator(marker.data, map, false, placeLabel)
          });
        }
      });
  }, [units, unitType]);

  // setup event handlers when interactive map has loaded
  const handleMapReady = (map: InteractiveMap, app: InteractiveMapApp) => {
    setMapRef(map);

    map.on("marker:click", (e: any) => {
      const eventData = e.data || {};
      const data = eventData.data;
      const source = data.awxjs_source;

      if ((source === "obs-current" || source === "obs-nearby") && data) {
        const observation = new Observation({ data });
        setActiveObservation(observation);
      } else {
        setActiveObservation(null);
      }
    });
  };

  if (mapRef && activePlace && place.placeId !== activePlace.placeId) {
    setActivePlace(place);
  }

  useEffect(() => {}, []);

  return (
    <Card
      boxShadow="sm"
      mb={3}
      p={0}
      position="relative"
      overflow="hidden"
      height={`calc(100% - ${Theme.space[3]}px)`}
    >
      <MapController
        height="100%"
        type="InteractiveMap"
        place={activePlace}
        mapReadyHandler={handleMapReady}
        config={{
          refresh: RefreshRates.StationMapCard * 60,
          map: {
            zoom: type === "station" ? 11 : 9
          }
        }}
      />
      <Absolute top="10px" right="10px">
        <Box p={0} rounded="full" backgroundColor="rgba(0,0,0,0.8)">
          <SegmentedControl
            value={viewType}
            size="xs"
            p="3px"
            spacing={0}
            {...theme.components.card.map.unitToggle}
            rounded="full"
            onChange={(value: ViewType) => setViewType(value)}
          >
            {/*
              // @ts-ignore */}
            <Button value="current">Current</Button>
            {/*
              // @ts-ignore */}
            <Button value="nearby">Nearby</Button>
          </SegmentedControl>
        </Box>
      </Absolute>
      {activeObservation && (
        <MapPanel
          variant="minimal"
          observation={activeObservation}
          url={`${buildStationPath("dashboard", activeObservation.id)}`}
          handleCloseClick={() => setActiveObservation(null)}
        />
      )}
    </Card>
  );
};

const StationMapCardWithErrorBoundary = (props: StationMapCardProps) => {
  return (
    <ErrorBoundary variant="card">
      <StationMapCard {...props} />
    </ErrorBoundary>
  );
};

export default StationMapCardWithErrorBoundary;
