import React, { useState } from "react";
import { Card, Flex, Heading, Hide } from "pws-design-system/design-system";
import Datepicker from "../../datepicker";
import DateRangeToggle from "./components/DateRangeToggle";
import CardViewToggle from "./components/CardViewToggle";
import useObservationHistoryQuery from "./useObservationHistoryQuery";
import ResponsiveRender from "../../../../../common/components/responsive-render/ResponsiveRender";
import ContentPanel from "./components/ContentPanel";
import _get from "lodash.get";
import _noop from "lodash.noop";
import ErrorBoundary from "../../../../../common/components/error-boundary";
import { Z, CardPeriod, CardView } from "../../../../../../types/enums";
import { UseDateContainer } from "../../../../../common/hooks/stores/useDateStore";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";

type ObservationHistoryCardProps = {
  placeId: string;
  initialDate: Date;
  initialPeriod: CardPeriod;
  initialView: CardView;
  onDateChange?: (value: Date) => void;
  onPeriodChange?: (value: CardPeriod) => void;
};

const ObservationHistoryCard = ({
  placeId,
  initialDate = new Date(),
  initialPeriod = CardPeriod.Daily,
  initialView = CardView.Graph,
  onDateChange = _noop,
  onPeriodChange = _noop
}: ObservationHistoryCardProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  const [date, setDate] = useState(initialDate);
  const [view, setView] = useState(initialView);
  const { period, setPeriod } = UseDateContainer.useContainer();
  const [chartModel, queryState] = useObservationHistoryQuery({
    placeId,
    date,
    period
  });
  const { dateStore } = UseDateContainer.useContainer();
  const handleDateChange = (val: Date) => {
    if (onDateChange !== _noop) {
      onDateChange(val);
    }
    setDate(val);
  };

  const handlePeriodChange = (val: CardPeriod) => {
    if (val === period) {
      return;
    }
    setPeriod(val);
    onPeriodChange(val);
  };

  const handleViewChange = (val: CardView) => {
    setView(val);
  };

  return (
    <Card
      boxShadow="sm"
      mb={3}
      position="relative"
      minHeight="400px"
      bg={theme.components.dashboard.card.bg}
    >
      <Flex mb={4} align="center" justify="space-between" width="100%">
        <Flex align="center" width="100%" flexWrap="wrap">
          <Heading
            as="h2"
            variant="subheadline"
            mr={4}
            minWidth={[null, "250px"]}
            mb={[3, null, 0]}
          >
            Observation History
          </Heading>
          <Hide tablet desktop width="100%" zIndex={Z.mobileDatepicker}>
            <Datepicker
              context={period}
              timezone={dateStore.timezone}
              variant="fullWidth"
              initialDate={date}
              changeHandler={handleDateChange}
            />
          </Hide>
          <ResponsiveRender size={["tablet", "desktop"]}>
            <Datepicker
              timezone={dateStore.timezone}
              variant="fullWidth"
              initialDate={date}
              context={period}
              changeHandler={handleDateChange}
            />
            <DateRangeToggle period={period} changeHandler={handleViewChange} />
          </ResponsiveRender>
          <ResponsiveRender size={["mobile"]}>
            <CardViewToggle
              changeHandler={handleViewChange}
              {...theme.components.historical.toggle}
            />
          </ResponsiveRender>
          <ResponsiveRender size={["tablet", "desktop"]}>
            <CardViewToggle
              ml="auto"
              changeHandler={handleViewChange}
              {...theme.components.historical.toggle}
            />
          </ResponsiveRender>
        </Flex>
      </Flex>
      <ContentPanel
        chartModel={chartModel}
        date={new Date(`${date}T00:00:00`)}
        period={period}
        view={view}
        dateChangeHandler={handleDateChange}
        periodChangeHandler={handlePeriodChange}
        viewChangeHandler={handleViewChange}
        state={queryState}
      />
    </Card>
  );
};

const ObservationHistoryCardWithErrorBoundary: React.FC<ObservationHistoryCardProps> = (
  props
): React.ReactElement => {
  return (
    <ErrorBoundary variant="card">
      <ObservationHistoryCard variant="card" {...props} />
    </ErrorBoundary>
  );
};

export default ObservationHistoryCardWithErrorBoundary;
