import Collection from "../../Collection";
import Observation from ".";
import _get from "lodash.get";
import * as yup from "yup";

export default class Observations extends Collection {
  _precipAccumulationByPeriod: number[] = null;
  _sortedByDateDescending: Observation[] = null;
  _precipAccumulationByPeriodDescending: number[] = null;
  constructor(config = {}) {
    super(config);
    let data = _get(config, "data", null);
    if (data !== null) {
      if (Array.isArray(data) === false) {
        data = [data];
      }
      this.models = data.map((datum: any) => new Observation({ data: datum }));
    }
  }

  get sortedByDateDescending() {
    if (this.models === null) {
      return null;
    }
    if (this._sortedByDateDescending) {
      return this._sortedByDateDescending;
    }
    this._sortedByDateDescending = [...this.models].sort((a, b) => {
      return a.dateTimeIso > b.dateTimeIso ? -1 : 1;
    });
    return this._sortedByDateDescending;
  }

  private accumulatePrecip() {
    // const precipAccumulation: number[] = [];
    // this.models.reduce((prev, curr): number => {
    //   const val = curr.precip;
    //   if (yup.number().isValidSync(val) === false) {
    //     precipAccumulation.push(prev);
    //     return prev;
    //   }
    //   const precip = prev + Number(val);
    //   precipAccumulation.push(precip);
    //   return precip;
    // }, 0);
    // return precipAccumulation;
  }

  get precipAccumulationByPeriod() {
    if (this._precipAccumulationByPeriod) {
      return this._precipAccumulationByPeriod;
    }
    this._precipAccumulationByPeriod = this.accumulatePrecip();
    return this._precipAccumulationByPeriod;
  }

  get precipAccumulationByPeriodDescending() {
    if (this._precipAccumulationByPeriodDescending) {
      return this._precipAccumulationByPeriodDescending;
    }
    this._precipAccumulationByPeriodDescending = [...this._precipAccumulationByPeriod].reverse();
    return this._precipAccumulationByPeriodDescending;
  }
}
