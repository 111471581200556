import React, { useState, useEffect } from "react";
import Response from "../../../../../models/aeris/response/Response";
import { Badge, Button } from "pws-design-system/design-system";
import _get from "lodash.get";
import aeris from "../../../../../api/";
import { fetchStation as fetchStationFromPwsApi } from "../../../../../api/pws/";

interface ActiveFlagProps {
  // the Place ID
  id: string;
  type: string;
}

class StatusResponse extends Response {
  get status() {
    return _get(this, "apiResponse.station.status", null);
  }
}

/**
 * Displays a "flag" graphic indicating whether the station is active or
 * inactive. A station is considered active if it has reported any
 * observation within the last 30 days.
 */
export default function ActiveFlag({ id, type }: ActiveFlagProps): React.ReactElement {
  const [status, setStatus] = useState(null);

  const sdkRequest = aeris
    .api()
    .endpoint("observations")
    .from("-30days")
    .limit(1)
    .fields("dateTimeISO");

  const pwsRequest = (id: string) => fetchStationFromPwsApi(id);

  const request = type === "pws" ? pwsRequest : sdkRequest;

  useEffect(() => {
    const fetchObservationData = async () => {
      setStatus(null);

      if (type === "pws") {
        const pwsResponse = await request(id);
        const response = new StatusResponse(pwsResponse);
        setStatus(response.status);
      } else {
        const sdkResponse = await request
          .resetParams()
          .place(id)
          .get();

        const response = new Response(sdkResponse);

        if (response.hasError === true && response.hasNoData === true) {
          setStatus("inactive");
        }

        if (response.hasError === false) {
          setStatus("active");
        }
      }
    };

    fetchObservationData();
  }, [id]);

  if (!status) {
    return null;
  }

  return (
    <>
      <Badge mt={2} variant="outline" variantColor="green" height="16px">
        {status}
      </Badge>
      {/*
      {hasObservation === true && (
        <Badge mt={2} variant="outline" variantColor="green" height="16px">
          Active
        </Badge>
      )}
      {hasObservation === false && (
        <Badge mt={2} variant="solid" variantColor="yellow" height="16px">
          Inactive
        </Badge>
      )} */}
    </>
  );
}
