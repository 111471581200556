import React from "react";
import { SegmentedControl, Button } from "pws-design-system/design-system/";
import theme from "pws-design-system/design-system/theme";

type CardView = "Graphs" | "Tabular";

type CardViewToggleProps = {
  changeHandler(val: CardView): void;
};

const CardViewToggle = ({ changeHandler, ...rest }: CardViewToggleProps): React.ReactElement => {
  return (
    <SegmentedControl
      bg="brand.gray.100"
      p="2px"
      size="xs"
      spacing={0}
      ml={[0, null, 3]}
      mt={[3, null, 0]}
      variant="link"
      variantColor="dark"
      selectedVariant="outline-fill"
      selectedVariantColor="dark"
      rounded="full"
      defaultValue="graphs"
      onChange={changeHandler}
      width={["100%", null, "auto"]}
      display={["flex", null, "block"]}
      {...rest}
    >
      <Button value="graphs" flexGrow="1">
        Graphs
      </Button>
      <Button value="tabular" flexGrow="1">
        Tabular
      </Button>
    </SegmentedControl>
  );
};

export default CardViewToggle;
