import { navigate } from "gatsby";
import { isToday, isTomorrow } from "date-fns";
import _isNil from "lodash.isnil";
import {
  Absolute,
  Box,
  Button,
  Col,
  Flex,
  Heading,
  Row,
  Spinner,
  Stat,
  Text,
  WeatherIcon
} from "pws-design-system/design-system";
import React from "react";
import Forecast from "../../../../../../models/aeris/forecast/Forecast";
import Place from "../../../../../../models/aeris/place/";
import SunMoon from "../../../../../../models/aeris/sunmoon/Sunmoon";
import { unitForProp } from "../../../../../../models/aeris/units";
import { valueRangeString } from "../../../../../../utils";
import DelayedRenderer from "../../../../../common/components/delayed-renderer/DelayedRender";
import PwsCardDisplay from "../PwsCardDisplay";
import useForecastQuery from "./useForecastQuery";
import ErrorBoundary from "../../../../../common/components/error-boundary";
import { unitStore } from "../../../../../common/hooks/stores/useUnitStore";
import DataFormatter from "../../../../../../models/aeris/observation/DataFormatter";
import { UnitCategory, ClockType } from "../../../../../../types/enums";
const { convertToTimeZone, formatToTimeZone } = require("date-fns-timezone");
import { TimeStoreContainer } from "../../../../../common/hooks/stores/useTimeStore";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";

interface ForecastCardProps {
  placeId: string;
  forecastUrl: string;
}

const ForecastStat: React.FC<any> = ({ ...rest }): React.ReactElement => {
  return <Stat width="50%" mt={3} valueSize="xl" labelSize="0.7rem" {...rest} />;
};

const timeOfDay = (date: Date, tz: string): string => {
  const convertedDate = convertToTimeZone(date, {
    timeZone: tz
  });

  if (isToday(convertedDate) && convertedDate.getHours() === 7) {
    return "Today";
  }
  if (isToday(convertedDate) && convertedDate.getHours() === 19) {
    return "Tonight";
  }
  if (isTomorrow(convertedDate) && convertedDate.getHours() === 7) {
    return "Tomorrow";
  }
  if (isTomorrow(convertedDate) && convertedDate.getHours() === 19) {
    return "Tomorrow Night";
  }
  return " ";
};

function ForecastCard({ placeId, forecastUrl }: ForecastCardProps): React.ReactElement {
  const [place, state] = useForecastQuery({ placeId });
  const { theme } = ThemeContainer.useContainer();
  return (
    <ForecastCardDisplay place={place} state={state} forecastUrl={forecastUrl} theme={theme} />
  );
}

const getSunTime = (place: Place, forecast: Forecast): string => {
  const { getHourFormatToken, timeFormat } = TimeStoreContainer.useContainer();
  const sunMoons = place.sunmoons;
  const sunMoon = sunMoons.models.filter((model: SunMoon) => {
    const forecastDay = formatToTimeZone(new Date(forecast.dateTimeIso), "D", {
      timeZone: place.timezone
    });
    const sunDay = formatToTimeZone(new Date(model.riseIso), "D", {
      timeZone: place.timezone
    });
    return forecastDay === sunDay;
  });
  if (sunMoon.length !== 0) {
    return formatToTimeZone(
      forecast.isDay ? sunMoon[0].riseDate : sunMoon[0].setDate,
      `${getHourFormatToken()}:mm${timeFormat === ClockType.TwelveHour ? " a" : ""}`,
      {
        timeZone: place.timezone
      }
    );
  }
  return null;
};

const ForecastCardDisplay: React.FC<any> = ({
  place = new Place(),
  state,
  forecastUrl,
  theme
}): React.ReactElement => {
  return (
    <Box color={theme.colors.text.base.primary}>
      <PwsCardDisplay
        HeadingSlot={() => (
          <Flex mb={4} justifyContent="space-between" alignItems="center">
            <Heading as="h2" variant="subheadline">
              Next 36 Hours
            </Heading>
            <Button size="xs" onClick={() => navigate(forecastUrl)}>
              Full Forecast
            </Button>
          </Flex>
        )}
        state={state}
        height={state.matches("initialLoadComplete") === true ? "auto" : "384px"}
        position="relative"
      >
        {state.matches("error") === true && (
          <Absolute top="0" left="0" right="0" bottom="0">
            <Flex height="100%" width="100%" align="center" justify="center">
              There was an error loading forecast data.
            </Flex>
          </Absolute>
        )}

        {state.matches("initialLoad") === true && (
          <DelayedRenderer delay={3000}>
            <Absolute top="0" left="0" right="0" bottom="0">
              <Flex height="100%" width="100%" align="center" justify="center">
                <Spinner></Spinner>
              </Flex>
            </Absolute>
          </DelayedRenderer>
        )}
        {place.has("forecasts") === true && (
          <Row>
            {place.forecasts.models.map((forecast: Forecast, i: number) => (
              <Col md={4} sm={12} key={forecast.date.getTime()}>
                <Box flexGrow={1} my={[3, null, 4, 0]}>
                  <Text variant="label" fontSize="sm">
                    {timeOfDay(forecast.date, place.timezone)}
                  </Text>
                  <Flex align="center" mt={3} mb={2}>
                    {forecast.has("weatherShortCode") && (
                      <WeatherIcon code={forecast.weatherShortCode} width={50} height={50} mr={3} />
                    )}
                    {forecast.has("weatherShortCode") === false && <Box height={50} mr={4} />}
                    <Text variant="value" fontSize="5xl">
                      <>
                        {forecast.isDay
                          ? forecast.get("maxTemp", "--", val => Number(val))
                          : forecast.get("minTemp", "--", val => Number(val))}
                      </>
                    </Text>
                  </Flex>
                  <Text height={[null, null, "50px"]} fontSize="md">
                    {forecast.get("weatherPrimary", " ")}
                  </Text>
                  <Box height={"3px"} mt={2} bg="brand.green.base" />
                  <Flex wrap="wrap">
                    {forecast.hasAll(["windMin", "windMax"]) === true && (
                      <ForecastStat
                        value={valueRangeString(forecast.windMin, forecast.windMax, 5)}
                        units={unitForProp("wind", unitStore.speed === "metric")}
                        label="Winds"
                      />
                    )}
                    {forecast.has("precip") === true && (
                      <ForecastStat
                        value={forecast.get(
                          "precip",
                          0,
                          val => {
                            const formatter = new DataFormatter(
                              UnitCategory.Rain,
                              unitStore.rain,
                              val
                            );
                            return val.toFixed(formatter.decimal);
                          },
                          val => {
                            const formatter = new DataFormatter(
                              UnitCategory.Rain,
                              unitStore.rain,
                              val
                            );
                            return val.toFixed(formatter.decimal);
                          }
                        )}
                        units={unitForProp("precip", unitStore.rain === "metric")}
                        label="Precip"
                      />
                    )}

                    {_isNil(getSunTime(place, forecast)) !== true && (
                      <ForecastStat
                        value={getSunTime(place, forecast)}
                        label={forecast.isDay ? "Sunrise" : "Sunset"}
                      />
                    )}
                    {forecast.has("snow") === true && (
                      <ForecastStat
                        value={forecast.get(
                          "snow",
                          0,
                          val => {
                            const formatter = new DataFormatter(
                              UnitCategory.Snow,
                              unitStore.snow,
                              val
                            );
                            return val.toFixed(formatter.decimal);
                          },
                          val => {
                            const formatter = new DataFormatter(
                              UnitCategory.Snow,
                              unitStore.snow,
                              val
                            );
                            return val.toFixed(formatter.decimal);
                          }
                        )}
                        units={unitForProp("snow", unitStore.snow === "metric")}
                        label="Snow"
                      />
                    )}
                  </Flex>
                </Box>
              </Col>
            ))}
          </Row>
        )}
      </PwsCardDisplay>
    </Box>
  );
};

const ForecastCardWithErrorBoundary: React.FC<ForecastCardProps> = (props): React.ReactElement => {
  return (
    <ErrorBoundary variant="card">
      <ForecastCard {...props} />
    </ErrorBoundary>
  );
};

export default ForecastCardWithErrorBoundary;
