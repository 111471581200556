import React, { useEffect } from "react";
import {
  Stack,
  PseudoBox,
  Theme,
  Text,
  Flex,
  Icon,
  Absolute,
  WeatherIcon
} from "pws-design-system/design-system";
import _startcase from "lodash.startcase";
import _lowercase from "lodash.lowercase";
import _some from "lodash.some";
import _keys from "lodash.keys";
import _pickBy from "lodash.pickby";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

export default function ForecastPeriod({ selected, clickHandler, forecast, divider }) {
  const { units } = UnitStoreContainer.useContainer();
  const { theme } = ThemeContainer.useContainer();
  return (
    <PseudoBox
      position="relative"
      width="150px"
      flexShrink="0"
      p={3}
      // margin="auto"
      borderBottom={`2px solid ${selected ? Theme.colors.brand.green.base : "transparent"}`}
      _hover={{
        bg: theme.components.forecast.period.hover,
        borderBottom: `2px solid ${Theme.colors.brand.green.base}`,
        cursor: "pointer"
      }}
      onClick={() => clickHandler(forecast.date)}
    >
      <Text variant="title3" mb={3} textAlign="center">
        {forecast.isToday ? "Today" : forecast.dayOfWeek}
      </Text>
      <WeatherIcon code={forecast.weatherShortCode} width={50} height={50} mx="auto" />
      <Flex align="center" justify="space-between" mt={3} mb={2} mx={2}>
        <Stack spacing={1} isInline>
          <Icon name="arrow-up" height="18px" color="red.600" />
          <Text variant="value" fontSize="2xl">
            {forecast.maxTemp}
          </Text>
        </Stack>
        <Stack spacing={1} isInline>
          <Icon name="arrow-down" height="14px" color="blue.500" />
          <Text variant="value" fontSize="xl">
            {forecast.minTemp}
          </Text>
        </Stack>
      </Flex>
      <Text height={[null, null, "50px"]} fontSize="sm" textAlign="center">
        {forecast.weatherPrimary}
      </Text>
      {selected && (
        <PseudoBox
          bg="brand.green.base"
          height="6px"
          position="absolute"
          left={0}
          right={0}
          bottom={0}
          _before={{
            content: '""',
            position: "absolute",
            bottom: "-17px",
            left: "50%",
            marginLeft: "-15px",
            borderWidth: "15px 15px 0",
            width: 0,
            height: 0,
            borderColor: "transparent",
            borderStyle: "solid",
            borderTopColor: Theme.colors.brand.green.base
          }}
        />
      )}
      {divider && (
        <Absolute
          width="1px"
          bg="brand.gray.200"
          height="120px"
          top="50%"
          marginTop="-60px"
          right="0"
        />
      )}
    </PseudoBox>
  );
}
