import { addDays, format, startOfDay } from "date-fns";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import _every from "lodash.every";
import _get from "lodash.get";
import { useEffect, useState } from "react";
import aeris from "../../../../../../api";
import Observations from "../../../../../../models/aeris/observation/Observations";
import Place from "../../../../../../models/aeris/place";
import Summaries from "../../../../../../models/aeris/summary/Summaries";
import useFetchStateMachine from "../../../../../common/hooks/useFetchStateMachine";
import IsMetric from "../../../../../common/hooks/useIsMetric";
import ChartObservations from "../../../common/charts/chart-model/ChartObservations";
import ChartSummaries from "../../../common/charts/chart-model/ChartSummaries";
import { RefreshRates } from "../../../../../../types/enums";
import { convertToTimeZone } from "date-fns-timezone";
import {
  endOfMonthDateString,
  endOfYearDateString,
  getTomorrowDateString,
  startOfMonthDateString,
  startOfYearDateString
} from "../../../../../../utils";

export default function useObservationHistoryQuery({
  placeId,
  date,
  timezone,
  period,
  initialObservations = new ChartObservations({ period })
}) {
  const [observations, setObservations] = useState(initialObservations);
  const [isMetric] = IsMetric.useContainer();
  const [current, send] = useFetchStateMachine();

  const isDaily = period === "daily";
  const endpoint = isDaily ? "observations/archive" : "observations/summary";
  const ChartModel = isDaily ? ChartObservations : ChartSummaries;
  const Model = isDaily ? Observations : Summaries;

  let startDate = date;
  let endDate = getTomorrowDateString(startDate, timezone);

  if (period === "monthly") {
    startDate = startOfMonthDateString(startDate);
    endDate = endOfMonthDateString(startDate);
  } else if (period === "annual") {
    startDate = startOfYearDateString(startDate);
    endDate = endOfYearDateString(startDate);
  }

  const observationRequest = aeris
    .api()
    .endpoint(endpoint)
    .place(placeId)
    .filter("calcprecip,precise,metar;pws;madis;ausbom,allownowksy")
    .from(startDate)
    .to(endDate)
    .sort("dt:-1")
    .plimit(9999);

  const placeRequest = aeris
    .api()
    .endpoint("places")
    .filter("metar;pws;madis;ausbom,allownowksy")
    .place(placeId);

  useEffect(() => {
    async function fetchObservations() {
      send("LOADING");
      const [observationResponse, placeResponse] = await Promise.all([
        observationRequest.get(),
        placeRequest.get()
      ]);
      const successes = [observationResponse, placeResponse].map(response =>
        _get(response, "response.data.success", false)
      );
      const success = _every(successes, success => success === true);
      if (success === true) {
        if (observationResponse.data.length === 0) {
          send("NO_DATA");
          return;
        }

        // since we're grabbing the last ob from the previous day and the first of the next, we need to modify the array of obs to ensure
        // it's displaying data clamped to the date range being viewed
        const results: any[] =
          _get(observationResponse, "data[0].periods", null) ||
          _get(observationResponse, "data.periods", null);
        const obs: any[] = results;

        const chartModel = new ChartModel({
          data: obs,
          period
        });

        chartModel.place = new Place({
          data: _get(placeResponse, "data", null)
        });

        chartModel.observations = new Model({
          data: obs,
          period
        });
        chartModel.period = period;
        send("LOADED");
        setObservations(chartModel);
      } else {
        send("ERROR");
      }
    }
    fetchObservations();
    const heartbeatHandle = window.setInterval(
      fetchObservations,
      RefreshRates.HistoryCard * 60 * 1000
    );
    return () => window.clearInterval(heartbeatHandle);
  }, [placeId, period, date]);

  useEffect(() => {}, [isMetric]);

  return [observations, current];
}
