import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Absolute,
  Box,
  Icon,
  Text,
  Flex,
  Theme,
  PseudoBox,
  Fixed
} from "pws-design-system/design-system";
import _startCase from "lodash.startcase";
import { Z } from "../../../../types/enums";
import _debounce from "lodash.debounce";
import _isNil from "lodash.isnil";
import _get from "lodash.get";

const colors = Theme.colors.brand;

interface DropdownSelectProps {
  items: { key: string; text: string }[];
  initialActiveItem?: { key: string; text: string };
  initialActive?: boolean;
  selectHandler: (item: string) => void;
  place: string;
}

export default function DropdownSelect({
  items,
  initialActiveItem = items[0],
  initialActive = false,
  selectHandler,
  place
}: DropdownSelectProps): React.ReactElement {
  const [isActive, setIsActive] = useState(initialActive);
  const [isOpen, setIsOpen] = useState(initialActive);
  const [activeItem, setActiveItem] = useState(initialActiveItem);
  const [height, setHeight] = useState("0px");
  const dropdownRef = useRef(null);
  const chatLink = `https://demos.xweather.com/data-maps/examples/nimbus/weather-station-chat/?station=${place}`;

  const baseStyles = {
    backgroundColor: colors.green.base,
    color: colors.white.base,
    borderRadius: "22px",
    height: "44px",
    overflow: "hidden"
  };

  const variants = {
    active: Object.assign({}, baseStyles, {
      height: height
    }),
    inactive: Object.assign({}, baseStyles, {})
  };

  function handleClick() {
    const open = !isOpen;
    if (open) {
      setIsActive(true);
    }
    setIsOpen(open);
  }

  function handleSelect(item: { key: string; text: string }) {
    setActiveItem(item);
    selectHandler(item.key);
  }

  useEffect(() => {
    const resizeCallback = _debounce(() => {
      const offsetHeight = _get(dropdownRef, "current.offsetHeight", null);
      if (_isNil(offsetHeight) === true) {
        return;
      }
      setHeight(`${Number(dropdownRef.current.offsetHeight + 10)}px`);
    }, 200);
    window.addEventListener("resize", resizeCallback);
    return () => window.removeEventListener("resize", resizeCallback);
  }, []);

  useLayoutEffect(() => {
    setHeight(`${Number(dropdownRef.current.offsetHeight + 10)}px`);
  }, [dropdownRef]);

  return (
    <Box position="relative" zIndex={Z.dropdown}>
      {isActive && (
        <motion.div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0,0,0,0.8)"
          }}
          variants={{
            active: {
              opacity: 1
            },
            inactive: {
              opacity: 0
            }
          }}
          animate={isOpen ? "active" : "inactive"}
          initial={"inactive"}
          transition={{
            duration: 0.3,
            ease: "easeOut"
          }}
          onClick={() => {
            setIsActive(false);
            setIsOpen(false);
          }}
          onAnimationComplete={() => {
            if (!isOpen) {
              setIsActive(false);
            }
          }}
        />
      )}
      <Box position="relative" onClick={handleClick} px={2} zIndex={1} cursor="pointer">
        <Flex height="44px" justify="space-between" alignItems="center" px={3}>
          <Text color="brand.white.base" fontWeight="bold">
            {_startCase(activeItem.text)}
          </Text>
          <Icon
            mr="-15px"
            height="24px"
            width="24px"
            name={isOpen ? "chevron-up" : "chevron-down"}
            color="brand.white.base"
          />
        </Flex>
        <Absolute width="100%" height="44px" top="0" left="0" right="0" zIndex={-1}>
          <motion.div
            initial="inactive"
            variants={variants}
            animate={isOpen ? "active" : "inactive"}
            transition={{
              type: "spring",
              damping: 50,
              stiffness: 450
            }}
          >
            <div ref={dropdownRef}>
              <Box position="relative" pt="44px" px={2}>
                <Box borderTop="3px solid rgba(0,0,0,.3)">
                  <Box>
                    {items
                      .filter(item => item.key !== activeItem.key)
                      .map((item, i) => (
                        <PseudoBox
                          key={item.text}
                          px={3}
                          py="12px"
                          borderTop={i !== 0 && `1px solid rgba(0,0,0,.25)}`}
                          color="brand.green.100"
                          fontWeight="bold"
                          onClick={() => handleSelect(item)}
                          _hover={{
                            color: "brand.white.base",
                            cursor: "pointer"
                          }}
                        >
                          {_startCase(item.text)}
                        </PseudoBox>
                      ))}
                    <PseudoBox
                      px={3}
                      py="12px"
                      borderTop={`1px solid rgba(0,0,0,.25)}`}
                      color="brand.green.100"
                      fontWeight="bold"
                      // onClick={() => handleSelect(item)}
                      _hover={{
                        color: "brand.white.base",
                        cursor: "pointer"
                      }}
                    >
                      <a href={chatLink} target="_blank">
                        AI Chat
                      </a>
                    </PseudoBox>
                  </Box>
                </Box>
              </Box>
            </div>
          </motion.div>
        </Absolute>
      </Box>
    </Box>
  );
}
