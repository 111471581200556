import Forecasts from "../../../../../../models/aeris/forecast/Forecasts";
import Place from "../../../../../../models/aeris/place";
import { subMinutes } from "date-fns";
import _get from "lodash.get";

export default class ChartForecasts extends Forecasts {
  place: Place = null;
  getChartData(prop: string, models = this.models) {
    if (this.hasData === false) {
      return null;
    }
    const currentTimeZoneOffset = new Date().getTimezoneOffset();
    const offset = this.place.timeZoneOffsetInMinutes - currentTimeZoneOffset;
    return models.map(model => {
      let data = _get(model, prop);
      data = data === null ? 0 : data;
      return {
        x: subMinutes(model.date, offset),
        y: data
      };
    });
  }
  get temps() {
    return this.getChartData("temp");
  }
  get feelsLike() {
    return this.getChartData("feelsLike");
  }
  get dewpoints() {
    return this.getChartData("dewpoint");
  }
  get wind() {
    return this.getChartData("avgWindSpeed");
  }
  get windGust() {
    return this.getChartData("avgWindGust");
  }
  get windDirection() {
    return this.getChartData("windDirectionDegrees");
  }
  get pressure() {
    return this.getChartData("averagePressure");
  }
  get precip() {
    return this.getChartData("precip");
  }
  get accumulatedPrecip() {
    if (this.hasData === false) {
      return;
    }
    const modelsWithAccumulation: any[] = [];
    this.models.reduce((prev, curr) => {
      const accumulatedPrecip = prev + curr.precip;
      modelsWithAccumulation.push({
        date: curr.date,
        accumulatedPrecip
      });
      return accumulatedPrecip;
    }, 0);
    return this.getChartData("accumulatedPrecip", modelsWithAccumulation);
  }
  get snow() {
    return this.getChartData("snow");
  }
  get accumulatedSnow() {
    if (this.hasData === false) {
      return;
    }
    const modelsWithAccumulation: any[] = [];
    this.models.reduce((prev, curr) => {
      const result = prev + curr.snow;
      modelsWithAccumulation.push({
        date: curr.date,
        accumulatedSnow: result
      });
      return result;
    }, 0);
    return this.getChartData("accumulatedSnow", modelsWithAccumulation);
  }
  get humidity() {
    return this.getChartData("humidity");
  }
  get precipProb() {
    return this.getChartData("precipProb");
  }
  get skyCover() {
    return this.getChartData("skyCover");
  }
}
