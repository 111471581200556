import Forecast from ".";
import Collection from "../../Collection";
import _get from "lodash.get";
import _isNil from "lodash.isnil";
import { unitStore } from "../../../react/common/hooks/stores/useUnitStore";
import { UnitCategory, UnitTypes, ApiProps, ApiPropGroups, Units } from "../../../types/enums";
import DataFormatter from "../../../models/aeris/observation/DataFormatter";

export default class Forecasts extends Collection {
  _precipAccumulation: number = null;
  _precipAccumulationByPeriod: number[] = null;
  _snowAccumulation: number = null;
  _snowAccumulationByPeriod: number[] = null;

  constructor(config: any) {
    super(config);
    const data = _get(config, "data.periods", null);
    if (data !== null) {
      this.models = data.map((datum: {}) => new Forecast({ data: datum }));
    }
  }

  get accumulatedPrecip(): any {
    if (this._precipAccumulation !== null) {
      return this._precipAccumulation;
    }
    this.accumulatePrecip();
    return this._precipAccumulation;
  }

  get precipAccumulationByPeriod() {
    if (this._precipAccumulationByPeriod !== null) {
      return this._precipAccumulationByPeriod;
    }
    this.accumulatePrecip();
    return this._precipAccumulationByPeriod;
  }

  get accumulatedSnow(): any {
    if (this._snowAccumulation !== null) {
      return this._snowAccumulation;
    }
    this.accumulateSnow();
    return this._snowAccumulation;
  }

  get snowAccumulationByPeriod() {
    if (this._snowAccumulationByPeriod !== null) {
      return this._snowAccumulationByPeriod;
    }
    this.accumulateSnow();
    return this._snowAccumulationByPeriod;
  }

  private accumulatePrecip() {
    this._precipAccumulation = this.models.reduce((prev, curr) => {
      if (this._precipAccumulationByPeriod === null) {
        this._precipAccumulationByPeriod = [];
      }

      let val = curr.precip;
      if (_isNil(val) || Number.isNaN(val)) {
        this._precipAccumulationByPeriod.push(prev);
        return prev;
      }

      const result = prev + Number(val);
      this._precipAccumulationByPeriod.push(result);

      return result;
    }, 0);
  }

  private accumulateSnow() {
    this._snowAccumulation = this.models.reduce((prev, curr) => {
      if (this._snowAccumulationByPeriod === null) {
        this._snowAccumulationByPeriod = [];
      }

      let val = curr.snow;
      if (_isNil(val) || Number.isNaN(val)) {
        this._snowAccumulationByPeriod.push(prev);
        return prev;
      }

      const result = prev + Number(val);
      this._snowAccumulationByPeriod.push(result);

      return result;
    }, 0);
  }
}
