import { Box, Button, Col, Flex } from "pws-design-system/design-system";
import React, { useEffect } from "react";
import Place from "../../../../models/aeris/place";
import PlaceMeta from "../../../../models/aeris/place/PlaceMeta";
import Path from "../../../../models/path/Path";
import ResponsiveRender from "../../../common/components/responsive-render";
import { dateStore, UseDateContainer } from "../../../common/hooks/stores/useDateStore";
import { useInstallPrompt } from "../../../common/hooks/useInstallPrompt";
import { ThemeContainer } from "../../../common/hooks/useTheme";
import PlaceLayout from "../common/Layout";
import ForecastCard from "./cards/forecast";
import HistoricalCard from "./cards/historical/";
import LatestObservationCard from "./cards/latest-observation";
import AlertsCard from "./cards/alerts";
import NearbyStationsCard from "./cards/nearby-stations";
import StationMap from "./cards/station-map";

const DashboardFlexCard = (props: any): React.ReactElement => (
  <Box flexBasis="100%" mx="0.5rem" {...props} />
);

interface DashboardPageProps {
  isMetric: Boolean;
  place: Place;
  path: Path;
}

function DashboardPageDisplay(props): React.ReactElement {
  const { date, setTimezone } = UseDateContainer.useContainer();

  const installPrompt = useInstallPrompt();

  const placeMeta = new PlaceMeta(props.place, props.path);

  const onClick = () => {
    installPrompt.prompt();
  };

  useEffect(() => {
    setTimezone(props.place.timezone);
  }, []);

  return (
    <PlaceLayout
      {...props}
      manifest={props.manifest}
      metaTitle={placeMeta.buildMeta("dashboard", "title")}
      metaDescription={placeMeta.buildMeta("dashboard", "description")}
    >
      <Flex flexWrap="wrap" px="0.5rem">
        <DashboardFlexCard flexBasis={["100%", null, "67%"]} flexGrow={1}>
          <AlertsCard placeId={props.place.id} path={props.path} />
        </DashboardFlexCard>
        <DashboardFlexCard flexBasis={["100%", null, "67%"]} flexGrow={1}>
          <LatestObservationCard placeId={props.place.id} path={props.path} />
        </DashboardFlexCard>
        <DashboardFlexCard flexBasis={["100%", null, "30%"]}>
          <StationMap place={props.place} type={props.path.type} />
        </DashboardFlexCard>
        <DashboardFlexCard flexBasis={["100%", null, "40%"]} flexGrow={1}>
          <NearbyStationsCard placeId={props.place.id} />
        </DashboardFlexCard>
        <ResponsiveRender size={["tablet", "desktop"]}>
          <DashboardFlexCard flexBasis={["100%", null, "40%"]} flexGrow={1}>
            <ForecastCard placeId={props.place.id} forecastUrl={props.path.urls.forecast} />
          </DashboardFlexCard>
          <DashboardFlexCard width="100%">
            {date && <HistoricalCard placeId={props.place.id} timezone={props.place.timezone} />}
          </DashboardFlexCard>
        </ResponsiveRender>
      </Flex>
      <ResponsiveRender size={["mobile"]}>
        {installPrompt && <Button onClick={onClick}>Add to Home Screen</Button>}
      </ResponsiveRender>
    </PlaceLayout>
  );
}

export default function DashboardPage(props) {
  return <DashboardPageDisplay {...props}>{props.children}</DashboardPageDisplay>;
}
