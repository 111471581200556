import Collection from "../../Collection";
import Alert from ".";
import _get from "lodash.get";
import * as yup from "yup";

export default class Alerts extends Collection {
  constructor(config) {
    super(config);
    this.models = config.data.map(datum => new Alert({ data: datum }));
  }
}
