import ChartJs from "chart.js";
import { addDays, format, startOfDay } from "date-fns";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import _merge from "lodash.merge";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import uuid from "uuid/v4";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { datasetConfig } from "./config/defaultConfig";
import { TimeStoreContainer } from "../../../../common/hooks/stores/useTimeStore";
import { ClockType } from "../../../../../types/enums";
import { ThemeContainer } from "../../../../common/hooks/useTheme";
import { PressureTypeContainer } from "../../../../common/hooks/stores/usePressureTypeStore";

ChartJs.defaults.LineWithLine = ChartJs.defaults.line;
ChartJs.controllers.LineWithLine = ChartJs.controllers.line.extend({
  draw: function(ease: any) {
    ChartJs.controllers.line.prototype.draw.call(this, ease);

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      var activePoint = this.chart.tooltip._active[0],
        ctx = this.chart.ctx,
        x = activePoint.tooltipPosition().x,
        topY = this.chart.scales["y-axis-0"].top,
        bottomY = this.chart.scales["y-axis-0"].bottom;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = "#222";
      ctx.stroke();
      ctx.restore();
    }
  }
});

export function yAxisLabelBuilder(fn: (value: string) => string) {
  return {
    scales: {
      yAxes: [
        {
          ticks: {
            callback: fn
          }
        }
      ]
    }
  };
}

const axisDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

export function datasetConfigBuilder(config) {
  return _merge(_cloneDeep(datasetConfig), config);
}

const timeAxesConfig = (date: Date, period: string, timeFormat, config): any => {
  const isMonthly = period === "monthly";
  const startDate = isMonthly ? startOfMonth(date) : startOfDay(date);
  const endDate = isMonthly ? startOfDay(endOfMonth(startDate)) : addDays(startDate, 1);
  return {
    time: {
      unit: isMonthly ? "day" : "hour",
      tooltipFormat:
        timeFormat === ClockType.TwelveHour ? "MMM D, YYYY h:mm a" : "MMM D, YYYY H:mm",
      displayFormats: {
        hour: timeFormat === ClockType.TwelveHour ? "hA" : "H:mm"
      }
    },
    ticks: {
      ...config.options.scales.xAxes[0].ticks,
      min: format(startDate, axisDateFormat),
      max: format(endDate, axisDateFormat)
    }
  };
};

export default function Chart({ config, date, period, collection }) {
  const { theme } = ThemeContainer.useContainer();
  const { pressureType } = PressureTypeContainer.useContainer();
  const chartEl = useRef(null);
  const [chart, setChart] = useState(null);
  const { units, unitType } = UnitStoreContainer.useContainer();
  const { timeFormat } = TimeStoreContainer.useContainer();
  const uid = useRef(uuid());

  const timeAxes = _get(config, "options.scales.xAxes");
  if (timeAxes && timeAxes.length > 0) {
    // const startDate = new Date();
    timeAxes[0] = {
      ...timeAxes[0],
      ...timeAxesConfig(date, period, timeFormat, config)
    };
  }

  function update() {
    if (Array.isArray(collection.models) === false) {
      return;
    }

    const timeAxes = _get(config, "options.scales.xAxes");
    if (timeAxes && timeAxes.length > 0) {
      timeAxes[0] = {
        ...timeAxes[0],
        ...timeAxesConfig(date, period, timeFormat, config)
      };
    }

    chart.data.datasets = [];
    config.data.datasets.forEach(dataset => {
      chart.data.datasets.push(dataset);
    });

    chart.options = {
      ...config.options,
      animation: {
        duration: 0
      }
    };
    chart.update();
  }

  useEffect(() => {
    let chart = new ChartJs(chartEl.current, config);
    setChart(chart);
    return function() {
      const tooltip = document.getElementById("chartjs-tooltip");
      if (tooltip) {
        tooltip.remove();
      }
      chart.destroy();
    };
  }, [theme]);

  useLayoutEffect(() => {
    if (chart !== null && chart.data) {
      update();
    }
  }, [collection]);

  useEffect(() => {
    if (chart !== null) {
      update();
    }
  }, [units, unitType, timeFormat, pressureType]);

  return <canvas id={uid.current} ref={chartEl} />;
}
