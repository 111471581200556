import React from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  Theme,
  WeatherIcon
} from "pws-design-system/design-system";
import Forecasts from "../../../../../models/aeris/forecast/Forecasts";

type DailyListPanelProps = any & {
  forecasts: Forecasts;
  onSelect?: (date: Date) => void;
};

const DailyListPanel = ({ forecasts, onSelect }: DailyListPanelProps): React.ReactElement => {
  const handleClick = (date: Date) => {
    if (onSelect) {
      onSelect(date);
    }
  };

  return (
    <>
      {forecasts.models.map((forecast, i: number) => {
        return (
          <Box
            width="100%"
            borderTop={i !== 0 && `1px solid ${Theme.colors.brand.gray["300"]}`}
            py={3}
            px={2}
            onClick={() => handleClick(forecast.date)}
          >
            <Heading as="h4" size="md" mb={2}>
              {forecast.dayOfWeek}
            </Heading>
            <Flex width="100%" justify="space-between" align="center">
              <Flex align="center">
                <WeatherIcon code={forecast.weatherShortCode} width={50} height={50} />
                <Box px={3}>
                  <Text variant="caption1">{forecast.weatherPrimary}</Text>
                </Box>
              </Flex>
              <Flex align="center">
                <Flex flexDirection="column" align="center">
                  <Flex justify="flex-end" width="50px">
                    <Text variant="value" fontSize="4xl">
                      {forecast.maxTemp}
                    </Text>
                    <Icon name="arrow-up" height="16px" color="red.600" mt="4px" />
                  </Flex>
                  <Flex justify="flex-end" width="50px">
                    <Text variant="value" fontSize="3xl">
                      {forecast.minTemp}
                    </Text>
                    <Icon name="arrow-down" height="16px" color="blue.500" mt="2px" />
                  </Flex>
                </Flex>
                <Icon
                  name="chevron-right"
                  color="brand.gray.500"
                  width="32px"
                  height="32px"
                  ml={2}
                />
              </Flex>
            </Flex>
          </Box>
        );
      })}
    </>
  );
};

export default DailyListPanel;
