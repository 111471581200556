import React, { useEffect, useState } from "react";
import _identity from "lodash.identity";
import { TimeStoreContainer } from "../../hooks/stores/useTimeStore";

interface TimeProps {
  /**
   * toLocaleTimeString config
   */
  config?: {
    hour: "numeric" | "2-digit";
    minute: "numeric" | "2-digit";
    timeZone: string;
  };
  /**
   * Processes the time string after generation
   */
  postProcessor: any;
  /**
   * Delay to update time
   */
  delay?: number;
}

/**
 * Displays the time and updates on an interval
 */
const Time = ({ config, postProcessor = _identity, delay = 1000 }: TimeProps) => {
  const { timeFormat, getLocaleToken } = TimeStoreContainer.useContainer();

  const newTime = () => {
    return postProcessor(new Date().toLocaleTimeString(getLocaleToken(), config));
  };

  const [time, setTime] = useState(newTime());

  useEffect(() => {
    setTime(newTime());
  }, [timeFormat]);

  useEffect(() => {
    setTime(newTime());
    const handle = setInterval(() => setTime(newTime()), delay);
    return () => clearInterval(handle);
  }, [time, config]);

  return <>{time}</>;
};

export default Time;
