import { navigate } from "gatsby";
import {
  Box,
  Button,
  Flex,
  Heading,
  Hide,
  Stack,
  Link,
  Stat,
  Text,
  WeatherIcon
} from "pws-design-system/design-system";
import React, { useState } from "react";
import Observation from "../../../../../../models/aeris/observation/Observation";
import Path from "../../../../../../models/path/Path";
import { Views } from "../../../../../../types/enums";
import ErrorBoundary from "../../../../../common/components/error-boundary";
import CardProps from "../CardProps";
import PwsCardDisplay from "../PwsCardDisplay";
import _get from "lodash.get";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";
import useAlertsQuery from "./useAlertsQuery";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ChakraProvider,
  HStack
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { ThemeProvider } from "emotion-theming";

interface LatestObservationsCardProps extends CardProps {
  initialObservation?: Observation;
  path: Path;
}

function AlertsCard({ placeId }: { placeId: any }) {
  const [alerts, state] = useAlertsQuery(placeId);

  if (alerts === null) {
    return <></>;
  }

  return <AlertsCardDisplay state={state} alerts={alerts}></AlertsCardDisplay>;
}

function AlertsCardDisplay({ alerts, state }: { alerts: any; state: any }) {
  const { theme } = ThemeContainer.useContainer();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentAlert, setCurrentAlert] = useState<any>({});

  const showAlertDetails = (data: any) => {
    setCurrentAlert(data);
    onOpen();
  };

  return (
    <PwsCardDisplay
      HeadingSlot={() => <></>}
      boxShadow="sm"
      bg={theme.name == "dark" ? "#cc9f9f" : "#FED7D7"}
      borderWidth="2px"
      borderStyle="solid"
      borderColor="red.600"
      mb={3}
      state={state}
    >
      {alerts !== null && (
        <>
          <ChakraProvider portalZIndex={10}>
            <Modal size="lg" isOpen={isOpen} onClose={onClose}>
              <ModalOverlay bg="rgba(0,0,0,0.5)" zIndex={0} />
              <Hide mobile>
                <ModalContent maxWidth="50%" zIndex={1} rounded="0.75em">
                  <ModalHeader bg="#eee" p="32px" mb={20} rounded="0.75em 0.75em 0 0">
                    <Flex>
                      <Box
                        bg={`#${currentAlert.color}`}
                        w="32px"
                        h="32px"
                        mr="12px"
                        borderRadius="50%"
                      />
                      <Flex align="baseline">
                        <Text fontWeight="bold" fontSize="xl">
                          {currentAlert.name}
                        </Text>
                        <Text fontSize="md" ml={2}>
                          {currentAlert.expires}
                        </Text>
                      </Flex>
                    </Flex>
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody p="32px">
                    <Text dangerouslySetInnerHTML={{ __html: currentAlert.body }}></Text>
                  </ModalBody>
                  <ModalFooter p="32px">
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Hide>
              <Hide tablet desktop>
                <ModalContent maxWidth="75%" zIndex={1} rounded="0.75em" position="relative">
                  <ModalHeader bg="#eee" p="32px" mb={20} rounded="0.75em 0.75em 0 0">
                    <Flex>
                      <Flex align="baseline">
                        <Text fontWeight="bold" fontSize="xl">
                          {currentAlert.name}
                        </Text>
                      </Flex>
                    </Flex>
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody p="32px">
                    <Text dangerouslySetInnerHTML={{ __html: currentAlert.body }}></Text>
                  </ModalBody>
                  <ModalFooter p="32px">
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Hide>
            </Modal>
          </ChakraProvider>
          <Heading as="h2" variant="subheadline" color="red.900" mb={1}>
            Active Alerts
          </Heading>
          {alerts.models.map((alert: any, i: number) => (
            <HStack
              key={alert.name}
              spacing={8}
              color="red.900"
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
              onClick={() => showAlertDetails(alert)}
            >
              <Text fontWeight="bold">{alert.name}</Text>
              <Hide mobile>
                <Text>{alert.expires}</Text>
              </Hide>
            </HStack>
          ))}
        </>
      )}
    </PwsCardDisplay>
  );
}

const AlertsCardWithErrorBoundary: React.FC<LatestObservationsCardProps> = (
  props
): React.ReactElement => {
  return (
    <ErrorBoundary variant="card" minHeight="120px">
      <AlertsCard {...props} />
    </ErrorBoundary>
  );
};

export default AlertsCardWithErrorBoundary;
