import Measurement from "../Measurement";
import { isToday, format } from "date-fns";
import { unitStore } from "../../../react/common/hooks/stores/useUnitStore";
import DataFormatter from "../../../models/aeris/observation/DataFormatter";
import { UnitCategory, UnitTypes, ApiProps, ApiPropGroups, Units } from "../../../types/enums";
import { kmhToMs } from "../units";

export default class Forecast extends Measurement {
  get dateTimeIso() {
    return this.getData("dateTimeISO");
  }
  get date() {
    if (this.dateTimeIso === null) {
      return null;
    }
    return new Date(this.dateTimeIso);
  }
  get isDay() {
    return this.getData("isDay");
  }
  get isToday() {
    return isToday(this.date);
  }
  get dayOfWeek() {
    return format(this.date, "EEEE");
  }

  get tempF() {
    return this.getData("tempF");
  }
  get tempC() {
    return this.getData("tempC");
  }
  public get temp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.tempC : this.tempF;
    return value;
  }

  public get tempFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.temp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get tempFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.temp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get tempUnits() {
    const unitPref = unitStore[UnitCategory.Temp];
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref);
    return dataFormatter.units;
  }
  get windUnits() {
    const unitPref = unitStore[UnitCategory.Speed];
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref);
    return dataFormatter.units;
  }
  get pressureUnits() {
    const unitPref = unitStore[UnitCategory.Pressure];
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref);
    return dataFormatter.units;
  }
  get precipUnits() {
    const unitPref = unitStore[UnitCategory.Rain];
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref);
    return dataFormatter.units;
  }

  get maxTempF() {
    return this.getData("maxTempF");
  }
  get maxTempC() {
    return this.getData("maxTempC");
  }
  public get maxTemp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.maxTempC : this.maxTempF;
    return value;
  }

  public get maxTempFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.maxTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get maxTempFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.maxTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get minTempF() {
    return this.getData("minTempF");
  }
  get minTempC() {
    return this.getData("minTempC");
  }
  get minTemp() {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.minTempC : this.minTempF;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }
  public get minTemp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.minTempC : this.minTempF;
    return value;
  }

  public get minTempFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.minTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get minTempFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.minTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get avgTempF() {
    return this.getData("avgTempF");
  }
  get avgTempC() {
    return this.getData("avgTempC");
  }
  get avgTemp() {
    return this.isMetric ? this.avgTempC : this.avgTempF;
  }
  public get avgTemp(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.avgTempC : this.avgTempF;
    return value;
  }

  public get avgTempFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.avgTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get avgTempFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.avgTemp;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get feelsLikeF() {
    return this.getData("feelslikeF");
  }
  get feelsLikeC() {
    return this.getData("feelslikeC");
  }
  public get feelsLike(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.feelsLikeC : this.feelsLikeF;
    return value;
  }

  public get feelsLikeFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.feelsLike;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get feelsLikeFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.feelsLike;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get dewpointF() {
    return this.getData("dewpointF");
  }
  get dewpointC() {
    return this.getData("dewpointC");
  }
  public get dewpoint(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.dewpointC : this.dewpointF;
    return value;
  }

  public get dewpointFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewpoint;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get dewpointFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.dewpoint;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get avgDewpointF() {
    return this.getData("avgDewpointF");
  }
  get avgDewpointC() {
    return this.getData("avgDewpointC");
  }
  public get avgDewpoint(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = unitPref === "metric" ? this.avgDewpointC : this.avgDewpointF;
    return value;
  }

  public get avgDewpointFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.avgDewpoint;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get avgDewpointFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Temp];
    const value = this.avgDewpoint;
    const dataFormatter = new DataFormatter(UnitCategory.Temp, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get humidity() {
    return this.getData("humidity");
  }

  get weatherPrimary() {
    return this.getData("weatherPrimary");
  }
  get weatherShortCode() {
    const icon = this.getData("icon");
    return icon.substring(0, icon.length - 4);
  }

  get avgWindSpeedKph() {
    return this.getData("windSpeedKPH");
  }
  get avgWindSpeedMph() {
    return this.getData("windSpeedMPH");
  }
  get avgWindSpeedKts() {
    return this.getData("windSpeedKTS");
  }
  get avgWindSpeedMps() {
    const kmh = this.avgWindSpeedKph;
    const mps = kmhToMs(kmh);
    return mps;
  }

  public get avgWindSpeed(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitPref === "custom") {
      if (unitChoice === "kts") {
        return this.avgWindSpeedKts;
      } else if (unitChoice === "m/s") {
        return this.avgWindSpeedMps;
      }
    }
    const value = unitPref === "metric" ? this.avgWindSpeedKph : this.avgWindSpeedMph;
    return value;
  }

  public get avgWindSpeedFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.avgWindSpeed;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get avgWindSpeedFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.avgWindSpeed;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get windMinKph() {
    return this.getData("windSpeedMinKPH");
  }
  get windMinMph() {
    return this.getData("windSpeedMinMPH");
  }
  get windMinKts() {
    return this.getData("windSpeedMinKTS");
  }
  get windMinMps() {
    const kmh = this.windMinKph;
    const mps = kmhToMs(kmh);
    return mps;
  }
  get windMin() {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitPref === "custom") {
      if (unitChoice === "kts") {
        return this.windMinKts;
      } else if (unitChoice === "m/s") {
        return this.windMinMps;
      }
    }
    const value = unitPref === "metric" ? this.windMinKph : this.windMinMph;
    return value;
  }
  get windMaxKph() {
    return this.getData("windSpeedMaxKPH");
  }
  get windMaxMph() {
    return this.getData("windSpeedMaxMPH");
  }
  get windMaxKts() {
    return this.getData("windSpeedMaxKTS");
  }
  get windMaxMps() {
    const kmh = this.windMaxKph;
    const mps = kmhToMs(kmh);
    return mps;
  }
  get windMax() {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitPref === "custom") {
      if (unitChoice === "kts") {
        return this.windMaxKts;
      } else if (unitChoice === "m/s") {
        return this.windMaxMps;
      }
    }
    const value = unitPref === "metric" ? this.windMaxKph : this.windMaxMph;
    return value;
  }

  get avgWindGustKph() {
    return this.getData("windGustKPH");
  }
  get avgWindGustMph() {
    return this.getData("windGustMPH");
  }
  get avgWindGustKts() {
    return this.getData("windGustKTS");
  }
  get avgWindGustMps() {
    const kmh = this.avgWindGustKph;
    const mps = kmhToMs(kmh);
    return mps;
  }
  public get avgWindGust(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const unitChoice = unitStore.values["speed"];
    if (unitPref === "custom") {
      if (unitChoice === "kts") {
        return this.avgWindGustKts;
      } else if (unitChoice === "m/s") {
        return this.avgWindGustMps;
      }
    }
    const value = unitPref === "metric" ? this.avgWindGustKph : this.avgWindGustMph;
    return value;
  }

  public get avgWindGustFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.avgWindGust;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get avgWindGustFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Speed];
    const value = this.avgWindGust;
    const dataFormatter = new DataFormatter(UnitCategory.Speed, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get windDirection() {
    return this.getData("windDir");
  }

  get windDirectionDegrees() {
    return this.getData("windDirDEG");
  }

  get precipMm() {
    return this.getData("precipMM");
  }
  get precipIn() {
    return this.getData("precipIN");
  }
  public get precip(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = unitPref === "metric" ? this.precipMm : this.precipIn;
    return value;
  }

  public get precipFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precip;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get precipFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Rain];
    const value = this.precip;
    const dataFormatter = new DataFormatter(UnitCategory.Rain, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get snowCm() {
    return this.getData("snowCM");
  }
  get snowIn() {
    return this.getData("snowIN");
  }

  public get snow(): string | number | null {
    const unitPref = unitStore[UnitCategory.Snow];
    const value = unitPref === "metric" ? this.snowCm : this.snowIn;
    return value;
  }

  public get snowFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Snow];
    const value = this.snow;
    const dataFormatter = new DataFormatter(UnitCategory.Snow, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get snowFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Snow];
    const value = this.snow;
    const dataFormatter = new DataFormatter(UnitCategory.Snow, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get averagePressureIn() {
    return this.getData("pressureIN");
  }

  get averagePressureMb() {
    return this.getData("pressureMB");
  }

  public get averagePressure(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = unitPref === "metric" ? this.averagePressureMb : this.averagePressureIn;
    return value;
  }

  public get averagePressureFormatted(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.averagePressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValue;
  }

  public get averagePressureFormattedWithUnits(): string | number | null {
    const unitPref = unitStore[UnitCategory.Pressure];
    const value = this.averagePressure;
    const dataFormatter = new DataFormatter(UnitCategory.Pressure, unitPref, value);
    return dataFormatter.formattedValueWithUnits;
  }

  get averagePrecipIn() {
    return this.getData("precipIN");
  }
  get averagePrecipMm() {
    return this.getData("precipMM");
  }
  get averagePrecip() {
    return this.isMetric ? this.averagePrecipMm : this.averagePrecipIn;
  }
  get precipProb() {
    return this.getData("pop");
  }
  get skyCover() {
    return this.getData("sky");
  }
}
