import React, { useEffect } from "react";
import { Box } from "pws-design-system/design-system";
import Collection from "../../../../../../../models/Collection";
import Place from "../../../../../../../models/aeris/place/Place";
import IsMetric from "../../../../../../common/hooks/useIsMetric";
import _has from "lodash.has";
import _keys from "lodash.keys";
import _pickBy from "lodash.pickby";
import _get from "lodash.get";
import {
  Table,
  Thead as TableHeader,
  Th as TableHeadCell,
  Tr as TableRow,
  Td as TableCell,
  DesktopTd,
  DesktopTh
} from "../../nearby-stations/Table";
import { ThemeContainer } from "../../../../../../common/hooks/useTheme";
import { PressureTypeContainer } from "../../../../../../common/hooks/stores/usePressureTypeStore";

const { formatToTimeZone } = require("date-fns-timezone");

type ColumnProps = {
  label: string;
  data: any;
  units?: string;
  span?: number;
};

type TabularSummaryPanelProps = {
  collection: Collection;
  place: Place;
};

const TabularSummaryPanel = ({ collection, place }: TabularSummaryPanelProps) => {
  const { theme } = ThemeContainer.useContainer();
  const { pressureType } = PressureTypeContainer.useContainer();
  const [isMetric] = IsMetric.useContainer();

  useEffect(() => {}, [isMetric]);

  const headers = [
    {
      text: "Date"
    },
    {
      text: "Temperature",
      colSpan: 3
    },
    {
      text: "Dew Point",
      colSpan: 3
    },
    {
      text: "Winds",
      colSpan: 2
    },
    {
      text: "Humidity",
      colSpan: 3
    },
    {
      text: `Pressure`,
      colSpan: 2
    },
    {
      text: "Precip"
    }
  ];

  const columns: ColumnProps[] = [
    {
      label: "Day",
      data: (index: number) =>
        formatToTimeZone(collection.models[index].date, "M/D/YY", {
          timeZone: place.timezone
        })
    },
    {
      label: "Min",
      data: (index: number) => {
        return collection.models[index].minTempFormattedWithUnits || "--";
      }
    },
    {
      label: "Max",
      data: (index: number) => {
        return collection.models[index].maxTempFormattedWithUnits || "--";
      }
    },
    {
      label: "Avg",
      data: (index: number) => {
        return collection.models[index].avgTempFormattedWithUnits || "--";
      }
    },
    {
      label: "Min",
      data: (index: number) => {
        return collection.models[index].dewptMinFormattedWithUnits || "--";
      }
    },
    {
      label: "Max",
      data: (index: number) => {
        return collection.models[index].dewptMaxFormattedWithUnits || "--";
      }
    },
    {
      label: "Avg",
      data: (index: number) => {
        return collection.models[index].dewptAvgFormattedWithUnits || "--";
      }
    },
    {
      label: "Max",
      data: (index: number) => {
        return collection.models[index].maxWindFormattedWithUnits || "--";
      }
    },
    {
      label: "Avg",
      data: (index: number) => {
        return collection.models[index].avgWindFormattedWithUnits || "--";
      }
    },
    {
      label: "Min",
      data: (index: number) => {
        return collection.models[index].has("rhMin") ? collection.models[index].rhMin + "%" : "--";
      }
    },
    {
      label: "Max",
      data: (index: number) => {
        return collection.models[index].has("rhMax") ? collection.models[index].rhMax + "%" : "--";
      }
    },
    {
      label: "Avg",
      data: (index: number) => {
        return collection.models[index].has("rhAvg") ? collection.models[index].rhAvg + "%" : "--";
      }
    },
    {
      label: "Min",
      data: (index: number) => {
        return collection.models[index].minPressureByType
          ? `${collection.models[index].minPressureByType.toFixed(2)}${
              collection.models[index].pressureUnits
            }`
          : "--";
      }
    },
    {
      label: "Max",
      data: (index: number) => {
        return collection.models[index].maxPressureByType
          ? `${collection.models[index].maxPressureByType.toFixed(2)}${
              collection.models[index].pressureUnits
            }`
          : "--";
      }
    },
    {
      label: "",
      data: (index: number) => {
        return collection.models[index].precipTotalFormattedWithUnits || "--";
      }
    }
  ];

  return (
    <Box overflow="auto" width="100%">
      <Table width="100%">
        <TableHeader>
          <TableRow bg="#3aba50" color="white">
            {headers.map((header, index) => (
              <TableHeadCell
                key={`summary-table-header-${index}`}
                colSpan={_get(header, "colSpan", 1)}
                minWidth={_get(header, "minWidth")}
                whiteSpace="nowrap"
                p="8px 0"
              >
                {header.text}{" "}
              </TableHeadCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.map((column, index) => (
              <TableHeadCell
                scope="col"
                colSpan={column.span}
                border="bottom"
                key={`summary-table-subheader-${index}`}
              >
                {column.label} {_has(column, "units") === true && `(${column.units})`}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHeader>
        <tbody>
          {collection.models.map((model, index: number) => (
            <TableRow
              key={`summary-table-row-${index}`}
              variant="light"
              borderBottomColor={theme.components.table.borderBottomColor}
            >
              {columns.map((column, colIndex) => (
                <TableCell
                  colSpan={column.span}
                  key={`summary-table-row-${index}-${colIndex}`}
                  fontSize="13px"
                  fontWeight={colIndex === 0 ? "bold" : null}
                >
                  {column.data(index)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

export default TabularSummaryPanel;
