import React from "react";
import ChartObservations from "./chart-model/ChartObservations";
import DashboardChart from "./DashboardChart";
import { isMetricStore } from "../../../../common/hooks/useIsMetric";
import { CardPeriod } from "../../../../../types/enums";
import { unitForProp } from "../../../../../models/aeris/units";
import _merge from "lodash.merge";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { UnitCategory } from "../../../../../types/enums";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

interface SnowChartProps {
  collection: ChartObservations;
  date: Date;
  period: CardPeriod;
  config: any;
}

export default function SnowChart({ collection, date, period, config = {} }: SnowChartProps) {
  const { unitStore } = UnitStoreContainer.useContainer();
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;

  function labelConfigCallback(value: string) {
    return `${value} ${unitForProp("snow", unitStore[UnitCategory.Snow] === "metric")}`;
  }

  const datasetConfigs = [
    {
      type: "bar",
      data: collection.snow,
      label: "Snow",
      key: "snow",
      borderColor: dataColors.snow,
      backgroundColor: dataColors.snow,
      pointBackgroundColor: dataColors.snow,
      maxBarThickness: 8
    },
    {
      data: collection.accumulatedSnow,
      label: "Accumulated Snow",
      key: "accumulatedSnow",
      borderColor: dataColors.snowaccum,
      pointBackgroundColor: dataColors.snowaccum
    }
  ];

  return (
    <DashboardChart
      period={period}
      date={date}
      chartConfig={config}
      datasetConfigs={datasetConfigs}
      labelConfigCallback={labelConfigCallback}
      collection={collection}
      startsAtZero={true}
    />
  );
}
