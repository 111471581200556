import { css } from "@emotion/react";
import { navigate } from "gatsby";
import { motion, useAnimation } from "framer-motion";
import _isNil from "lodash.isnil";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Popover,
  Text,
  Theme
} from "pws-design-system/design-system";
import { Table, Thead, Tbody, Tr as Ctr, Th as Cth, Td as Ctd } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Place from "../../../../../../models/aeris/place/Place";
import { unitForProp } from "../../../../../../models/aeris/units";
import { buildStationPath } from "../../../../../../models/path/";
import ErrorBoundary from "../../../../../common/components/error-boundary";
import IsMetric from "../../../../../common/hooks/useIsMetric";
import { UnitStoreContainer } from "../../../../../common/hooks/stores/useUnitStore";
import { Td, Th, Tr, DesktopTd, DesktopTh } from "./Table";

import PwsCardDisplay from "../PwsCardDisplay";
import useNearbyStationsQuery from "./useNearbyStationsQuery";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";
import { PressureTypeContainer } from "../../../../../common/hooks/stores/usePressureTypeStore";

type NearbyStationsCardProps = any & {};
type NearbyStationsCardDisplayProps = {
  place: Place;
  nearbyStations: Place[];
  page: number;
  state: string;
  pageSize: number;
  totalPages: number;
  rowClickHandler: (id: string) => void;
  paginationChangeHandler: (page: number) => void;
};

const NearbyStationsCard = ({
  placeId,
  initialPage = 1,
  initialTotalPages = 0,
  pageSize = 4
}: NearbyStationsCardProps): React.ReactElement => {
  const [isMetric] = IsMetric.useContainer();
  const [page, setPage] = useState(initialPage);
  const [place, state] = useNearbyStationsQuery({ placeId });
  const [totalPages, setTotalPages] = useState(initialTotalPages);
  const [nearbyStations, setNearbyStations] = useState(null);

  useEffect(() => {
    setPage(1);
    setTotalPages(0);
  }, [placeId]);

  useEffect(() => {
    if (place.hasData) {
      const nearbyStations = place.nearbyStations.models.filter(
        nearbyStation => place.distanceTo(nearbyStation, isMetric, Math.floor) !== 0
      );
      setNearbyStations(nearbyStations);
      setTotalPages(Math.ceil(nearbyStations.length / pageSize));
    }
  }, [place]);

  function handleRowClick(id: string) {
    navigate(buildStationPath("dashboard", id));
  }

  function handlePaginationChange(newPage: number) {
    setPage(newPage);
  }

  return (
    <NearbyStationsCardDisplay
      place={place}
      nearbyStations={nearbyStations}
      state={state}
      page={page}
      pageSize={pageSize}
      totalPages={totalPages}
      rowClickHandler={handleRowClick}
      paginationChangeHandler={handlePaginationChange}
    />
  );
};

const NearbyStationsCardDisplay = ({
  place,
  nearbyStations,
  state,
  page,
  pageSize,
  totalPages,
  rowClickHandler,
  paginationChangeHandler
}: NearbyStationsCardDisplayProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  const { unitStore } = UnitStoreContainer.useContainer();
  const { pressureType } = PressureTypeContainer.useContainer();
  const [isAnimating, setIsAnimating] = useState(false);
  const bodyControls = useAnimation();
  const cellControls = useAnimation();
  const headControls = useAnimation();

  // useEffect(() => {}, [pressureStore]);
  const formatWind = observation => {
    if (observation.wind == null) {
      return "--";
    }

    if (observation.wind === 0) {
      return "Calm";
    }

    return (
      <>
        {observation.has("windDirection") && observation.windDirection}
        {observation.has("windDirection") && <br />}
        {observation.windFormatted}{" "}
        <Text as="span" fontWeight="normal" fontSize="sm" color={theme.colors.text.base.secondary}>
          {observation.windUnits}
        </Text>
      </>
    );
  };

  async function handlePaginationClick(newPage: number) {
    setIsAnimating(true);

    const direction = newPage < page ? 1 : -1;
    const shiftX = 10;
    const transitionProps = {
      easing: "ease-out",
      duration: 0.1
    };

    await Promise.all([
      bodyControls.start(i => {
        return {
          opacity: 0,
          x: [0, shiftX * direction],
          transition: transitionProps
        };
      }),
      headControls.start(i => {
        return {
          opacity: 0,
          x: [0, shiftX * direction],
          transition: transitionProps
        };
      })
    ]);
    paginationChangeHandler(newPage);
    await Promise.all([
      bodyControls.start(i => {
        return {
          opacity: 1,
          x: [-shiftX * direction, 0],
          transition: transitionProps
        };
      }),
      headControls.start(() => {
        return {
          opacity: 1,
          x: [-shiftX * direction, 0],
          transition: transitionProps
        };
      })
    ]);
    setIsAnimating(false);
  }
  return (
    <Box color={theme.colors.text.base.primary}>
      <PwsCardDisplay
        HeadingSlot={() => (
          <Heading as="h2" variant="subheadline">
            Nearby Stations
          </Heading>
        )}
        state={state}
        pb={1}
      >
        {place.hasData === true && nearbyStations !== null && nearbyStations.length === 0 && (
          <Flex align="center" justify="center" height="calc(100% - 20px)">
            No nearby stations found.
          </Flex>
        )}
        {place.hasData === true && nearbyStations !== null && nearbyStations.length > 0 && (
          <Box>
            <Box minHeight="270px" mb={2} overflowX={isAnimating === true ? "hidden" : "auto"}>
              <Table width="100%">
                <Thead>
                  <Tr>
                    <Th scope="col" colSpan="3" width={["160px", null, "200px"]}></Th>
                    <Th scope="col" textAlign="left">
                      Temp
                    </Th>
                    {/* <DesktopTh scope="col">
                      <motion.div>Dew Pt</motion.div>
                    </DesktopTh> */}
                    <Th scope="col" textAlign="left">
                      <motion.div>Winds</motion.div>
                    </Th>
                    <DesktopTh scope="col" textAlign="left">
                      <motion.div>Pressure</motion.div>
                    </DesktopTh>
                    <DesktopTh scope="col" textAlign="left">
                      <motion.div>Precip</motion.div>
                    </DesktopTh>
                  </Tr>
                </Thead>
                <motion.tbody maxHeight="200px" animate={bodyControls}>
                  {nearbyStations
                    .slice((page - 1) * pageSize, page * pageSize)
                    .map((nearbyStation, i) => (
                      <motion.tr
                        key={`nearby-stations-row-${i}`}
                        onClick={() => rowClickHandler(nearbyStation.id)}
                        animate={cellControls}
                        custom={i}
                        css={css`
                          cursor: pointer;
                          transition: all 150ms ease-out;
                          &:hover {
                            background: ${theme.components.card.nearbyStations.tableRow.hover.bg};
                          }
                        `}
                      >
                        <Td
                          width={"50px"}
                          onClick={(event: MouseEvent) => event.stopPropagation()}
                          align="left"
                          paddingLeft="8px"
                          paddingRight="8px"
                          variant="bordered"
                          borderBottomColor={
                            theme.components.card.nearbyStations.tableCell.borderColor
                          }
                        >
                          <Popover.Container trigger="hover" placement="right">
                            <Popover.Trigger>
                              <Icon name={`station-${nearbyStation.type}`} size="40px" />
                            </Popover.Trigger>
                            <Popover.Content
                              width="auto"
                              bg="rgba(0,0,0,0.9)"
                              border={0}
                              boxShadow="0 0 2px 0 rgba(0,0,0,.04),0 4px 16px 0 rgba(0,0,0,.16)"
                              borderRadius="xl"
                              zIndex={4}
                            >
                              <Popover.Arrow
                                css={css`
                                  :before {
                                    top: 0;
                                    left: 0;
                                  }
                                `}
                              />
                              <Popover.Body>
                                <Text
                                  as="div"
                                  variant="caption1"
                                  fontWeight="bold"
                                  color={Theme.colors.brand.white}
                                >
                                  {nearbyStation.type.toUpperCase()}
                                </Text>
                              </Popover.Body>
                            </Popover.Content>
                          </Popover.Container>
                        </Td>
                        <Th
                          scope="row"
                          colSpan="2"
                          textAlign="left"
                          width="120px"
                          maxWidth="120px"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          variant="cell"
                          borderBottomStyle="solid"
                          borderBottomWidth="1px"
                          borderBottomColor={
                            theme.components.card.nearbyStations.tableCell.borderColor
                          }
                        >
                          <Text as="span" fontWeight="bold">
                            {nearbyStation.has("displayId") &&
                              nearbyStation.displayId.toUpperCase()}
                          </Text>
                          <br />
                          <Text
                            as="span"
                            fontSize="xs"
                            whiteSpace="nowrap"
                            color={theme.text.secondary}
                          >
                            <Text fontSize="xs">
                              {place.distanceTo(nearbyStation, unitStore.distance === "metric")}
                              {unitForProp("distance", unitStore.distance === "metric")}{" "}
                              {place.directionTo(nearbyStation)} {nearbyStation.formattedPlaceShort}
                            </Text>
                            {/* <Text
                              fontSize="xs"
                              width="100%"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {nearbyStation.formattedPlaceShort}
                            </Text> */}
                          </Text>
                        </Th>
                        <Td
                          fontSize="lg"
                          fontWeight="bold"
                          variant="bordered"
                          borderColor={theme.components.card.nearbyStations.tableCell.borderColor}
                        >
                          {nearbyStation.observation.tempFormatted || "--"}
                          {nearbyStation.observation.has("temp") && (
                            <Text
                              as="span"
                              fontWeight="normal"
                              fontSize="sm"
                              color={theme.colors.text.base.secondary}
                            >
                              {nearbyStation.observation.tempUnits}
                            </Text>
                          )}
                        </Td>

                        {/* <DesktopTd
                          variant="bordered"
                          borderColor={theme.components.card.nearbyStations.tableCell.borderColor}
                        >
                          {nearbyStation.observation.dewpointFormatted || "--"}
                          {nearbyStation.observation.has("dewpoint") && (
                            <Text
                              as="span"
                              fontWeight="normal"
                              fontSize="sm"
                              color={theme.colors.text.base.secondary}
                            >
                              {nearbyStation.observation.dewpointUnits}
                            </Text>
                          )}
                        </DesktopTd> */}
                        <Td
                          variant="bordered"
                          borderColor={theme.components.card.nearbyStations.tableCell.borderColor}
                        >
                          {formatWind(nearbyStation.observation)}
                        </Td>
                        <DesktopTd
                          variant="bordered"
                          borderColor={theme.components.card.nearbyStations.tableCell.borderColor}
                        >
                          {nearbyStation.observation.pressureByType || "--"}{" "}
                          {nearbyStation.observation.has("pressure") && (
                            <Text
                              as="span"
                              fontWeight="normal"
                              fontSize="sm"
                              color={theme.colors.text.base.secondary}
                            >
                              {nearbyStation.observation.pressureUnits}
                            </Text>
                          )}
                        </DesktopTd>
                        <DesktopTd
                          variant="bordered"
                          borderColor={theme.components.card.nearbyStations.tableCell.borderColor}
                        >
                          {nearbyStation.observation.precipFormatted || "--"}{" "}
                          {nearbyStation.observation.has("precip") && (
                            <Text
                              as="span"
                              fontWeight="normal"
                              fontSize="sm"
                              color={theme.colors.text.base.secondary}
                            >
                              {nearbyStation.observation.precipUnits}
                            </Text>
                          )}
                        </DesktopTd>
                      </motion.tr>
                    ))}
                </motion.tbody>
              </Table>
            </Box>
            <Flex justify={page === 1 ? "flex-end" : "space-between"} mb={[2, null, null, -3]}>
              {page !== 1 && (
                <Button
                  size="sm"
                  leftIcon="arrow-left"
                  variantColor={theme.components.card.nearbyStations.pagination.color}
                  variant={theme.components.card.nearbyStations.pagination.variant}
                  onClick={() => handlePaginationClick(page - 1)}
                >
                  <Box as="span" mt={[null, null, null, "2px"]}>
                    Prev
                  </Box>
                </Button>
              )}
              {page !== totalPages && (
                <Button
                  size="sm"
                  rightIcon="arrow-right"
                  variantColor={theme.components.card.nearbyStations.pagination.color}
                  variant={theme.components.card.nearbyStations.pagination.variant}
                  onClick={() => handlePaginationClick(page + 1)}
                >
                  <Box as="span" mt={[null, null, null, "2px"]}>
                    Next
                  </Box>
                </Button>
              )}
            </Flex>
          </Box>
        )}
      </PwsCardDisplay>
    </Box>
  );
};

const NearbyStationsCardWithErrorBoundary = (props: NearbyStationsCardProps) => {
  return (
    <ErrorBoundary variant="card">
      <NearbyStationsCard {...props}></NearbyStationsCard>
    </ErrorBoundary>
  );
};

export default NearbyStationsCardWithErrorBoundary;
