import { css } from "@emotion/react";
import _get from "lodash.get";
import { Box, Flex, Stack, Text } from "pws-design-system/design-system/";
import React, { useLayoutEffect, useRef, useState } from "react";
import { FixedSizeList as List } from "react-window";
import Observations from "../../../../../../../models/aeris/observation/Observations";
import Place from "../../../../../../../models/aeris/place/Place";
import ArrowIcon from "../../../../../../common/components/arrow-icon/";
import { PressureTypeContainer } from "../../../../../../common/hooks/stores/usePressureTypeStore";
import { TimeStoreContainer } from "../../../../../../common/hooks/stores/useTimeStore";
import { ThemeContainer } from "../../../../../../common/hooks/useTheme";
import QCBadge from "../../../qc-badge";
const { formatToTimeZone } = require("date-fns-timezone");
import {
  Table,
  Thead as TableHeader,
  Th as TableHeadCell,
  Tr as TableRow,
  Td as TableCell,
  DesktopTd,
  DesktopTh
} from "../../nearby-stations/Table";

type ColumnProps = {
  label: string;
  data: any;
  units?: string;
  span?: number;
  mobile?: boolean;
};

type TabularObsPanelProps = {
  collection: Observations;
  place: Place;
};

const TabularObsPanel = ({ collection, place }: TabularObsPanelProps) => {
  const { theme } = ThemeContainer.useContainer();
  const { pressureType } = PressureTypeContainer.useContainer();
  const { getHourFormatToken, getTimeFormatString, timeFormat } = TimeStoreContainer.useContainer();

  const [areTableHeadersRendered, setAreTableHeadersRendered] = useState(false);

  const headers = [
    {
      text: "Time",
      minWidth: "100px"
    },
    {
      text: "Temp"
    },
    {
      text: "Dew Pt"
    },
    {
      text: "Humidity"
    },
    {
      text: "Wind",
      colSpan: 3
    },
    {
      text: "Pressure"
    },
    {
      text: "Solar Rad"
    },
    {
      text: "UV"
    },
    {
      text: "Precipitation",
      colSpan: 2
    }
  ];

  const sortedModels = collection.sortedByDateDescending;

  const columns: ColumnProps[] = [
    {
      label: "",
      data: (index: number) => {
        return (
          <Stack align="center" isInline>
            <Text variant="caption1" fontWeight="bold">
              {formatToTimeZone(sortedModels[index].date, getTimeFormatString(), {
                timeZone: place.timezone
              })}
            </Text>
            <QCBadge
              variant="indicator"
              observation={sortedModels[index]}
              popover={{ placement: "right" }}
            />
          </Stack>
        );
      },
      mobile: true
    },
    {
      label: "",
      data: (index: number) => {
        return sortedModels[index].tempFormattedWithUnits;
      },
      mobile: true
    },
    {
      label: "",
      data: (index: number) => {
        return sortedModels[index].dewpointFormattedWithUnits;
      }
    },
    {
      label: "",
      data: (index: number) => {
        return sortedModels[index].humidityFormattedWithUnits;
      }
    },
    {
      label: "Speed",
      data: (index: number) => {
        const val = sortedModels[index].wind;
        if (val === null) {
          return "--";
        }
        if (Number(val) === 0) {
          return "Calm";
        }
        return sortedModels[index].windFormattedWithUnits;
      },
      mobile: true
    },
    {
      label: "Direction",
      data: (index: number) => {
        const windDir = sortedModels[index].get("windDirection", "");
        const windDirDeg = sortedModels[index].windDirectionDegrees;
        return (
          <>
            <Box as="span">{windDir}</Box>
            {sortedModels[index].has("windDirectionDegrees") && (
              <Box
                as="span"
                width="16px"
                height="16px"
                ml={2}
                transform={`rotate(${windDirDeg}deg)`}
              >
                <ArrowIcon size="16" theme={theme.components.table.arrowIcon} />
              </Box>
            )}
          </>
        );
      },
      mobile: true
    },
    {
      label: "Gusts",
      data: (index: number) => {
        return sortedModels[index].windGustFormattedWithUnits || "--";
      }
    },
    {
      label: pressureType.toUpperCase(),
      data: (index: number) => {
        return `${sortedModels[index].pressureByType}${sortedModels[index].pressureUnits}` || "--";
      }
    },
    {
      label: "",
      data: (index: number) => {
        const val = sortedModels[index].get("solarRadiation", "--");
        const units = (
          <>
            W/m<sup style={{ top: ".125em" }}>2</sup>
          </>
        );
        return (
          <>
            {val} {units}
          </>
        );
      }
    },
    {
      label: "",
      data: (index: number) => {
        const val = sortedModels[index].get("uvIndex", "--");
        return `${val}`;
      }
    },
    {
      label: "Total",
      data: (index: number) => {
        return sortedModels[index].precipSinceLastObFormattedWithUnits || "--";
      }
    },
    {
      label: "Accum",
      data: (index: number) => {
        return sortedModels[index].precipSinceMidnightFormattedWithUnits || "--";
      }
    }
  ];

  const headerRef = useRef<any>();
  const refs = useRef(columns.map(col => React.createRef()));

  const handleScroll = (event: any) => {
    if (event.target) {
      headerRef.current.style.right = `${event.target.scrollLeft}px`;
      // console.dir(event.target.scrollLeft);
    }
  };

  useLayoutEffect(() => {
    setAreTableHeadersRendered(true);
  }, []);

  return (
    <Box width="100%" overflow="hidden">
      <div
        ref={headerRef}
        css={css`
          width: calc(100% - 40px);
          min-width: 1268px;
          position: relative;
        `}
      >
        <Table width="100%">
          <TableHeader>
            <TableRow bg="#3aba50" color="white">
              {headers.map((header, i) => (
                <TableHeadCell
                  key={`obs-table-header-${i}`}
                  colSpan={_get(header, "colSpan", 1)}
                  minWidth={_get(header, "minWidth")}
                  textAlign={i === 0 ? "left" : "center"}
                  p="8px 4px"
                  fontSize="13px"
                >
                  {header.text}
                </TableHeadCell>
              ))}
            </TableRow>
            <TableRow>
              {columns.map((col, i) => (
                <TableHeadCell
                  key={`obs-table-subheader-${i}`}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  textAlign={i === 0 ? "left" : "center"}
                  fontSize="13px"
                >
                  <span ref={refs.current[i]}>{col.label}</span>
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHeader>
        </Table>
      </div>
      {areTableHeadersRendered === true && (
        <div onScroll={handleScroll}>
          <List height={500} width="100%" itemSize={43} itemCount={sortedModels.length}>
            {({ index, style }) => (
              <Box key={`obs-table-row-${index}`} style={style}>
                <Flex
                  height={"42px"}
                  align="center"
                  px={0}
                  borderBottomWidth="1px"
                  borderBottomStyle="solid"
                  borderBottomColor={theme.components.table.borderBottomColor}
                  fontSize="13px"
                >
                  {columns.map((col, i) => {
                    const currentRef = _get(refs, `current[${i}].current`, null);
                    return (
                      <Flex
                        key={`obs-table-row-${index}-${i}`}
                        justify={i === 0 ? "flex-start" : "center"}
                        px={i === 0 ? 2 : 0}
                        flexShrink={0}
                        width={_get(currentRef, "parentNode.offsetWidth", 100)}
                        fontWeight={i === 0 ? "bold" : null}
                      >
                        {columns[i].data(index)}
                      </Flex>
                    );
                  })}
                </Flex>
              </Box>
            )}
          </List>
        </div>
      )}
    </Box>
  );
};

export default TabularObsPanel;
