import { isBrowser } from "../../../utils/";
import React, { useState } from "react";

let prompt: any = null;

const useInstallPrompt = () => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  if (isBrowser() && !loaded && !loading) {
    setLoading(true);
    window.addEventListener("beforeinstallprompt", event => {
      prompt = event;
      setLoading(false);
      setLoaded(true);
    });
  }

  return prompt;
};

export { useInstallPrompt };
