import { useEffect, useState } from "react";
import aeris from "../../../../../../api";
import Place from "../../../../../../models/aeris/place/";
import Forecasts from "../../../../../../models/aeris/forecast/Forecasts";
import Sunmoons from "../../../../../../models/aeris/sunmoon/Sunmoons";
import { UnitStoreContainer } from "../../../../../common/hooks/stores/useUnitStore";
import useFetchStateMachine from "../../../../../common/hooks/useFetchStateMachine";
import { RefreshRates } from "../../../../../../types/enums";

import { addDays } from "date-fns";
import _get from "lodash.get";
import _every from "lodash.every";

export default function useForecastQuery({ placeId, initialPlace = new Place() }) {
  const [place, setPlace] = useState(initialPlace);
  const { units, unitType } = UnitStoreContainer.useContainer();
  const [current, send] = useFetchStateMachine();

  const api = aeris.api();
  const placeApi = aeris.api();
  const sunMoonApi = aeris.api();
  useEffect(() => {
    async function fetchData() {
      send("LOADING");
      const placeRequest = placeApi
        .endpoint("places")
        .place(placeId)
        .limit(1);

      const forecastsRequest = api
        .endpoint("forecasts")
        .place(placeId)
        .format("json")
        .filter("daynight,precise")
        .limit(3);

      const sunMoonRequest = sunMoonApi
        .endpoint("sunmoon")
        .place(placeId)
        .from(new Date().toISOString())
        .to(addDays(new Date(), 1).toISOString())
        .fields("sun,moon.phase")
        .format("json");

      const [placeResponse, forecastsResponse, sunMoonResponse] = await Promise.all([
        placeRequest.get(),
        forecastsRequest.get(),
        sunMoonRequest.get()
      ]);
      const successes = [placeResponse, forecastsResponse, sunMoonResponse].map(response =>
        _get(response, "response.data.success", false)
      );
      const success = _every(successes, success => success === true);
      if (success === true) {
        if (forecastsResponse.data.length === 0) {
          send("NO_DATA");
          return;
        }
        send("LOADED");
        const place = new Place({
          placeId,
          data: placeResponse.data
        });
        place.sunmoons = new Sunmoons({
          data: sunMoonResponse.data
        });
        place.forecasts = new Forecasts({
          data: _get(forecastsResponse, "data[0]", null)
        });
        setPlace(place);
      } else {
        send("ERROR");
      }
    }
    fetchData();
    const heartbeatHandle = window.setInterval(fetchData, RefreshRates.ForecastCard * 60 * 1000);
    return () => window.clearInterval(heartbeatHandle);
  }, [placeId]);

  return [place, current];
}
