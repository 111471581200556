import React from "react";
import { Box, Icon, Stack, Stat, Text, Flex, Hide } from "pws-design-system/design-system";
import Summary from "../../../../../../models/aeris/summary/Summary";
import { unitForProp, formatValue } from "../../../../../../models/aeris/units";
import _isNil from "lodash.isnil";
import { unitStore } from "../../../../../common/hooks/stores/useUnitStore";
import { PressureTypeContainer } from "../../../../../common/hooks/stores/usePressureTypeStore";

interface TableViewProps {
  summary: Summary;
}

interface TabularStatProps {
  label: string;
  measurement: string | number;
  units?: string;
}

interface TabularStatContainerProps {
  label: string;
  iconName: string;
}

function TabularStat({ measurement, ...rest }: TabularStatProps) {
  return (
    <Stat size="md" value={_isNil(measurement) === false ? `${measurement}` : "--"} {...rest} />
  );
}

const TabularStatContainer: React.FC<TabularStatContainerProps> = ({
  label,
  iconName,
  ...rest
}): React.ReactElement => {
  return (
    <Box width={["100%", null, 1 / 2, 1 / 3, 1 / 4]} mb={4}>
      <Flex align="center">
        <Box width={"32px"} mr={2}>
          <Icon name={iconName} size="30px" />
        </Box>
        <Text variant="label" fontSize="sm">
          {label}
        </Text>
      </Flex>
      <Stack ml={"40px"} mt={0} spacing={3} isInline>
        {rest.children}
      </Stack>
    </Box>
  );
};

export default function TableView({ summary }: TableViewProps) {
  const { pressureType } = PressureTypeContainer.useContainer();
  const isMetric = summary.isMetric;
  return (
    <Flex wrap="wrap" mb={-4}>
      <TabularStatContainer label="Temperature" iconName="temperature">
        <TabularStat
          measurement={summary.maxTempFormatted}
          label="Max"
          units={unitForProp("temp", unitStore.temp === "metric")}
        />
        <TabularStat
          measurement={summary.minTempFormatted}
          label="Min"
          units={unitForProp("temp", unitStore.temp === "metric")}
        />
        <TabularStat
          measurement={summary.avgTempFormatted}
          label="Avg"
          units={unitForProp("temp", unitStore.temp === "metric")}
        />
      </TabularStatContainer>
      <TabularStatContainer label="Dew Point" iconName="dewpoint">
        <TabularStat
          measurement={summary.dewptMaxFormatted}
          label="Max"
          units={unitForProp("temp", unitStore.temp === "metric")}
        />
        <TabularStat
          measurement={summary.dewptMinFormatted}
          label="Min"
          units={unitForProp("temp", unitStore.temp === "metric")}
        />
        <TabularStat
          measurement={summary.dewptAvgFormatted}
          label="Avg"
          units={unitForProp("temp", unitStore.temp === "metric")}
        />
      </TabularStatContainer>
      <TabularStatContainer label="Humidity" iconName="humidity">
        <TabularStat measurement={formatValue(summary.rhMax, "percent")} label="Max" units="%" />
        <TabularStat measurement={formatValue(summary.rhMin, "percent")} label="Min" units="%" />
        <TabularStat measurement={formatValue(summary.rhAvg, "percent")} label="Avg" units="%" />
      </TabularStatContainer>
      <TabularStatContainer label="Winds" iconName="wind">
        <TabularStat
          measurement={summary.maxWindFormatted}
          label="Max"
          units={unitForProp("wind", unitStore.speed === "metric")}
        />
        <TabularStat
          measurement={summary.minWindFormatted}
          label="Min"
          units={unitForProp("wind", unitStore.speed === "metric")}
        />
        <TabularStat
          measurement={summary.avgWindFormatted}
          label="Avg"
          units={unitForProp("wind", unitStore.speed === "metric")}
        />
      </TabularStatContainer>
      <TabularStatContainer label="Wind Gusts" iconName="windGust">
        <TabularStat
          measurement={summary.windGustFormatted}
          label="Max"
          units={unitForProp("wind", unitStore.speed === "metric")}
        />
      </TabularStatContainer>
      <TabularStatContainer label="Pressure" iconName="pressure">
        <TabularStat
          measurement={summary.maxPressureByType}
          label="Max"
          units={unitForProp("pressure", unitStore.pressure === "metric")}
        />
        <TabularStat
          measurement={summary.minPressureByType}
          label="Min"
          units={unitForProp("pressure", unitStore.pressure === "metric")}
        />
        <TabularStat
          measurement={summary.avgPressureByType}
          label="Avg"
          units={unitForProp("pressure", unitStore.pressure === "metric")}
        />
      </TabularStatContainer>
      <TabularStatContainer label="Precipitation" iconName="precip">
        <TabularStat
          measurement={summary.precipTotalFormatted}
          label="Total"
          units={unitForProp("precip", unitStore.rain === "metric")}
        />
      </TabularStatContainer>
    </Flex>
  );
}
