import _capitalize from "lodash.capitalize";
import _noop from "lodash.noop";
import {
  Absolute,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Hide,
  Spinner
} from "pws-design-system/design-system";
import React, { useState } from "react";
import Summary from "../../../../../../models/aeris/summary/Summary";
import { Z, CardPeriod, CardView } from "../../../../../../types/enums";
import DelayedRenderer from "../../../../../common/components/delayed-renderer";
import ResponsiveRender from "../../../../../common/components/responsive-render";
import { SegmentedControl } from "../../../../../common/components/segmented-control";
import State from "../../../../../common/components/state/";
import { UseDateContainer } from "../../../../../common/hooks/stores/useDateStore";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";
import Datepicker from "../../datepicker";
import GraphView from "./GraphView";
import TableView from "./TableView";
import useDailySummaryQuery from "./useDailySummaryQuery";

type MeasurementSystem = "imperial" | "metric";

interface TabularStatProps {
  summary: Summary;
  measurement: string; // TODO: enum?>
  unit?: string;
  rest: any;
}

interface DailySummaryCardProps {
  placeId: string;
  measurementSystem?: MeasurementSystem;
  initialDate: Date;
  initialSummary?: Summary;
  initialOffset?: "daily" | "monthly" | "annual";
  initialPeriod?: CardPeriod;
  initialView: CardView;
  onDateChange?: (value: Date) => void;
  onPeriodChange?: (value: CardPeriod) => void;
}

export default function DailySummaryCard({
  placeId,
  initialPeriod = CardPeriod.Daily,
  initialDate = new Date(),
  initialView = CardView.Graph,
  onDateChange = _noop,
  onPeriodChange = _noop
}: DailySummaryCardProps) {
  const { theme } = ThemeContainer.useContainer();
  const [date, setDate] = useState(initialDate);
  const [period, setPeriod] = useState(initialPeriod);
  const [view, setView] = useState(initialView);
  const [summary, state] = useDailySummaryQuery({ placeId, date, period });
  const { dateStore } = UseDateContainer.useContainer();
  function handleDateChange(val) {
    if (onDateChange !== _noop) {
      onDateChange(val);
    }
    setDate(val);
  }

  function handlePeriodChange(val) {
    if (val === period) {
      return;
    }
    setPeriod(val);
    onPeriodChange(val);
  }

  function handleViewChange(val) {
    setView(val);
  }

  return (
    <Box
      bg={theme.components.card.bg}
      color={theme.components.card.color}
      rounded={["lg", null, null, "xl"]}
      p={[3, null, null, 4]}
      boxShadow="sm"
      mb={3}
      position="relative"
    >
      <Flex mb={4} align="center" justify="space-between" width="100%">
        <Flex align="center" width="100%" flexWrap="wrap">
          <Heading
            as="h2"
            variant="subheadline"
            mr={4}
            minWidth={[null, "250px"]}
            mb={[3, null, 0]}
          >
            {_capitalize(period)} Summary
          </Heading>
          <State state={state} matches={["initialLoadComplete", "noData", "error"]}>
            <Hide tablet desktop width="100%" zIndex={Z.mobileDatepicker}>
              {dateStore.timezone && (
                <Datepicker
                  variant="fullWidth"
                  initialDate={date}
                  context={period}
                  timezone={dateStore.timezone}
                  changeHandler={handleDateChange}
                />
              )}
            </Hide>
            <Hide mobile>
              {dateStore.timezone && (
                <Datepicker
                  initialDate={date}
                  context={period}
                  changeHandler={handleDateChange}
                  timezone={dateStore.timezone}
                />
              )}
            </Hide>
            <SegmentedControl
              ml={[0, null, 3]}
              mt={[3, null, 0]}
              size="xs"
              width={["100%", null, "auto"]}
              defaultValue={period}
              onChange={handlePeriodChange}
              display={["flex", null, "block"]}
              {...theme.components.historical.toggle}
            >
              <Button value="daily" flexGrow={1}>
                Daily
              </Button>
              <Button value="monthly" flexGrow={1}>
                Monthly
              </Button>
            </SegmentedControl>
            <ResponsiveRender size={["tablet", "desktop"]}>
              <SegmentedControl ml="auto" defaultValue="graphs" onChange={handleViewChange}>
                <Button value="graphs">Graphs</Button>
                <Button value="tabular">Tabular</Button>
              </SegmentedControl>
            </ResponsiveRender>
          </State>
        </Flex>
      </Flex>
      <DailySummaryCardDisplay
        summary={summary}
        view={view}
        period={period}
        date={date}
        state={state}
        dateChangeHandler={handleDateChange}
        periodChangeHandler={handlePeriodChange}
        viewChangeHandler={handleViewChange}
      />
    </Box>
  );
}

export function DailySummaryCardDisplay({ summary, view, state }) {
  return (
    <Box minHeight="200px" position="relative">
      <State state={state} matches={["initialLoadComplete.loading"]}>
        <DelayedRenderer delay={1000}>
          <Absolute
            top="80px"
            left="0"
            right="0"
            bottom="20px"
            background="hsla(0, 0%, 100%, .8)"
            zIndex={3}
          >
            <DelayedRenderer delay={1000}>
              <Absolute top="120px" left="0" right="0" bottom="0">
                <Flex height="100%" justify="center">
                  <Spinner></Spinner>
                </Flex>
              </Absolute>
            </DelayedRenderer>
          </Absolute>
        </DelayedRenderer>
      </State>
      <State state={state} matches={["noData"]}>
        <Absolute top="20px" left="0" right="0" bottom="0">
          <Flex width="100%" height="100%" align="center" justify="center">
            No summary for the date selected.
          </Flex>
        </Absolute>
      </State>
      <State state={state} matches={["error"]}>
        <Absolute
          top="20px"
          left="0"
          right="0"
          bottom="20px"
          zIndex={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          There was an error loading summary data.
        </Absolute>
      </State>
      <State state={state} matches={["initialLoadComplete.loading"]}>
        <DelayedRenderer delay={1000}>
          <Absolute
            top="80px"
            left="0"
            right="0"
            bottom="20px"
            background="brand.white.base"
            opacity={0.8}
            zIndex={3}
          ></Absolute>
        </DelayedRenderer>
      </State>
      <State state={state} matches={["initialLoadComplete"]}>
        {summary.hasData === true && view === "graphs" && (
          <GraphView summary={summary} state={state} />
        )}
        {summary.hasData === true && view === "tabular" && (
          <TableView summary={summary} state={state} />
        )}
      </State>
    </Box>
  );
}
