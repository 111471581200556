import React from "react";
import { Box, Card, Flex, Heading, Button } from "pws-design-system/design-system";
import { CardPeriod, CardView, DeviceFormFactor } from "../../../../../../types/enums";
import { ReactChildren } from "../../../../../../types";
import { SegmentedControl } from "../../../../../common/components/segmented-control/";
import Datepicker from "../../datepicker";
import ResponsiveRender from "../../../../../common/components/responsive-render";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";
import { UseDateContainer } from "../../../../../common/hooks/stores/useDateStore";
import { isToday, isTomorrow } from "date-fns";
const { convertToTimeZone, formatToTimeZone } = require("date-fns-timezone");

interface HistoricalCardWrapperProps {
  heading: string;
  period: CardPeriod;
  date: Date;
  dateChangeHandler: (val: Date) => void;
  periodChangeHandler: (val: CardPeriod) => void;
  viewChangeHandler: (val: CardView) => void;
  children: ReactChildren;
}

const HistoricalCardWrapper = ({
  heading,
  date,
  timezone,
  summaryView,
  period,
  dateChangeHandler,
  periodChangeHandler,
  viewChangeHandler,
  children
}: HistoricalCardWrapperProps) => {
  const { theme } = ThemeContainer.useContainer();
  const { setDate, setPeriod } = UseDateContainer.useContainer();
  const convertedDate = convertToTimeZone(date, {
    timeZone: timezone
  });

  const modifyDate = (date: Date, days: number) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + days);
    return currentDate.toISOString().split("T")[0];
  };

  const dayBefore = () => {
    const yesterday = modifyDate(date, -1);
    setDate(yesterday);
  };

  const dayAfter = () => {
    const nextDay = modifyDate(date, 1);
    setDate(nextDay);
  };

  return (
    <Box
      bg={theme.components.dashboard.card.bg}
      color={theme.components.card.color}
      rounded={["lg", null, null, "xl"]}
      p={[3, null, null, 4]}
      boxShadow="sm"
      mb={3}
      position="relative"
    >
      <Flex mb={4} align="center" justify="space-between" width="100%">
        <Flex align="center" width="100%" flexWrap="wrap">
          <Heading
            as="h2"
            variant="subheadline"
            mr={4}
            minWidth={[null, "250px"]}
            mb={[3, null, 0]}
          >
            {heading}
          </Heading>
          <Flex>
            <Button
              size="sm"
              leftIcon="arrow-left"
              roundedRight="0"
              pr="0"
              onClick={e => {
                e.stopPropagation();
                dayBefore();
              }}
            ></Button>
            <Datepicker
              initialDate={date}
              timezone={timezone}
              context={period}
              changeHandler={dateChangeHandler}
            />
            <Button
              size="sm"
              rightIcon="arrow-right"
              roundedLeft="0"
              pl="0"
              onClick={e => {
                e.stopPropagation();
                dayAfter();
              }}
              isDisabled={isToday(convertedDate)}
            ></Button>
          </Flex>
          <SegmentedControl
            ml={[0, null, 3]}
            mt={[3, null, 0]}
            size="xs"
            width={["100%", null, "auto"]}
            defaultValue={period}
            onChange={periodChangeHandler}
            display={["flex", null, "block"]}
            {...theme.components.card.historical.unitToggle}
          >
            <Button value={CardPeriod.Daily} flexGrow={1}>
              {CardPeriod.Daily}
            </Button>
            <Button value={CardPeriod.Monthly} flexGrow={1}>
              {CardPeriod.Monthly}
            </Button>
          </SegmentedControl>
          <ResponsiveRender size={[DeviceFormFactor.Tablet, DeviceFormFactor.Desktop]}>
            <SegmentedControl
              ml="auto"
              defaultValue={summaryView}
              onChange={viewChangeHandler}
              {...theme.components.card.historical.unitToggle}
            >
              <Button value={CardView.Graph}>{CardView.Graph}</Button>
              <Button value={CardView.Tabular}>{CardView.Tabular}</Button>
            </SegmentedControl>
          </ResponsiveRender>
        </Flex>
      </Flex>
      {children}
    </Box>
  );
};

export default HistoricalCardWrapper;
