import Observations from "../../../../../../models/aeris/observation/Observations";
import Place from "../../../../../../models/aeris/place";
import { subMinutes } from "date-fns";
import _get from "lodash.get";

export default class ChartObservations extends Observations {
  place: Place = null;
  getChartData(prop, models = this.models) {
    if (this.hasData === false) {
      return null;
    }
    const clamp = 10 * 60 * 1000; // defines the minimum time interval between data points to include
    const reducedModels: any[] = [];
    let lastDate: Date;
    models.forEach((model: any) => {
      const d = model.date;
      if (d && (!lastDate || d.getTime() - lastDate.getTime() >= clamp)) {
        reducedModels.push(model);
        lastDate = d;
      }
    });

    const currentTimeZoneOffset = new Date().getTimezoneOffset();
    const offset = this.place.timeZoneOffsetInMinutes - currentTimeZoneOffset;
    return reducedModels
      .map(model => {
        let data = _get(model, prop);
        if (data === null) {
          return null;
        }
        return {
          x: subMinutes(model.date, offset),
          y: data
        };
      })
      .filter((item: any) => {
        return item !== null && item.y !== null;
      });
  }
  get temps() {
    return this.getChartData("temp");
  }
  get feelsLike() {
    return this.getChartData("feelsLike");
  }
  get dewpoints() {
    return this.getChartData("dewpoint");
  }
  get wind() {
    return this.getChartData("wind");
  }
  get windGust() {
    return this.getChartData("windGust");
  }
  get windDirection() {
    return this.getChartData("windDirectionDegrees");
  }
  get pressure() {
    return this.getChartData("pressureByType");
  }
  get precip() {
    return this.getChartData("precipSinceLastOb");
  }
  get humidity() {
    return this.getChartData("humidity");
  }
  get precipProb() {
    return this.getChartData("precipProb");
  }
  get skyCover() {
    return this.getChartData("skyCover");
  }
  get accumulatedPrecip() {
    return this.getChartData("precipSinceMidnight");
  }

  get solarRadiation() {
    return this.getChartData("solarRadiation");
  }
  get uvIndex() {
    return this.getChartData("uvIndex");
  }
}
