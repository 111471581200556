import React from "react";
import ChartObservations from "./chart-model/ChartObservations";
import DashboardChart from "./DashboardChart";
import { isMetricStore } from "../../../../common/hooks/useIsMetric";
import { CardPeriod } from "../../../../../types/enums";
import { unitForProp } from "../../../../../models/aeris/units";
import _merge from "lodash.merge";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { UnitCategory } from "../../../../../types/enums";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

interface PrecipChartProps {
  collection: ChartObservations;
  date: Date;
  period: CardPeriod;
  config: any;
}

export default function PrecipChart({ collection, date, period, config = {} }: PrecipChartProps) {
  const { unitStore } = UnitStoreContainer.useContainer();
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;

  function labelConfigCallback(value: string) {
    return `${value} ${unitForProp("precip", unitStore[UnitCategory.Rain] === "metric")}`;
  }

  const datasetConfigs = [
    {
      type: "bar",
      data: collection.precip,
      label: "Precip",
      key: "precip",
      borderColor: dataColors.precip,
      backgroundColor: dataColors.precip,
      pointBackgroundColor: dataColors.precip,
      maxBarThickness: 8
    },
    {
      data: collection.accumulatedPrecip,
      label: "Accumulated Precip",
      key: "accumulatedPrecip",
      borderColor: dataColors.precipaccum,
      pointBackgroundColor: dataColors.precipaccum
    }
  ];

  return (
    <DashboardChart
      period={period}
      date={date}
      chartConfig={config}
      datasetConfigs={datasetConfigs}
      labelConfigCallback={labelConfigCallback}
      collection={collection}
      startsAtZero={true}
    />
  );
}
