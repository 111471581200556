import Model from "../../Model";
import { format } from "date-fns";

export default class Sunmoon extends Model {
  public get riseIso() {
    return this.getData("sun.riseISO");
  }
  public get riseDate() {
    return this.has("riseIso") ? new Date(this.riseIso) : null;
  }
  private get setIso() {
    return this.getData("sun.setISO");
  }
  public get setDate() {
    return this.has("setIso") ? new Date(this.setIso) : null;
  }
  public get moonPhase() {
    return this.getData("moon.phase.name");
  }
}
