import Model from "../../Model";
import { format } from "date-fns";
import { capitalize, isArray, startCase, toLower, upperCase } from "lodash";

const expiration = (timestamps: any, tz: string): string => {
  const { begins, expires } = timestamps;
  const now = new Date().getTime();

  const beginsDate = new Date(begins * 1000);
  const expiresDate = new Date(expires * 1000);
  const dateFormat = "EEE, MMM d 'at' h:mm aaaa";

  return now < begins * 1000
    ? `beginning ${format(beginsDate, dateFormat)}, expires ${format(expiresDate, dateFormat)}`
    : `until ${format(expiresDate, dateFormat)}`;
};

const formatAdvisory = (data: any): any => {
  // clean up advisory body text
  let body = data || "";
  body = body.replace(/(?:\r\n|\r|\n){2,}/g, "__");
  body = body.replace(/\n/g, " ");
  body = body.replace(/\s{3,}/g, ", ");
  body = body.replace(/\s{2,}/g, " ");
  body = body.replace(/__/g, "<br><br>");

  return body;
};

export default class Alert extends Model {
  public get type(): string | null {
    return this.getData("details.type");
  }
  public get name(): string | null {
    return startCase(toLower(this.getData("details.name")));
  }
  public get body(): string | null {
    return formatAdvisory(this.getData("details.body"));
  }
  public get color(): string | null {
    return this.getData("details.color");
  }
  public get expires(): string | null {
    return expiration(this.getData("timestamps"), this.getData("profile.tz"));
  }
}
