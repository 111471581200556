import React, { useState } from "react";
import { addDays, format, startOfDay } from "date-fns";
import {
  Absolute,
  Box,
  Button,
  Card as PwsCard,
  Col,
  Flex,
  Heading,
  Spinner
} from "pws-design-system/design-system";
import Forecasts from "../../../../models/aeris/forecast/Forecasts";
import Place from "../../../../models/aeris/place/";
import PlaceMeta from "../../../../models/aeris/place/PlaceMeta";
import Path from "../../../../models/path/Path";
import { CardView, LocalStorageKeys } from "../../../../types/enums";
import DelayedRender from "../../../common/components/delayed-renderer/DelayedRender";
import ResponsiveRenderer from "../../../common/components/responsive-render";
import { SegmentedControl as StyledSegmentedControl } from "../../../common/components/segmented-control";
import State from "../../../common/components/state/";
import useLocalStorage from "../../../common/hooks/useLocalStorage";
import * as Charts from "../common/charts";
import PlaceLayout from "../common/Layout";
import Datepicker from "../dashboard/datepicker/";
import DailyListPanel from "./components/DailyListPanel";
import ForecastPeriod from "./components/ForecastPeriod";
import GraphPanel from "./components/GraphPanel";
import TabularPanel from "./components/TabularPanel";
import useChartForecastQuery from "./useChartForecastQuery";
import useForecastQuery from "./useForecastQuery";
import _has from "lodash.has";
import _keys from "lodash.keys";
import _pickBy from "lodash.pickby";
import _get from "lodash.get";
import { navigate } from "gatsby";
import { ThemeContainer } from "../../../common/hooks/useTheme";
import { UseDateContainer } from "../../../common/hooks/stores/useDateStore";

interface MobileCardProps {
  forecasts: Forecasts;
  charts: [];
  forecastDate: Date;
}

const getChartsConfig = (theme: any): any[] => {
  const dataColors = theme.components.charts.data;
  return [
    {
      chart: Charts.Temperature,
      label: [
        { text: "Temperature", color: dataColors.temperature },
        { text: "Feels Like", color: dataColors.feelslike },
        { text: "Dew Point", color: dataColors.dewpoint }
      ]
    },
    {
      chart: Charts.Wind,
      label: [
        { text: "Wind", color: dataColors.windspeed },
        { text: "Wind Gust", color: dataColors.windgust }
      ]
    },
    {
      chart: Charts.Percentage,
      label: [
        { text: "Humidity", color: dataColors.humidity },
        { text: "Precipitation Probability", color: dataColors.pop },
        { text: "Sky Cover", color: dataColors.skycover }
      ]
    },
    {
      chart: Charts.Precip,
      label: [
        { text: "Precipitation", color: dataColors.precip },
        { text: "Accumulation", color: dataColors.precipaccum }
      ]
    },
    {
      chart: Charts.Snow,
      label: [
        { text: "Snow", color: dataColors.snow },
        { text: "Accumulation", color: dataColors.snowaccum }
      ]
    },
    {
      chart: Charts.Pressure,
      label: [{ text: "Pressure", color: dataColors.pressure }]
    }
  ];
};

const Card = props => {
  const { theme } = ThemeContainer.useContainer();
  return (
    <Box
      bg={theme.components.dashboard.card.bg}
      rounded={["lg", null, null, "xl"]}
      p={[3, null, null, 4]}
      boxShadow="sm"
      mb={3}
      position="relative"
      color={theme.colors.text.base.primary}
      {...props}
    >
      {props.children}
    </Box>
  );
};

function MobileCard({
  forecasts,
  place,
  charts,
  chartForecasts,
  forecastDate,
  dateChangeHandler,
  date,
  path
}: MobileCardProps) {
  const { theme } = ThemeContainer.useContainer();
  const [display, setDisplay] = useState("period");
  const [view, setView] = useLocalStorage(LocalStorageKeys.MobileForecastCardView, CardView.Graph);
  const { dateStore } = UseDateContainer.useContainer();
  const handleViewChange = (value: CardView) => {
    setView(value);
  };

  const handleClick = (date: Date) => {
    window.scrollTo(0, 0);
    window.location.hash = "charts";
    setDisplay("detail");
    dateChangeHandler(date);
  };

  const handleBack = (path: string) => {
    setDisplay("period");
    navigate(path);
  };
  if (forecasts.hasData === true) {
    if (display === "period") {
      return (
        <Card boxShadow="sm" py={0} mb={3}>
          <DailyListPanel forecasts={forecasts} onSelect={handleClick} />
        </Card>
      );
    }
    if (display === "detail") {
      return (
        <Card boxShadow="sm" mb={3}>
          <Flex mb={2}>
            <Button
              size="xs"
              leftIcon="arrow-left"
              onClick={e => {
                e.stopPropagation();
                handleBack(path.urls["forecast"]);
              }}
            >
              <Box>Prev</Box>
            </Button>
          </Flex>
          <Box mb={4}>
            <Heading as="h2" variant="subheadline" mb={2}>
              Forecast
            </Heading>
            {dateStore.timezone && forecastDate && (
              <Datepicker
                initialDate={format(forecastDate, "yyyy-MM-dd")}
                changeHandler={dateChangeHandler}
                minDate={new Date()}
                maxDate={addDays(new Date(), 6)}
                timezone={dateStore.timezone}
                width="100%"
                variant="fullWidth"
                py={2}
              />
            )}

            <StyledSegmentedControl
              ml={[0, null, 3]}
              mt={[3, null, 0]}
              size="xs"
              width={["100%", null, "auto"]}
              defaultValue={view}
              onChange={handleViewChange}
              display={["flex", null, "block"]}
              {...theme.components.forecast.viewToggle}
            >
              <Button value={CardView.Graph} flexGrow={1}>
                Graph
              </Button>
              <Button value={CardView.Tabular} flexGrow={1}>
                Tabular
              </Button>
            </StyledSegmentedControl>
          </Box>
          {view === CardView.Graph && (
            <GraphPanel
              chartForecasts={chartForecasts}
              charts={charts}
              forecastDate={forecastDate}
            />
          )}
          {view === CardView.Tabular && <TabularPanel forecasts={chartForecasts} place={place} />}
        </Card>
      );
    }
  }

  return "";
}
interface SiteForecastProps {
  place: Place;
  path: Path;
}

export default function Forecast({ place, path }: SiteForecastProps): React.ReactElement {
  const { theme } = ThemeContainer.useContainer();
  const placeMeta = new PlaceMeta(place, path);
  const chartsConfig = getChartsConfig(theme);
  const pressureConfig = chartsConfig.pop();
  chartsConfig.push({
    ...pressureConfig,
    label: [
      {
        text: "Pressure (Station)",
        color: pressureConfig.label[0].color
      }
    ]
  });
  const [forecastDate, setForecastDate] = useState(startOfDay(new Date()));
  const [selectedForecastIndex, setSelectedForecastIndex] = useState(0);
  const [view, setView] = useLocalStorage("pws-forecast-card-view", CardView.Graph);
  const [dailyForecasts, dailyForecastsState] = useForecastQuery({
    place: place,
    period: "day",
    limit: 7
  });

  const [chartForecasts, chartForecastState] = useChartForecastQuery({
    place: place,
    from: forecastDate,
    to: addDays(forecastDate, 1),
    period: "1hr",
    limit: 25
  });

  function handleClick(date: Date) {
    const index = Number(_keys(_pickBy(dailyForecasts.models, { date }))[0]);
    if (index === selectedForecastIndex) {
      return;
    }
    setSelectedForecastIndex(index);
    setForecastDate(startOfDay(date));
  }

  function handleDateChange(date) {
    setForecastDate(startOfDay(date));
  }

  const handleViewChange = value => {
    setView(value);
  };

  return (
    <PlaceLayout
      place={place}
      path={path}
      metaTitle={placeMeta.buildMeta("forecast", "title")}
      metaDescription={placeMeta.buildMeta("forecast", "description")}
    >
      <ResponsiveRenderer size="mobile">
        <Col>
          <MobileCard
            forecasts={dailyForecasts}
            charts={chartsConfig}
            place={place}
            chartForecasts={chartForecasts}
            forecastDate={forecastDate}
            dateChangeHandler={handleDateChange}
            date={forecastDate}
            path={path}
          />
        </Col>
      </ResponsiveRenderer>
      <ResponsiveRenderer size={["tablet", "desktop"]}>
        <Col md={12}>
          <Card boxShadow="sm" mb={3} md={12} position="relative">
            <Flex justify="space-between" align="center" width="100%">
              <Heading as="h2" variant="subheadline" mr={4}>
                Forecast
              </Heading>
              <StyledSegmentedControl
                defaultValue={view}
                onChange={handleViewChange}
                {...theme.components.forecast.viewToggle}
              >
                <Button value={CardView.Graph}>{CardView.Graph}</Button>
                <Button value={CardView.Tabular}>{CardView.Tabular}</Button>
              </StyledSegmentedControl>
            </Flex>
            <Box overflowX="auto">
              <Flex
                my={4}
                borderBottomColor="brand.green.base"
                borderBottomWidth="3px"
                borderBottomStyle="solid"
                isInline
              >
                <Flex margin="auto" minHeight="154px">
                  <State state={dailyForecastsState} matches={["initialLoadComplete"]}>
                    {dailyForecasts.hasData === true &&
                      dailyForecasts.models.map((forecast, i) => (
                        <ForecastPeriod
                          key={forecast.date.getTime()}
                          forecast={forecast}
                          divider={i !== dailyForecasts.models.length - 1}
                          clickHandler={handleClick}
                          selected={i === selectedForecastIndex}
                        />
                      ))}
                  </State>
                </Flex>
              </Flex>
            </Box>
            <State state={chartForecastState} matches={["initialLoadComplete.loading"]}>
              <DelayedRender delay={1000}>
                <Absolute
                  top="250px"
                  left="0"
                  right="0"
                  bottom="0"
                  background="hsla(0, 0%, 100%, .8)"
                >
                  <DelayedRender delay={1000}>
                    <Flex position="relative" top="50px" width="100%" justify="center">
                      <Spinner></Spinner>
                    </Flex>
                  </DelayedRender>
                </Absolute>
              </DelayedRender>
            </State>
            <State state={chartForecastState} matches={["initialLoadComplete"]}>
              {view === CardView.Graph && (
                <GraphPanel
                  chartForecasts={chartForecasts}
                  charts={chartsConfig}
                  forecastDate={forecastDate}
                />
              )}
              {view === CardView.Tabular && chartForecasts.hasData === true && (
                <Box mt={3}>
                  <TabularPanel forecasts={chartForecasts} place={place} />
                </Box>
              )}
            </State>
          </Card>
        </Col>
      </ResponsiveRenderer>
    </PlaceLayout>
  );
}
