import Summary from "./Summary";
import Collection from "../../Collection";
import _get from "lodash.get";

export default class Summaries extends Collection {
  constructor(config: any) {
    super(config);

    const data = _get(config, "data", null);
    if (data !== null) {
      this.models = data.map((datum: any) => new Summary({ data: datum.summary }));
    }
  }
}
