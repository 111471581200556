import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Text, Hide, defaultTheme, Flex, LinearMeter } from "pws-design-system/design-system";
import { colorForValue, colorsInRange } from "pws-design-system/design-system/utils/colors";
import Summary from "../../../../../../models/aeris/summary/Summary";
import { roundToRange, roundToDecimalRange, clampRange } from "../../../../../../utils";
import { formatValue } from "../../../../../../models/aeris/units";
import { matchesMediaQuery } from "../../../../../common/components/responsive-render/ResponsiveRender";
import { unitStore } from "../../../../../common/hooks/stores/useUnitStore";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";
import { PressureTypeContainer } from "../../../../../common/hooks/stores/usePressureTypeStore";
import _isNil from "lodash.isnil";

type MeterOpts = { summary: Summary; orientation: "horizontal" | "vertical" };

function TemperatureLinearMeter({ summary, orientation, ...rest }: MeterOpts) {
  const { theme } = ThemeContainer.useContainer();
  const minVal = summary.get("minTempFormatted");
  const maxVal = summary.get("maxTempFormatted");

  let value = minVal;
  if (minVal !== maxVal) {
    value = {
      min: minVal,
      max: maxVal
    };
  }

  let maxLabel = "Normal";
  let minLabel = "Normal";
  if (summary.period === "monthly") {
    maxLabel = "Avg Max";
    minLabel = "Avg Min";
  }

  return (
    <LinearMeter
      orientation={orientation}
      label="Temperatures"
      units={`${summary.tempUnits}`}
      value={value}
      range={roundToRange(
        summary.get("minTemp"),
        summary.get("maxTemp"),
        summary.normal.get("minTemp"),
        summary.normal.get("maxTemp"),
        5
      )}
      gradient={colorsInRange(
        defaultTheme.colors.weather.temps,
        summary.get("minTempF"),
        summary.get("maxTempF")
      ).map(result => result.color)}
      animation={{ delay: 0 }}
      disabled={minVal === "--" && maxVal === "--"}
      bgColor={theme.components.meter.bg}
      accessoryItems={
        summary.normal.hasAll(["minTemp", "maxTemp"]) && [
          {
            value: summary.normal.minTemp,
            content: (
              <Box textAlign="right">
                <Text variant="value" color="text.dark.tertiary">
                  {summary.normal.minTempFormatted}
                </Text>
                <Text mt={-1} variant="label" fontSize="2xs" color="text.dark.tertiary">
                  {minLabel}
                </Text>
              </Box>
            )
          },
          {
            value: summary.normal.maxTemp,
            content: (
              <Box textAlign="right">
                <Text variant="value" color="text.dark.tertiary">
                  {summary.normal.maxTempFormatted}
                </Text>
                <Text mt={-1} variant="label" fontSize="2xs" color="text.dark.tertiary">
                  {maxLabel}
                </Text>
              </Box>
            )
          }
        ]
      }
      {...rest}
    />
  );
}

function DewpointLinearMeter({ summary, orientation, ...rest }: MeterOpts) {
  const { theme } = ThemeContainer.useContainer();
  const minVal = summary.get("dewptMinFormatted", 0);
  const maxVal = summary.get("dewptMaxFormatted", 0);
  let value = minVal;
  if (minVal !== maxVal) {
    value = {
      min: minVal,
      max: maxVal
    };
  }
  return (
    <LinearMeter
      orientation={orientation}
      label="Dew Point"
      units={`${summary.tempUnits}`}
      value={value}
      range={roundToRange(summary.get("dewptMin", 0), summary.get("dewptMax", 80), null, null, 5)}
      gradient={colorsInRange(
        defaultTheme.colors.weather.dewpt,
        summary.get("dewptMinF", 0),
        summary.get("dewptMaxF", 80)
      ).map(result => result.color)}
      animation={{ delay: 0.15 }}
      disabled={minVal === "--" && maxVal === "--"}
      bgColor={theme.components.meter.bg}
      {...rest}
    />
  );
}

function WindLinearMeter({ summary, orientation, ...rest }: MeterOpts) {
  const { theme } = ThemeContainer.useContainer();
  const val = summary.get("maxWindFormatted");
  return (
    <LinearMeter
      orientation={orientation}
      label="Wind"
      units={summary.windUnits}
      value={summary.get("maxWindFormatted", 0)}
      range={clampRange(
        roundToRange(
          0,
          summary.get("maxWind", 0),
          null,
          null,
          unitStore.speed === "metric" ? 48 : 30
        ),
        0,
        unitStore.speed === "metric" ? 242 : 150
      )}
      color={colorForValue(defaultTheme.colors.weather.winds, 66.6)}
      gradient={colorsInRange(
        defaultTheme.colors.weather.winds,
        0,
        summary.get("maxWindMph", 0)
      ).map(result => result.color)}
      animation={{ delay: 0.3 }}
      disabled={val === "--"}
      bgColor={theme.components.meter.bg}
      accessoryItems={
        summary.hasAll(["windGust", "maxWind"]) === true &&
        summary.windGust > summary.maxWind && [
          {
            value: summary.windGustFormatted,
            content: (
              <Box textAlign="right">
                <Text variant="value" color="text.dark.tertiary">
                  {summary.windGustFormatted}
                </Text>
                <Text mt={-1} variant="label" fontSize="2xs" color="text.dark.tertiary">
                  Gust
                </Text>
              </Box>
            )
          }
        ]
      }
      {...rest}
    />
  );
}

function HumidityLinearMeter({ summary, orientation, ...rest }: MeterOpts) {
  const { theme } = ThemeContainer.useContainer();
  const minVal = summary.get("rhMin");
  const maxVal = summary.get("rhMax");
  let value = minVal;
  if (minVal !== maxVal) {
    value = {
      min: minVal,
      max: maxVal
    };
  }

  return (
    <LinearMeter
      orientation={orientation}
      label="Humidity"
      units="%"
      value={value}
      range={clampRange(
        roundToRange(summary.get("rhMin", 0), summary.get("rhMax", 100), null, null, 5),
        0,
        100
      )}
      gradient={colorsInRange(
        defaultTheme.colors.weather.humidity,
        summary.get("rhMin", 0),
        summary.get("rhMax", 100),
        5
      ).map(result => result.color)}
      animation={{ delay: 0.45 }}
      disabled={minVal === "--" && maxVal === "--"}
      bgColor={theme.components.meter.bg}
      {...rest}
    />
  );
}

function PressureLinearMeter({ summary, orientation, ...rest }: MeterOpts) {
  const { theme } = ThemeContainer.useContainer();
  const { pressureType } = PressureTypeContainer.useContainer();
  const minVal = _isNil(summary.getPressuresByType().min) ? "--" : summary.getPressuresByType().min;
  const maxVal = _isNil(summary.getPressuresByType().max) ? "--" : summary.getPressuresByType().max;

  let value = minVal;

  if (minVal !== maxVal) {
    value = {
      min: minVal,
      max: maxVal
    };
  }

  const getGradient = () => {
    if (summary.hasAll(["minPressureMb", "maxPressureMb"])) {
      return colorsInRange(
        defaultTheme.colors.weather.pressure,
        summary.get("minPressureMb"),
        summary.get("maxPressureMb"),
        5
      ).map(result => result.color);
    }
    return [];
  };

  return (
    <LinearMeter
      orientation={orientation}
      label="Pressure"
      units={summary.pressureUnits}
      value={value}
      range={roundToDecimalRange(
        minVal,
        maxVal,
        null,
        null,
        unitStore.pressure === "metric" ? 10 : 0.1
      )}
      gradient={getGradient()}
      animation={{ delay: 0.6 }}
      disabled={minVal === "--" || maxVal === "--"}
      bgColor={theme.components.meter.bg}
      {...rest}
    />
  );
}

function PrecipitationLinearMeter({ summary, orientation, ...rest }: MeterOpts) {
  const { theme } = ThemeContainer.useContainer();
  const val = summary.precipTotalFormatted;
  return (
    <LinearMeter
      orientation={orientation}
      label="Precipitation"
      units={summary.precipUnits}
      value={summary.precipTotalFormatted}
      range={clampRange(
        roundToDecimalRange(
          0,
          summary.get("precipTotal", 1),
          null,
          null,
          summary.isMetric ? 13 : 0.5
        ),
        0,
        summary.isMetric ? 255 : 10
      )}
      gradient={colorsInRange(
        defaultTheme.colors.weather.precip,
        0,
        summary.precipTotalIn,
        0.1
      ).map(result => result.color)}
      animation={{ delay: 0.75 }}
      disabled={val === null}
      bgColor={theme.components.meter.bg}
      {...rest}
    />
  );
}

export default function GraphView({ summary }: { summary: Summary }) {
  const Meters = [
    TemperatureLinearMeter,
    DewpointLinearMeter,
    WindLinearMeter,
    HumidityLinearMeter,
    PressureLinearMeter,
    PrecipitationLinearMeter
  ];

  let width = 250;
  if (matchesMediaQuery("tablet-up")) {
    width = 500;
  }

  return (
    <>
      <Hide tablet-down>
        <Flex justify="space-between">
          {Meters.map((Meter, i) => (
            <Meter key={i} summary={summary} orientation="vertical" />
          ))}
        </Flex>
      </Hide>
      <Hide desktop-up>
        {Meters.map((Meter, i) => (
          <React.Fragment key={Meter.name}>
            <Meter key={i} summary={summary} orientation="horizontal" barWidth={width} />
            {i !== Meters.length - 1 && <Box my={3} bg="brand.gray.100" height={"2px"} />}
          </React.Fragment>
        ))}
      </Hide>
    </>
  );
}
