import React, { useEffect } from "react";
import ChartObservations from "./chart-model/ChartObservations";
import ChartForecasts from "./chart-model/ChartForecasts";
import ChartSummaries from "./chart-model/ChartSummaries";
import DashboardChart from "./DashboardChart";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { UnitCategory } from "../../../../../types/enums";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

export interface ChartProps {
  collection: ChartObservations | ChartForecasts | ChartSummaries;
  config: {};
  date: Date;
}

export default function WindChart({ collection, date, period, config }: ChartProps) {
  const { unitStore } = UnitStoreContainer.useContainer();
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;

  function labelConfigCallback(value: string) {
    let label = unitStore[UnitCategory.Speed] === "metric" ? "km/h" : "mph";
    if (unitStore[UnitCategory.Speed] === "custom") {
      label = unitStore.values[UnitCategory.Speed];
    }
    // return `${value} ${unitStore[UnitCategory.Speed] === "metric" ? "km/h" : "mph"}`;
    return `${value} ${label}`;
  }

  const datasetConfigs = [
    {
      data: collection.wind,
      label: "Wind",
      key: "wind",
      borderColor: dataColors.windspeed,
      pointBackgroundColor: dataColors.windspeed
    },
    {
      data: collection.windGust,
      label: "Wind Gusts",
      key: "windGust",
      borderColor: dataColors.windgust,
      pointBackgroundColor: dataColors.windgust,
      showLine: false
    }
  ];

  return (
    <DashboardChart
      date={date}
      chartConfig={config}
      period={period}
      datasetConfigs={datasetConfigs}
      labelConfigCallback={labelConfigCallback}
      collection={collection}
      startsAtZero={true}
    />
  );
}
