import { useEffect, useState } from "react";
import aeris from "../../../../../../api";
import Place from "../../../../../../models/aeris/place/";
import Alert from "../../../../../../models/aeris/alert/";
import Alerts from "../../../../../../models/aeris/alert/Alerts";
import useFetchStateMachine, {
  FetchEvent,
  FetchState
} from "../../../../../common/hooks/useFetchStateMachine";
import { RefreshRates } from "../../../../../../types/enums";
import _get from "lodash.get";

export default function useAlertsQuery(placeId: string) {
  const [alerts, setAlerts] = useState(null);
  const [current, send] = useFetchStateMachine();

  const alertsApi = aeris.api();

  useEffect(() => {
    async function fetchData() {
      send("LOADING");
      const alertsRequest = alertsApi
        .endpoint("alerts")
        .place(placeId)
        .format("json");

      const [alertsResponse] = await Promise.all([alertsRequest.get()]);
      const success = _get(alertsResponse, "response.data.success", false);
      if (success === true) {
        if (alertsResponse.data.length === 0) {
          send("NO_DATA");
          return;
        }
        send("LOADED");
        const alerts2 = new Alerts({
          data: alertsResponse.data
        });
        setAlerts(alerts2);
      } else {
        send("ERROR");
      }
    }
    fetchData();
    const heartbeatHandle = window.setInterval(fetchData, RefreshRates.ForecastCard * 60 * 1000);
    return () => window.clearInterval(heartbeatHandle);
  }, [placeId]);
  return [alerts, current];
}
