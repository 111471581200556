import Place from "../place";
import Observation from "../observation";
import Collection from "../../Collection";

export default class NearbyStations extends Collection {
  constructor(config) {
    super(config);
    this.models = config.data.map(datum => {
      const place = new Place({
        placeId: datum.id,
        data: datum
      });
      place.observation = new Observation({
        data: datum
      });
      return place;
    });
  }
}
