import React, { useEffect, useState } from "react";
import "chart.js";
import { Box, Flex, Absolute, Spinner } from "pws-design-system/design-system";
import State from "../../../../../../common/components/state/";
import DelayedRenderer from "../../../../../../common/components/delayed-renderer";
import TabularObsPanel from "./TabularObsPanel";
import TabularSummaryPanel from "./TabularSummaryPanel";
import GraphPanel from "./GraphPanel";
import * as Charts from "../../../../common/charts";
import _get from "lodash.get";
import _noop from "lodash.noop";
import { CardPeriod } from "../../../../../../../types/enums";
import { ThemeContainer } from "../../../../../../common/hooks/useTheme";
import { convertToTimeZone } from "date-fns-timezone";

type ContentPanelProps = any & {};

export const ContentPanel = (props: ContentPanelProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;
  const charts = [
    {
      chart: Charts.Temperature,
      label: {
        [CardPeriod.Daily]: [
          { text: "Temperature", color: dataColors.temperature },
          { text: "Feels Like", color: dataColors.feelslike },
          { text: "Dew Point", color: dataColors.dewpoint }
        ],
        [CardPeriod.Monthly]: [
          { text: "Max Temperature", color: dataColors.temperature },
          { text: "Min Temperature", color: dataColors.feelslike },
          { text: "Dew Point", color: dataColors.dewpoint }
        ]
      }
    },
    {
      chart: Charts.Wind,
      label: {
        [CardPeriod.Daily]: [
          { text: "Wind", color: dataColors.windspeed },
          { text: "Wind Gust", color: dataColors.windgust }
        ],
        [CardPeriod.Monthly]: [
          { text: "Wind", color: dataColors.windspeed },
          { text: "Wind Gust", color: dataColors.windgust }
        ]
      }
    },
    {
      chart: Charts.WindDirection,
      label: {
        [CardPeriod.Daily]: [{ text: "Wind Direction", color: dataColors.direction }]
        //[CardPeriod.Monthly]: [{ text: "Wind Direction", color: dataColors.direction }]
      }
    },
    {
      chart: Charts.Percentage,
      label: {
        [CardPeriod.Daily]: [
          { text: "Humidity", color: dataColors.humidity },
          { text: "Sky Cover", color: dataColors.skycover }
        ],
        [CardPeriod.Monthly]: [
          { text: "Humidity", color: dataColors.humidity },
          { text: "Sky Cover", color: dataColors.skycover }
        ]
      }
    },
    {
      chart: Charts.Precip,
      label: {
        [CardPeriod.Daily]: [
          { text: "Precipitation", color: dataColors.precip },
          { text: "Accumulation", color: dataColors.precipaccum }
        ],
        [CardPeriod.Monthly]: [
          { text: "Precipitation", color: dataColors.precip },
          { text: "Accumulation", color: dataColors.precipaccum }
        ]
      }
    },
    {
      chart: Charts.Pressure,
      label: {
        [CardPeriod.Daily]: [{ text: "Pressure", color: dataColors.pressure }],
        [CardPeriod.Monthly]: [{ text: "Pressure", color: dataColors.pressure }]
      }
    },
    {
      chart: Charts.SolarRadiation,
      label: {
        [CardPeriod.Daily]: [{ text: "Solar Radiation", color: dataColors.solrad }],
        [CardPeriod.Monthly]: [{ text: "Solar Radiation", color: dataColors.solrad }]
      }
    },
    {
      chart: Charts.UV,
      label: {
        [CardPeriod.Daily]: [{ text: "UV", color: dataColors.uv }]
        // [CardPeriod.Monthly]: [{ text: "UV", color: dataColors.uv }]
      }
    }
  ];

  const { chartModel, date, period, view, state } = props;

  const getTableComponent = (period: CardPeriod) => {
    return period === CardPeriod.Daily ? TabularObsPanel : TabularSummaryPanel;
  };

  const [TableComponent, setTableComponent] = useState(() => getTableComponent(period));

  useEffect(() => {
    if (state.matches("initialLoadComplete.loaded")) {
      setTableComponent(() => getTableComponent(period));
    }
  }, [state]);

  return (
    <Box minHeight="200px" position="relative">
      <State state={state} matches={["initialLoad"]}>
        <DelayedRenderer delay={1000}>
          <Absolute top="0" left="0" right="0" bottom="0">
            <Flex height="100%" width="100%" align="center" justify="center">
              <Spinner></Spinner>
            </Flex>
          </Absolute>
        </DelayedRenderer>
      </State>
      <State state={state} matches={["initialLoadComplete.loading"]}>
        <DelayedRenderer delay={1000}>
          <Absolute
            top="0"
            bottom="0"
            left="0"
            right="0"
            background="hsla(0, 0%, 100%, .8)"
            zIndex={3}
          >
            <DelayedRenderer delay={1000}>
              <Flex height="100%" justify="center" align="center">
                <Spinner></Spinner>
              </Flex>
            </DelayedRenderer>
          </Absolute>
        </DelayedRenderer>
      </State>
      <State state={state} matches={["noData"]}>
        <Absolute top="20px" left="0" right="0" bottom="0">
          <Flex width="100%" height="100%" align="center" justify="center">
            No observations for the date selected.
          </Flex>
        </Absolute>
      </State>
      <State state={state} matches={["error"]}>
        <Absolute
          top="80px"
          left="0"
          right="0"
          bottom="20px"
          zIndex={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          There was an error loading history data.
        </Absolute>
      </State>
      <State state={state} matches={["initialLoadComplete"]}>
        {view === "graphs" && (
          <GraphPanel
            collection={chartModel}
            charts={charts}
            period={period}
            date={date}
            state={state}
            {...props}
          />
        )}
        {view === "tabular" && chartModel.hasData === true && (
          <TableComponent collection={chartModel} place={chartModel.place} />
        )}
      </State>
    </Box>
  );
};

export default ContentPanel;
