import Summaries from "../../../../../../models/aeris/summary/Summaries";
import Place from "../../../../../../models/aeris/place";
import _get from "lodash.get";
import { subMinutes } from "date-fns";

export default class ChartSummaries extends Summaries {
  place: Place = null;

  getChartData(prop, models = this.models) {
    if (this.hasData === false) {
      return null;
    }

    const currentTimeZoneOffset = new Date().getTimezoneOffset();
    const offset = this.place.timeZoneOffsetInMinutes - currentTimeZoneOffset;

    return models.map(model => {
      let data = _get(model, prop);
      if (data === null || (prop === "windGust" && data === 0)) {
        return null;
      }
      return {
        x: subMinutes(model.date, offset),
        y: data
      };
    });
  }

  get temps() {
    return this.getChartData("avgTemp");
  }

  get minTemps() {
    return this.getChartData("minTemp");
  }

  get maxTemps() {
    return this.getChartData("maxTemp");
  }

  get feelsLike() {
    return {};
  }

  get dewpoints() {
    return this.getChartData("dewptAvg");
  }

  get wind() {
    return this.getChartData("avgWind");
  }

  get windGust() {
    return this.getChartData("windGust");
  }
  get windDirection() {
    return this.getChartData("windDirectionDegrees");
  }
  get pressure() {
    return this.getChartData("avgSpressure");
  }

  get precip() {
    return this.getChartData("precipTotal");
  }

  get uv() {
    return this.getChartData("uvi");
  }

  get solarRadiation() {
    return this.getChartData("solrad");
  }

  get accumulatedPrecip() {
    const modelsWithAccumulation: any[] = [];

    this.models.reduce((prev, curr) => {
      const accumulatedPrecip = prev + curr.precipTotal;
      modelsWithAccumulation.push({
        date: curr.date,
        accumulatedPrecip
      });
      return accumulatedPrecip;
    }, 0);
    return this.getChartData("accumulatedPrecip", modelsWithAccumulation);
  }

  get humidity() {
    return this.getChartData("humidity");
  }

  get skyCover() {
    return this.getChartData("skyCover");
  }
}
