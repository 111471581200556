import React, { useEffect } from "react";
import ChartObservations from "./chart-model/ChartObservations";
import ChartForecasts from "./chart-model/ChartForecasts";
import ChartSummaries from "./chart-model/ChartSummaries";
import DashboardChart from "./DashboardChart";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { UnitCategory } from "../../../../../types/enums";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

export interface ChartProps {
  collection: ChartObservations | ChartForecasts | ChartSummaries;
  config: {};
  date: Date;
}

export default function UVChart({ collection, date, period, config }: ChartProps) {
  const { unitStore } = UnitStoreContainer.useContainer();
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;

  function labelConfigCallback(value: string) {
    return `${value}`;
  }

  const datasetConfigs = [
    {
      data: collection.uvIndex,
      label: "UV Index",
      key: "uvIndex",
      borderColor: dataColors.uv,
      pointBackgroundColor: dataColors.uv
    }
  ];

  return (
    <DashboardChart
      date={date}
      chartConfig={config}
      period={period}
      datasetConfigs={datasetConfigs}
      labelConfigCallback={labelConfigCallback}
      collection={collection}
      startsAtZero={true}
    />
  );
}
