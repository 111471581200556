import React, { forwardRef } from "react";
import { Badge, Box, Popover, Text } from "pws-design-system/design-system";
import Observation from "../../../../../models/aeris/observation/Observation";

const qcCodes = [
  {
    value: 0,
    label: "Failed",
    color: "red",
    desc: "This observation failed quality control."
  },
  {
    value: 3,
    label: "Caution",
    color: "yellow",
    desc: "Some of the attributes of this observation may be invalid."
  },
  {
    value: 7,
    label: "Questioned",
    color: "yellow",
    desc: "Some attributes of this observation may be inconsistent with other observations."
  },
  {
    value: 10,
    label: "OK",
    color: "green",
    desc: "This observation has passed quality control."
  }
];

const qcCodeInfo = (code: number): any => {
  let result: any;
  qcCodes.forEach(qc => {
    if (code >= qc.value) {
      result = qc;
    }
  });
  return result;
};

const Indicator = forwardRef((props, ref) => {
  return <Box ref={ref} width="12px" height="12px" rounded="full" {...props} />;
});

type QCBadgeProps = any & {
  observation: Observation;
  variant?: "badge" | "indicator";
  popover?: any;
};

const QCBadge = ({
  observation,
  variant = "badge",
  popover,
  ...rest
}: QCBadgeProps): React.ReactElement => {
  const qc = qcCodeInfo(observation.qcCode);
  let indicatorProps: any = {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: qc.value < 10 ? `${qc.color}.500` : "transparent",
    ...rest
  };
  if (qc.value < 7) {
    indicatorProps.bg = `${qc.color}.500`;
  }

  return (
    <>
      {observation.qcCode < 10 && qc && (
        <Popover.Container placement="auto" trigger="hover" {...popover}>
          <Popover.Trigger>
            {variant === "indicator" ? (
              <Indicator {...indicatorProps} />
            ) : (
              <Badge
                variant={qc.value >= 7 ? "outline" : "solid"}
                variantColor={qc.color}
                height="16px"
                {...rest}
              >
                {qc.label}
              </Badge>
            )}
          </Popover.Trigger>
          <Popover.Content
            maxWidth="250px"
            bg="rgba(0,0,0,0.9)"
            border={0}
            boxShadow="0 0 2px 0 rgba(0,0,0,.04),0 4px 16px 0 rgba(0,0,0,.16)"
            borderRadius="xl"
            color="white"
            zIndex={4}
          >
            <Popover.Arrow />
            <Popover.Body p={2}>
              <Text variant="caption2">{qc.desc}</Text>
            </Popover.Body>
          </Popover.Content>
        </Popover.Container>
      )}
      {observation.qcCode === 10 && variant === "indicator" && <Indicator {...indicatorProps} />}
    </>
  );
};

export default QCBadge;
