import { useState, useEffect } from "react";
import ChartForecasts from "../common/charts/chart-model/ChartForecasts";
import aeris from "../../../../api";
import Forecasts from "../../../../models/aeris/forecast/Forecasts";
import useFetchStateMachine from "../../../common/hooks/useFetchStateMachine";

export default function useForecastQuery({ place, period, limit }) {
  const [forecasts, setForecasts] = useState(new Forecasts(place.id));
  const [state, send] = useFetchStateMachine();

  const request = aeris
    .api()
    .endpoint("forecasts")
    .place(place.id)
    .format("json")
    .filter(period)
    .limit(limit);

  useEffect(() => {
    async function fetchForecasts() {
      send("LOADING");
      const response = await request.get();
      const forecasts = new Forecasts({
        data: response.data[0]
      });
      send("LOADED");
      setForecasts(forecasts);
    }
    fetchForecasts();
  }, [place]);
  return [forecasts, state];
}
