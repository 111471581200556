import React, { useEffect } from "react";
import ChartObservations from "./chart-model/ChartObservations";
import ChartForecasts from "./chart-model/ChartForecasts";
import ChartSummaries from "./chart-model/ChartSummaries";
import DashboardChart from "./DashboardChart";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { UnitCategory } from "../../../../../types/enums";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

export interface ChartProps {
  collection: ChartObservations | ChartForecasts | ChartSummaries;
  config: {};
  date: Date;
}

export default function SolarRadiationChart({ collection, date, period, config }: ChartProps) {
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;

  function labelConfigCallback(value: string) {
    return `${value} ${"W/m²"}`;
  }

  const datasetConfigs = [
    {
      data: collection.solarRadiation,
      label: "Solar Radiation",
      key: "solarRadiation",
      borderColor: dataColors.solrad,
      pointBackgroundColor: dataColors.solrad
    }
  ];

  return (
    <DashboardChart
      date={date}
      chartConfig={config}
      period={period}
      datasetConfigs={datasetConfigs}
      labelConfigCallback={labelConfigCallback}
      collection={collection}
      startsAtZero={true}
    />
  );
}
