import { navigate } from "gatsby";
import {
  Box,
  Button,
  Flex,
  Heading,
  Hide,
  Stack,
  Stat,
  Text,
  WeatherIcon
} from "pws-design-system/design-system";
import React from "react";
import Observation from "../../../../../../models/aeris/observation/Observation";
import Summary from "../../../../../../models/aeris/summary/Summary";
import Path from "../../../../../../models/path/Path";
import { Views } from "../../../../../../types/enums";
import ErrorBoundary from "../../../../../common/components/error-boundary";
import ResponsiveRender, {
  responsiveValue
} from "../../../../../common/components/responsive-render/ResponsiveRender";
import CardProps from "../CardProps";
import PwsCardDisplay from "../PwsCardDisplay";
import { HumidityMeter, PressureMeter, TemperatureMeter, WindMeter } from "./meters";
import QCBadge from "../../qc-badge";
import useObservationQuery from "./useObservationQuery";
import _get from "lodash.get";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";

interface LatestObservationsCardProps extends CardProps {
  initialObservation?: Observation;
  path: Path;
}

function LatestObservationsCard({ placeId, path }: LatestObservationsCardProps) {
  const [observation, summary, state] = useObservationQuery({
    placeId,
    fields: [
      "temp",
      "dewpoint",
      "humidity",
      "dateTimeIso",
      "pressure",
      "spressure",
      "altimeter",
      "wind",
      "windGust",
      "windDirDeg",
      "feelsLike",
      "precip",
      "icon",
      "weatherPrimary",
      "QCcode"
    ]
  });
  return (
    <LatestObservationsCardDisplay
      observation={observation}
      summary={summary}
      state={state}
      path={path}
    ></LatestObservationsCardDisplay>
  );
}

function LatestObservationsCardDisplay({
  observation = new Observation(),
  summary = new Summary(),
  path,
  state
}: {
  observation: Observation;
  summary: Summary;
  path: Path;
  state: any;
}) {
  const meters = [TemperatureMeter, HumidityMeter, PressureMeter, WindMeter];
  const { theme } = ThemeContainer.useContainer();

  function WeatherIconStat({ mr = 0 }) {
    return (
      <Flex align="center" mr={mr}>
        {observation.hasAll(["weatherShortCode", "weatherPrimary"]) && (
          <>
            <WeatherIcon code={observation.weatherShortCode} width="40px" height="40px" mr={3} />
            <Text variant="value">{observation.weatherPrimary}</Text>
          </>
        )}
      </Flex>
    );
  }

  return (
    <PwsCardDisplay
      HeadingSlot={() => (
        <>
          <Hide mobile>
            <Flex align="center" justify="space-between">
              <Stack isInline>
                <Heading as="h2" variant="subheadline" color={theme.colors.text.base.primary}>
                  Latest Observation
                </Heading>
                <Text
                  variant="caption2"
                  color="brand.gray.800"
                  ml={2}
                  mt={2}
                  color={theme.colors.text.base.secondary}
                >
                  {observation.has("dateDistanceToNowInWords") &&
                    `Updated ${observation.dateDistanceToNowInWords}`}
                </Text>
              </Stack>
              <QCBadge observation={observation} />
            </Flex>
          </Hide>
          <Hide tablet desktop>
            <Flex align="flex-start" justify="space-between">
              <Stack spacing={1}>
                <Heading as="h2" variant="subheadline">
                  Latest Observation
                </Heading>
                <Text mt={-1} variant="caption2" color="brand.gray.800">
                  {observation.has("dateDistanceToNowInWords") &&
                    `Updated ${observation.dateDistanceToNowInWords}`}
                </Text>
              </Stack>
              <QCBadge observation={observation} />
            </Flex>
          </Hide>
        </>
      )}
      LoadingState={({ observation }: { observation: Observation }) => (
        <Flex justify="center" wrap="wrap" mt={3} px={[2, null, null, 4]}>
          {meters.map((Meter, i) => (
            <Box my={3} mx={[3, null, 2, "10px"]} key={`latest-observation-meter-${i}`}>
              <Meter
                size={responsiveValue({
                  mobile: "sm",
                  tablet: "md",
                  desktop: "lg"
                })}
                observation={observation}
                animationDelay={i * 0.2}
              />
            </Box>
          ))}
        </Flex>
      )}
      boxShadow="sm"
      mb={3}
      observation={observation}
      state={state}
    >
      <Flex justify="center" wrap="wrap" mt={3} px={[2, null, null, 4]}>
        {meters.map((Meter, i) => (
          <Box my={3} mx={[3, null, 2, "10px"]} key={Meter.name}>
            <Meter
              size={responsiveValue({
                mobile: "sm",
                tablet: "md",
                desktop: "lg"
              })}
              observation={observation}
              animationDelay={i * 0.2}
            />
          </Box>
        ))}
      </Flex>
      <ResponsiveRender size={["tablet", "desktop"]}>
        <Stack mt={4} spacing={4} isInline>
          <WeatherIconStat />
          <Stat
            icon="temperature"
            value={observation.feelsLikeFormatted || "--"}
            label="Feels Like"
            units={observation.tempUnits}
          />
          <Stat
            icon="dewpoint"
            value={observation.dewpointFormatted || "--"}
            label="Dewpoint"
            units={observation.dewpointUnits}
          />
          <Stat
            icon="precip"
            value={
              (_get(summary, "rawData.data.profile.hasPrecip", false) &&
                summary.precipTotalFormatted) ||
              "--"
            }
            label="Precip Today"
            units={summary.precipUnits}
          />
          <Stat icon="uvi" value={observation.uvIndex || "--"} label="UV Index" />
        </Stack>
      </ResponsiveRender>
      <ResponsiveRender size={["mobile"]}>
        <Box bg="brand.gray.200" height="2px" mt={4} mb={3} />
        <WeatherIconStat />
        <Stack mt={4} spacing={4} isInline>
          <Stat
            icon="temperature"
            value={observation.feelsLikeFormatted || "--"}
            label="Feels Like"
            units={observation.tempUnits}
          />
          <Stat
            icon="dewpoint"
            value={observation.dewpointFormatted || "--"}
            label="Dewpoint"
            units={observation.tempUnits}
          />
        </Stack>
        <Stack mt={4} spacing={4} isInline>
          <Stat
            icon="precip"
            value={
              (_get(summary, "rawData.data.profile.hasPrecip", false) &&
                summary.precipTotalFormatted) ||
              "--"
            }
            label="Precip Today"
            units={summary.precipUnits}
          />
        </Stack>
        <Button onClick={() => navigate(path.mobileUrls[Views.Historical])} mt={4} width="100%">
          Today's Summary
        </Button>
      </ResponsiveRender>
    </PwsCardDisplay>
  );
}

const LatestObservationsCardWithErrorBoundary: React.FC<LatestObservationsCardProps> = (
  props
): React.ReactElement => {
  return (
    <ErrorBoundary variant="card" minHeight="370px">
      <LatestObservationsCard {...props} />
    </ErrorBoundary>
  );
};

export default LatestObservationsCardWithErrorBoundary;
