import React, { useState } from "react";
import { Button, Box } from "pws-design-system/design-system";
import ReactDatepicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _noop from "lodash.noop";
import { convertToLocalTime, convertToTimeZone } from "date-fns-timezone";
import { Global, ClassNames } from "@emotion/react";
import enUs from "date-fns/locale/en-US";
import { format, endOfDay } from "date-fns";
import { getDateString, getDate } from "../../../../../utils";

// register a locale for the datepicker display
registerLocale("enUs", enUs);

type DatepickerProps = {
  // initial date of the datepicker
  initialDate: Date;
  // timezone string from the tz database
  timezone: string;
  // earliest date allowed in the datepicker
  minDate: Date;
  // maximum date allowed in the datepicker
  maxDate: Date;
  // style variant, mapping to components
  variant: "default" | "fullWidth";
  // show the day calendar or month picker
  context: "daily" | "monthly";
  // change handler function
  changeHandler(date: Date): void;
};

const variants = {
  default: DatepickerDefaultVariant,
  fullWidth: DatepickerFullWidthVariant
};

export default function Datepicker({
  initialDate,
  timezone,
  changeHandler = _noop,
  minDate,
  maxDate,
  variant = "default",
  context = "daily"
}: DatepickerProps) {
  const [date, setDate] = useState(getDate(initialDate));
  minDate = minDate || convertToTimeZone(new Date("01/01/2012"), { timeZone: timezone });
  maxDate = maxDate || endOfDay(convertToTimeZone(new Date(), { timeZone: timezone }));

  function handleChange(val: Date) {
    changeHandler(getDateString(format(val, "yyyy-MM-dd")));
    changeHandler(format(val, "yyyy-MM-dd"));
    setDate(val);
  }
  const Variant = variants[variant];

  return (
    <>
      <Global
        styles={{
          ".react-datepicker__month .react-datepicker__month-text": {
            padding: "4px"
          },
          ".react-datepicker__header": {
            paddingBottom: "7px"
          },
          ".react-datepicker__header__dropdown": {
            marginTop: "6px"
          },
          ".react-datepicker__header__dropdown select": {
            background: "white"
          }
        }}
      />
      <Variant
        context={context}
        date={getDate(initialDate)}
        timezone={timezone}
        changeHandler={handleChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  );
}

function DatepickerDefaultVariant({ date, context, changeHandler, minDate, maxDate, timezone }) {
  const displayFormat = {
    // daily: (date: Date) => format(convertToLocalTime(date, { timeZone: timezone }), "yyyy-MM-dd"),
    daily: (date: Date) => format(date, "yyyy-MM-dd"),
    monthly: (date: Date) =>
      date.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric"
      })
  };
  return (
    <>
      <ReactDatepicker
        selected={date}
        onChange={changeHandler}
        minDate={minDate}
        maxDate={maxDate}
        showYearDropdown
        dropdownMode="select"
        showMonthYearPicker={context === "monthly"}
        locale="enUs"
        customInput={
          <Button leftIcon="calendar" size="sm" rounded="0">
            <Box width={"100px"}>{displayFormat[context](date)}</Box>
          </Button>
        }
      />
    </>
  );
}

function DatepickerFullWidthVariant({ date, context, changeHandler, minDate, maxDate }) {
  const displayFormat = {
    daily: date =>
      date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric"
      }),
    monthly: date =>
      date.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric"
      })
  };
  return (
    <ClassNames>
      {({ css, cx }) => (
        <ReactDatepicker
          selected={date}
          onChange={changeHandler}
          minDate={minDate}
          maxDate={maxDate}
          showYearDropdown
          dropdownMode="select"
          showMonthYearPicker={context === "monthly"}
          customInput={
            <Button leftIcon="calendar" width="100%" justify="flex-start">
              <Box width="100%" textAlign="left">
                {displayFormat[context](date)}
              </Box>
            </Button>
          }
          wrapperClassName={css({ width: "100%" })}
        />
      )}
    </ClassNames>
  );
}
