import React, { useState, useEffect } from "react";
import ObservationHistoryCard from "../dashboard/cards/observation-history";
import DailySummaryCard from "../dashboard/cards/daily-summary";
import PlaceLayout from "../common/Layout";
import {
  Box,
  Col,
  Flex,
  Hide,
  Text,
  Button,
  Icon,
  PseudoBox,
  Stack
} from "pws-design-system/design-system";
import _startcase from "lodash.startcase";
import _lowercase from "lodash.lowercase";
import _some from "lodash.some";
import Place from "../../../../models/aeris/place";
import Path from "../../../../models/aeris/Path";
import PlaceMeta from "../../../../models/aeris/place/PlaceMeta";
import { dateStore, UseDateContainer } from "../../../common/hooks/stores/useDateStore";
import { ThemeContainer } from "../../../common/hooks/useTheme";

type HistoricalPageProps = any & {
  isMetric: boolean;
  place: Place;
  path: Path;
};

type TabType = {
  key: string;
  hash: string;
};

type TabProps = {
  isActive?: boolean;
  clickHandler?: (result?: any) => void;
  children: any;
};

type TabsProps = any & {
  tabs: TabType[];
  onTabActivate?: (tab: TabType) => void;
};

const Tab = ({ clickHandler, isActive, children }: TabProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  return (
    <Button
      variant="ghost"
      p="0"
      color={isActive ? theme.components.tabs.active.color : theme.components.tabs.color}
      rounded="none"
      onClick={() => clickHandler(children)}
      _hover={{
        background: "transparent",
        color: theme.components.tabs.active.color
      }}
      _active={{
        background: "transparent"
      }}
    >
      <PseudoBox
        _before={{
          border: "8px solid transparent",
          borderTopColor: theme.components.tabs.border,
          content: "''",
          display: isActive ? "block" : "none",
          position: "absolute",
          left: "50%",
          marginTop: "15px",
          transform: "translate(-50%, -100%)",
          top: "100%"
        }}
        _after={{
          content: "''",
          display: isActive ? "block" : "none",
          position: "absolute",
          width: "50px",
          height: "4px",
          bottom: "0",
          left: "50%",
          marginLeft: "-25px",
          background: theme.components.tabs.border
        }}
      >
        <Text variant="label" m={0}>
          {children}
        </Text>
      </PseudoBox>
    </Button>
  );
};

const Tabs = ({ tabs, onTabActivate, ...rest }: TabsProps): React.ReactElement => {
  let initialActive = tabs[0];
  const hash = window.location.hash;
  const activeHash = tabs.filter((tab: TabType) => `#${tab.hash}` === hash);

  if (activeHash.length > 0) {
    initialActive = activeHash[0];
  }

  const [activeTab, setActiveTab] = useState(initialActive);

  onTabActivate(activeTab);

  const handleClick = (tabKey: string) => {
    const matchingTab = tabs.filter((tab: TabType) => tabKey === tab.key);
    setActiveTab(matchingTab[0]);
    history.pushState(null, null, `#${matchingTab[0].hash}`);
    onTabActivate(matchingTab[0]);
  };

  return (
    <Stack spacing={3} isInline {...rest}>
      {tabs.map((tab: any, index: number) => (
        <Box key={`tab-${index}`}>
          <Tab key={tab.key} isActive={tab.key === activeTab.key} clickHandler={handleClick}>
            {tab.key}
          </Tab>
        </Box>
      ))}
    </Stack>
  );
};

const HistoricalPageDisplay = (props: HistoricalPageProps) => {
  const placeMeta = new PlaceMeta(props.place, props.path);
  const { date, period, setDate, setPeriod, setTimezone } = UseDateContainer.useContainer();

  const tabs = [
    { key: "Overview", hash: "" },
    { key: "Tabular", hash: "tabular" },
    { key: "Hourly", hash: "hourly" }
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    console.log("!!", props.place.timezone);
    setTimezone(props.place.timezone);
  }, []);

  const handleTabActivate = (tab: TabType) => {
    setActiveTab(tab);
  };

  const dateChangeHandler = (date: Date) => {
    setDate(date);
  };

  const periodChangeHandler = (period: CardPeriod) => {
    setPeriod(period);
  };

  return (
    <>
      <Hide tablet desktop width="100%">
        <Flex justify="center" width="100%" mb={3}>
          <Box mx={1} px={1}>
            <Tabs mx="auto" onTabActivate={handleTabActivate} tabs={tabs} />
          </Box>
        </Flex>
      </Hide>
      <Col md={12}>
        {activeTab.key === "Overview" && date && (
          <DailySummaryCard
            initialDate={date}
            initialPeriod={period}
            onDateChange={dateChangeHandler}
            onPeriodChange={periodChangeHandler}
            placeId={props.place.id}
          />
        )}
        {activeTab.key === "Tabular" && date && (
          <DailySummaryCard
            initialDate={date}
            initialView="tabular"
            initialPeriod={period}
            onDateChange={dateChangeHandler}
            onPeriodChange={periodChangeHandler}
            placeId={props.place.id}
          />
        )}
        {activeTab.key === "Hourly" && date && dateStore.timezone && (
          <ObservationHistoryCard
            initialDate={date}
            initialPeriod={period}
            onDateChange={dateChangeHandler}
            onPeriodChange={periodChangeHandler}
            placeId={props.place.id}
          />
        )}
      </Col>
    </>
  );
};
const HistoricalPage = (props: HistoricalPageProps): React.ReactElement => {
  const placeMeta = new PlaceMeta(props.place, props.path);
  return (
    <PlaceLayout
      {...props}
      metaTitle={placeMeta.buildMeta("historical", "title")}
      metaDescription={placeMeta.buildMeta("historical", "description")}
    >
      <HistoricalPageDisplay {...props} />
    </PlaceLayout>
  );
};

export default HistoricalPage;
