import { Table, Thead, Td as Ctd, Th as Cth, Tr as Ctr } from "@chakra-ui/react";
import { Theme } from "pws-design-system/design-system";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";
import React from "react";
import ResponsiveRender from "../../../../../common/components/responsive-render";

const Tr = ({ children, variant = "default", ...rest }) => {
  const { theme } = ThemeContainer.useContainer();
  const defaultStyles = {};

  const variants = {
    default: {
      ...defaultStyles,
      borderStyle: "solid",
      borderColor: "#3aba50",
      borderWidth: "0 0 3px 0",
      padding: "4px 8px"
    },
    light: {
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: theme.components.table.borderBottomColor
    }
  };

  const selectedVariant = variants[variant];

  return (
    <Ctr textAlign="left" {...selectedVariant} {...rest}>
      {children}
    </Ctr>
  );
};

const Th = ({ children, variant = "default", ...rest }) => {
  const { theme } = ThemeContainer.useContainer();
  const defaultStyles = {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: Theme.fontSizes.xs,
    letterSpacing: ".1em",
    margin: 0,
    padding: "4px 8px"
  };

  const variants = {
    default: {
      ...defaultStyles
    },
    cell: {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: theme.components.table.borderBottomColor,
      padding: "8px 0"
    }
  };

  const selectedVariant = variants[variant];

  return (
    <Cth {...selectedVariant} {...rest}>
      {children}
    </Cth>
  );
};

const DesktopTh = ({ children, ...rest }) => {
  return (
    <ResponsiveRender size="tablet-up">
      <Th {...rest}>{children}</Th>
    </ResponsiveRender>
  );
};

const DesktopTd = ({ children, ...rest }) => {
  return (
    <ResponsiveRender size="tablet-up">
      <Td {...rest}>{children}</Td>
    </ResponsiveRender>
  );
};

const Td = ({ children, variant = "default", ...rest }) => {
  const { theme } = ThemeContainer.useContainer();
  const defaultStyles = {
    textAlign: "center",
    padding: "8px 0"
  };

  const variants = {
    default: {
      ...defaultStyles
    },
    bordered: {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: theme.components.table.borderBottomColor
    }
  };

  const selectedVariant = variants[variant];

  return (
    <Ctd {...selectedVariant} {...rest}>
      {children}
    </Ctd>
  );
};

export { Table, Thead, Td, Th, Tr, DesktopTd, DesktopTh };
