import React from "react";
import { Button } from "pws-design-system/design-system";
import { ButtonProps } from "pws-design-system/design-system/components/button/Button";

export default function NavItem(
  props: ButtonProps & React.HTMLProps<HTMLButtonElement>
): React.ReactElement {
  return (
    <Button
      size="sm"
      fontFamily="heading"
      fontSize="xl"
      fontWeight="semibold"
      textTransform="none"
      letterSpacing="normal"
      py={1}
      {...props}
    />
  );
}
