import { useState, useEffect } from "react";
import ChartForecasts from "../common/charts/chart-model/ChartForecasts";
import aeris from "../../../../api";
import { startOfDay, format } from "date-fns";
const { parseFromTimeZone } = require("date-fns-timezone");
import useFetchStateMachine from "../../../common/hooks/useFetchStateMachine";
import _get from "lodash.get";

export default function useChartForecastQuery({ place, from, to, period, limit }) {
  const [forecasts, setForecasts] = useState(new ChartForecasts(place.id));
  const [state, send] = useFetchStateMachine();
  const startDate = startOfDay(from);
  let endDate: Date;
  if (to) {
    endDate = startOfDay(to);
  }

  const request = aeris
    .api()
    .endpoint("forecasts")
    .place(place.id)
    .format("json")
    .from(`${format(startDate, "yyyy-MM-dd")} midnight`)
    .filter("1hr")
    .plimit(limit);

  if (endDate) {
    request.to(`${format(endDate, "yyyy-MM-dd")} midnight`);
  }

  useEffect(() => {
    async function fetchForecasts() {
      send("LOADING");
      const response = await request.get();
      const success = _get(response, "response.data.success", false);
      if (success === true) {
        const forecasts = new ChartForecasts({
          data: response.data[0]
        });
        forecasts.place = place;
        send("LOADED");
        setForecasts(forecasts);
      } else {
        send("ERROR");
      }
    }
    fetchForecasts();
  }, [place, from]);
  return [forecasts, state];
}
