import React, { useEffect } from "react";
import { Box, Flex } from "pws-design-system/design-system";
import IsMetric from "../../../../common/hooks/useIsMetric";
import ArrowIcon from "../../../../common/components/arrow-icon";
import { formatValue } from "../../../../../models/aeris/units";
import _has from "lodash.has";
import _keys from "lodash.keys";
import _pickBy from "lodash.pickby";
import _get from "lodash.get";
import { TimeStoreContainer } from "../../../../common/hooks/stores/useTimeStore";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { ThemeContainer } from "../../../../common/hooks/useTheme";
import {
  Table,
  Thead as TableHeader,
  Th as TableHeadCell,
  Tr as TableRow,
  Td as TableCell,
  DesktopTd,
  DesktopTh
} from "../../../place/dashboard/cards/nearby-stations/Table";

const { formatToTimeZone } = require("date-fns-timezone");

const TabularPanel = ({ forecasts, place }) => {
  const { theme } = ThemeContainer.useContainer();
  const { unitStore } = UnitStoreContainer.useContainer();
  const { getTimeFormatString } = TimeStoreContainer.useContainer();

  const models = forecasts.models;
  const headers = [
    {
      text: "Time",
      minWidth: "150px"
    },
    {
      text: "Temp"
    },
    {
      text: "Dew Point"
    },
    {
      text: "Humidity"
    },
    {
      text: "Wind",
      colSpan: 3
    },
    {
      text: "Pressure"
    },
    {
      text: "Precip",
      colSpan: 2
    },
    {
      text: "Snow",
      colSpan: 2
    }
  ];

  const columns = [
    {
      label: "",
      data: (index: number) =>
        formatToTimeZone(models[index].date, getTimeFormatString(), {
          timeZone: place.timezone
        })
    },
    {
      label: "",
      data: (index: number) => (
        <>
          {models[index].avgTemp}
          {models[index].tempUnits}
        </>
      )
    },
    {
      label: "",
      data: (index: number) => (
        <>
          {models[index].avgDewpoint}
          {models[index].tempUnits}
        </>
      )
    },
    {
      label: "",
      data: (index: number) => <>{models[index].humidity}%</>
    },
    {
      label: "Speed",
      data: (index: number) => (
        <>
          {models[index].avgWindSpeed} {models[index].windUnits}
        </>
      )
    },
    {
      label: "Direction",
      data: (index: number) => {
        const windDir = models[index].get("windDirection", "");
        const windDirDeg = models[index].windDirectionDegrees;
        return (
          <Flex justify="center" align="center">
            <Box as="span">{windDir}</Box>
            {models[index].has("windDirectionDegrees") && (
              <Box
                as="span"
                width="16px"
                height="16px"
                ml={2}
                transform={`rotate(${windDirDeg}deg)`}
              >
                <ArrowIcon size="16" theme={{ ...theme.components.table.arrowIcon }} />
              </Box>
            )}
          </Flex>
        );
      }
    },
    {
      label: "Gust",
      data: (index: number) => (
        <>
          {models[index].avgWindGust} {models[index].windUnits}
        </>
      )
    },
    {
      label: "Station",
      data: (index: number) => (
        <>
          {models[index].averagePressureFormatted} {models[index].pressureUnits}
        </>
      )
    },
    {
      label: "Total",
      data: (index: number) => <>{models[index].precipFormattedWithUnits}</>
    },
    {
      label: "Accum",
      data: (index: number) => {
        if (forecasts.has("precipAccumulationByPeriod") === false) {
          return "--";
        }
        const val = Number(forecasts.precipAccumulationByPeriod[index]);
        return formatValue(val, "rain", unitStore.rain === "metric", true, true);
      }
    },
    {
      label: "Total",
      data: (index: number) => <>{models[index].snowFormattedWithUnits}</>
    },
    {
      label: "Accum",
      data: (index: number) => {
        if (forecasts.has("snowAccumulationByPeriod") === false) {
          return "--";
        }
        const val = Number(forecasts.snowAccumulationByPeriod[index]);
        return formatValue(val, "snow", unitStore.snow === "metric", true, true);
      }
    }
  ];

  return (
    <Box overflow="auto" width="100%">
      <Table width="100%">
        <TableHeader>
          <TableRow bg="#3aba50" color="white">
            {headers.map((header, index) => (
              <TableCell
                key={`forecast-table-header-${index}`}
                colSpan={_get(header, "colSpan", 1)}
                minWidth={_get(header, "minWidth")}
                whiteSpace="nowrap"
              >
                {header.text}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                scope="col"
                colSpan={column.span}
                border="bottom"
                key={`forecast-table-subheader-${index}`}
              >
                {column.label} {_has(column, "units") === true && `(${column.units})`}
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <tbody>
          {forecasts.models.map((model, index: number) => (
            <TableRow
              key={`forecast-table-row-${index}`}
              borderBottomWidth="1px"
              borderBottomColor={theme.components.table.borderBottomColor}
            >
              {columns.map((column, colIndex) => (
                <TableCell
                  colSpan={column.span}
                  key={`forecast-table-row-${index}-${colIndex}`}
                  fontSize="14px"
                  fontWeight={colIndex === 0 ? "bold" : null}
                >
                  {column.data(index)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

export default TabularPanel;
