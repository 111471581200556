import { Link as ReachLink } from "gatsby";
import { Absolute, Box, Card, Flex, Link, Spinner, Theme } from "pws-design-system/design-system";
import React from "react";
import DelayedRenderer from "../../../../common/components/delayed-renderer/DelayedRender";
import ErrorBoundary from "../../../../common/components/error-boundary";
import { FetchState } from "../../../../common/hooks/useFetchStateMachine";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

type CardProps = any & {
  state: FetchState;
  HeadingSlot: (state: FetchState, rest: any[]) => React.ReactNode;
  LoadingState: any;
  children: React.ReactNode;
  rest: any[];
};

function InitialLoadingState() {
  return (
    <DelayedRenderer delay={3000}>
      <Absolute top="0" left="0" right="0" bottom="0">
        <Flex height="100%" width="100%" align="center" justify="center">
          <Spinner></Spinner>
        </Flex>
      </Absolute>
    </DelayedRenderer>
  );
}

export default function PwsCardDisplay({
  state,
  HeadingSlot = () => <></>,
  LoadingState = InitialLoadingState,
  children,
  ...rest
}: CardProps): React.ReactElement {
  const { theme } = ThemeContainer.useContainer();
  return (
    <ErrorBoundary variant="card">
      <Box
        bg={theme.components.dashboard.card.bg}
        rounded={["lg", null, null, "xl"]}
        p={[3, null, null, 4]}
        boxShadow="sm"
        mb={3}
        position="relative"
        {...rest}
        height={
          state.matches("initialLoadComplete.loaded") === true
            ? `calc(100% - ${Theme.space[3]}px)`
            : ["382px", "394px"]
        }
      >
        <HeadingSlot state={state} {...rest} />
        {state.matches("error") === true && (
          <Absolute top="0" left="0" right="0" bottom="0">
            <Flex p={4} height="100%" width="100%" align="center" justify="center">
              <div>
                An error occurred while requesting data. Please check your network connection or{" "}
                <Link to="/support" as={ReachLink}>
                  contact our support team
                </Link>{" "}
                if you feel this is an error.
              </div>
            </Flex>
          </Absolute>
        )}
        {state.matches("initialLoad") === true && <LoadingState {...rest} />}
        {state.matches("initialLoadComplete") && <>{children}</>}
      </Box>
    </ErrorBoundary>
  );
}
