import React, { useEffect } from "react";
import ChartObservations from "./chart-model/ChartObservations";
import DashboardChart from "./DashboardChart";
import { ChartProps } from "./WindChart";
import { UnitStoreContainer } from "../../../../common/hooks/stores/useUnitStore";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

export default function TempChart({ collection, period, date, config }: ChartProps) {
  const { unitStore } = UnitStoreContainer.useContainer();
  const { theme } = ThemeContainer.useContainer();
  const dataColors = theme.components.charts.data;

  const labelConfigCallback = value => {
    return `${value}${unitStore.temp === "metric" ? "°C" : "°F"}`;
  };
  const datasetConfigs = [];
  if (period === "daily") {
    datasetConfigs.push(
      {
        data: collection.temps,
        label: "Temperature",
        key: "temps",
        borderColor: dataColors.temperature,
        pointBackgroundColor: dataColors.temperature
      },
      {
        data: collection.dewpoints,
        label: "Dew Point",
        key: "dewpoints",
        borderColor: dataColors.dewpoint,
        pointBackgroundColor: dataColors.dewpoint
      },
      {
        data: collection.feelsLike,
        label: "Feels Like",
        key: "feelsLike",
        borderColor: dataColors.feelslike,
        pointBackgroundColor: dataColors.feelslike
      }
    );
  }
  if (period === "monthly") {
    datasetConfigs.push(
      {
        data: collection.maxTemps,
        label: "Max Temperature",
        key: "maxTemps",
        borderColor: dataColors.temperature,
        pointBackgroundColor: dataColors.temperature
      },
      {
        data: collection.dewpoints,
        label: "Dew Point",
        key: "dewpoints",
        borderColor: dataColors.dewpoint,
        pointBackgroundColor: dataColors.dewpoint
      },
      {
        data: collection.minTemps,
        label: "Min Temperature",
        key: "minTemps",
        borderColor: dataColors.feelslike,
        pointBackgroundColor: dataColors.feelslike
      }
    );
  }
  return (
    <DashboardChart
      date={date}
      period={period}
      chartConfig={config}
      datasetConfigs={datasetConfigs}
      labelConfigCallback={labelConfigCallback}
      collection={collection}
    />
  );
}
