import { fonts } from "pws-design-system/design-system/base/typography";
import _merge from "lodash.merge";
import _cloneDeep from "lodash.clonedeep";
import {
  dataTypeForProp,
  unitForDataType,
  formatValue
} from "../../../../../../models/aeris/units";
import { unitStore } from "../../../../../common/hooks/stores/useUnitStore";
import { matchesMediaQuery } from "../../../../../common/components/responsive-render/ResponsiveRender";
import merge from "webpack-merge";
import cloneDeep from "lodash.clonedeep";

export const datasetConfig = {
  pointRadius: 2,
  borderWidth: 4,
  pointHitRadius: matchesMediaQuery("mobile") ? 1 : 1,
  hoverRadius: 6,
  hoverBorderWidth: 4,
  // hoverBackgroundColor: "black",
  // hoverBorderColor: "black",
  fill: false,
  spanGaps: false,
  clip: {
    bottom: false,
    right: 0,
    left: 0,
    top: false
  }
};

export const tooltipConfig = {
  intersect: false,
  axis: "x",
  yPadding: 10,
  xPadding: 10,
  caretSize: 8,
  backgroundColor: "rgba(0, 0, 0, .9)",
  titleFontFamily: fonts.heading,
  titleFontStyle: "bold",
  titleFontSize: 18,
  bodyFontFamily: fonts.label,
  bodyFontSize: 11,
  bodySpacing: 6,
  enabled: false,
  custom: function(model: any) {
    let tooltipEl = document.getElementById("chartjs-tooltip");
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      document.body.appendChild(tooltipEl);

      // add tooltip caret styles to head
      const style = document.createElement("style");
      style.innerText = `
      #chartjs-tooltip {
        transform: translate(0, -50%);
        transition: top 0.15s ease-in-out, left 0.15s ease-in-out, opacity 0.2s;
      }
      #chartjs-tooltip:before {
        border: 10px solid transparent;
        content:"";
        display: block;
        position: absolute;
        z-index: 99;
      }
      #chartjs-tooltip.top:before,
      #chartjs-tooltip.bottom:before {
        left: 50%;
        top: 0;
      }
      #chartjs-tooltip.left:before {
        left: 0;
        top: 50%;
      }
      #chartjs-tooltip.right:before {
        left: 100%;
        top: 50%;
      }
      #chartjs-tooltip.top {
          margin-top: 12px;
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
      }
      #chartjs-tooltip.top:before {
          border-bottom-color: rgba(0, 0, 0, .9);
          -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
      }
      #chartjs-tooltip.bottom {
          margin-top: -12px;
          -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
      }
      #chartjs-tooltip.bottom:before {
          border-top-color: rgba(0, 0, 0, .9);
          top: 100%;
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
      }
      #chartjs-tooltip.left {
        margin-left: 12px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
      #chartjs-tooltip.left:before {
          border-right-color: rgba(0, 0, 0, .9);
          -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
      }
      #chartjs-tooltip.right {
        margin-left: -12px;
        -webkit-transform: translate(-100%, -50%);
        transform: translate(-100%, -50%);
      }
      #chartjs-tooltip.right:before {
          border-left-color: rgba(0, 0, 0, .9);
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
      }
      `;
      document.head.appendChild(style);
    }

    if (model.opacity === 0) {
      tooltipEl.style.opacity = `0`;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove(
      "above",
      "below",
      "left",
      "center",
      "right",
      "top",
      "bottom",
      "no-transform"
    );
    if (model.yAlign && model.yAlign !== "center") {
      tooltipEl.classList.add(model.yAlign);
    } else if (model.xAlign) {
      tooltipEl.classList.add(model.xAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    if (model.body) {
      const titleLines = model.title || [];
      const bodyLines = model.body.map((item: any) => item.lines);
      let html = "";

      if (titleLines.length > 0) {
        html += `<div style="font-family: ${model._titleFontFamily.replace(
          /"/g,
          "'"
        )}; font-weight: ${
          model.titleFontStyle
        }; border-bottom: 2px solid rgba(255,255,255,0.2); margin-bottom: 8px;">`;
      }

      titleLines.forEach((title: string) => {
        html += `<div style="font-size: ${model.titleFontSize}px; color: ${model.titleFontColor};">${title}</div>`;
      });

      if (titleLines.length > 0) {
        html += "</div>";
      }

      bodyLines.forEach((body: string, index: number) => {
        const colors = model.labelColors[index];
        const parts = (body.toString() || "").split(":");
        const label = parts[0];
        let value = `${parts[1]}`;

        // if the data point is weather-related, make sure it's formatted properly
        const dataType = dataTypeForProp(label);
        if (dataType) {
          const isMetric = unitStore[dataType];
          const unit = unitForDataType(dataType, isMetric === "metric");
          value = formatValue(value, dataType, isMetric === "metric");
          if (unit && unit.length > 0) {
            value = `${value}<span style="padding-left: 2px; font-size: ${model.bodyFontSize +
              4}px; font-weight: normal;">${unit}</span>`;
          }
        }

        html += `
          <div style="display: flex; align-items: center; margin-right: 10px; ${
            index < bodyLines.length - 1 ? `margin-bottom: ${model.bodySpacing}px;` : ""
          }">
            <div style="height: 15px; width: 15px; background-color: ${
              colors.backgroundColor
            }; border: 2px solid #fff; border-radius: 50%;"></div>
            <div style="text-transform: uppercase; letter-spacing: 0.05em; margin-left: 6px;">${label}:</div>
            <div style="font-family: ${model._titleFontFamily.replace(
              /"/g,
              "'"
            )}; margin-left: 6px; height: 15px; font-size: ${model.bodyFontSize +
          8}px; line-height: ${model.bodyFontSize + 8}px;">${value}</div>
          </div>
        `;
      });

      const position = this._chart.canvas.getBoundingClientRect();
      tooltipEl.innerHTML = html;
      tooltipEl.style.opacity = "1";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left = `${Math.round(position.left + window.pageXOffset + model.caretX)}px`;
      tooltipEl.style.top = `${Math.round(position.top + window.pageYOffset + model.caretY)}px`;
      tooltipEl.style.backgroundColor = model.backgroundColor;
      tooltipEl.style.borderRadius = "6px";
      tooltipEl.style.color = model.bodyFontColor;
      tooltipEl.style.fontFamily = model._bodyFontFamily;
      tooltipEl.style.fontSize = `${model.bodyFontSize}px`;
      tooltipEl.style.fontStyle = model._bodyFontStyle;
      tooltipEl.style.minWidth = "100px";
      tooltipEl.style.padding = `${model.yPadding}px ${model.xPadding}px`;
      tooltipEl.style.pointerEvents = "none";
    }
  }
};

export const chartConfig = {
  type: "LineWithLine",
  options: {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: tooltipConfig,
    events: ["mousemove", "mouseout", "click", "touchstart", "touchmove", "touchend"],
    scales: {
      yAxes: [
        {
          gridLines: {
            drawTicks: false,
            drawOnChartArea: true,
            color: "#ddd",
            drawBorder: false
          },
          ticks: {
            stepSize: 5,
            fontColor: "#555",
            maxTicksLimit: 4,
            fontFamily: fonts.heading,
            fontSize: 14,
            padding: 25
          }
        }
      ],
      xAxes: [
        {
          type: "time",
          time: {
            // stepSize: 1,
            parser: "YYYY-MM-DD'T'HH:mm:ss.SSSZ",
            tooltipFormat: "MMM D, YYYY h:mm a"
          },
          distribution: "linear",
          gridLines: {
            drawTicks: true,
            drawOnChartArea: false,
            tickMarkLength: 10,
            color: "#ddd"
          },
          ticks: {
            fontColor: "#555",
            fontFamily: fonts.heading,
            fontSize: 14,
            // maxRotation: 45,
            // minRotation: 45,
            lineWidth: 10,
            tickMarkLength: 2,
            drawTicks: true,
            padding: 10,
            major: {
              enabled: true
            }
          }
        }
      ]
    }
  },
  data: {
    datasets: [datasetConfig]
  }
};

const darkChartConfig = cloneDeep(chartConfig);
darkChartConfig.options.scales.yAxes = [
  {
    gridLines: {
      drawTicks: false,
      drawOnChartArea: true,
      color: "#181818",
      drawBorder: false
    },
    ticks: {
      stepSize: 5,
      fontColor: "#ccc",
      maxTicksLimit: 4,
      fontFamily: fonts.heading,
      fontSize: 14,
      padding: 25
    }
  }
];

darkChartConfig.options.scales.xAxes = [
  {
    type: "time",
    time: {
      // stepSize: 1,
      parser: "YYYY-MM-DD'T'HH:mm:ss.SSSZ",
      tooltipFormat: "MMM D, YYYY h:mm a"
    },
    distribution: "linear",
    gridLines: {
      drawTicks: true,
      drawOnChartArea: false,
      tickMarkLength: 10,
      color: "#181818"
    },
    ticks: {
      fontColor: "#ccc",
      fontFamily: fonts.heading,
      fontSize: 14,
      // maxRotation: 45,
      // minRotation: 45,
      lineWidth: 10,
      tickMarkLength: 2,
      drawTicks: true,
      padding: 10,
      major: {
        enabled: true
      }
    }
  }
];

export function configBuilder(config) {
  return _merge(_cloneDeep(baseConfig), {
    options: {
      scales: {
        xAxes: [
          {
            ticks: {}
          }
        ],
        yAxes: [
          {
            ticks: {
              callback: function(value) {
                return `${value}${config.unitLabel}`;
              }
            }
          }
        ]
      }
    },
    data: {
      datasets: config.datasets.map(dataset => {
        return _merge(_cloneDeep(datasetConfig), {
          data: dataset.data,
          borderColor: dataset.color,
          pointBackgroundColor: dataset.color
        });
      })
    }
  });
}

export function getConfigByTheme(theme) {
  const { name } = theme;
  if (name === "light") {
    return chartConfig;
  }
  return darkChartConfig;
}
