import React from "react";
import { Box, Stack, Text } from "pws-design-system/design-system";
import _has from "lodash.has";
import _keys from "lodash.keys";
import _pickBy from "lodash.pickby";
import _get from "lodash.get";
import { getConfigByTheme } from "../../common/charts/config/defaultConfig";
import { matchesMediaQuery } from "../../../../common/components/responsive-render/ResponsiveRender";
import { ThemeContainer } from "../../../../common/hooks/useTheme";

const GraphPanel = ({ chartForecasts, charts, forecastDate }) => {
  const { theme } = ThemeContainer.useContainer();
  return (
    <Stack spacing={[4, null, 5]}>
      {charts.map(item => (
        <Box key={item.chart.name}>
          <Box
            height={200}
            pt={[3, null, 4]}
            borderTopColor={theme.components.charts.border.bg}
            borderTopWidth="2px"
            borderTopStyle="solid"
          >
            <item.chart
              period="daily"
              collection={chartForecasts}
              config={getConfigByTheme(theme)}
              date={forecastDate}
            />
          </Box>
          <Box>
            <Stack
              isInline={matchesMediaQuery(["tablet", "desktop"]) ? true : false}
              spacing={[2, null, 3]}
              mt={[3, null, 2]}
              p={2}
              align={["left", null, "center"]}
              bg={theme.components.charts.labels.bg}
              borderBottomColor={theme.components.charts.border.bg}
              borderBottomWidth="3px"
              borderBottomStyle="solid"
            >
              {item.label.map(labelConfig => (
                <Stack isInline spacing={3} align="center" key={labelConfig.text}>
                  <Stack isInline spacing={2}>
                    <Box
                      h="15px"
                      w="15px"
                      alignSelf="center"
                      borderRadius="50%"
                      style={{
                        backgroundColor: labelConfig.color
                      }}
                    ></Box>
                    <Text
                      fontFamily="label"
                      fontWeight="bold"
                      fontSize="xs"
                      textTransform="uppercase"
                      letterSpacing="widest"
                    >
                      {labelConfig.text}
                    </Text>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Box>
      ))}
    </Stack>
  );
};

export default GraphPanel;
