import React, { useState, useEffect } from "react";
import { Flex, Heading, Button, Box, Text } from "pws-design-system/design-system";
import _capitalize from "lodash.capitalize";
import _isNil from "lodash.isnil";
import ResponsiveRender from "../../../../../common/components/responsive-render";
import { DailySummaryCardDisplay } from "../daily-summary/DailySummaryCard";
import ContentPanel from "../observation-history/components/ContentPanel";
import useDailySummaryQuery from "../daily-summary/useDailySummaryQuery";
import useObservationHistoryQuery from "../observation-history/useObservationHistoryQuery";
import HistoricalCardWrapper from "./HistoricalCardWrapper";
import { CardPeriod, CardView } from "../../../../../../types/enums";
import { SegmentedControl } from "../../../../../common/components/segmented-control/";
import useLocalStorage from "../../../../../common/hooks/useLocalStorage";
import { LocalStorageKeys } from "../../../../../../types/enums";
import ErrorBoundary from "../../../../../common/components/error-boundary";
import { UseDateContainer } from "../../../../../common/hooks/stores/useDateStore";
import { ThemeContainer } from "../../../../../common/hooks/useTheme";
import { convertToLocalTime, convertToTimeZone } from "date-fns-timezone";
import { toDate, format } from "date-fns-tz";

interface HistoricalCardProps {
  placeId: string;
  timezone: string;
  initialPeriod: CardPeriod;
  initialHistoricalView: CardView;
  initialSummaryView: CardView;
}

const HistoricalCard = ({
  placeId,
  timezone,
  initialHistoricalView = CardView.Graph,
  initialSummaryView = CardView.Graph
}: HistoricalCardProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  const [historyView, setHistoryView] = useLocalStorage(
    LocalStorageKeys.HistoricalCardSummaryView,
    initialHistoricalView
  );

  const [summaryView, setSummaryView] = useLocalStorage(
    LocalStorageKeys.SummaryCardView,
    initialSummaryView
  );

  const { date, period, setDate, setPeriod } = UseDateContainer.useContainer();

  const [summary, state] = useDailySummaryQuery({ placeId, date, timezone, period });

  const [chartModel, queryState] = useObservationHistoryQuery({
    placeId,
    date,
    timezone,
    period
  });

  const handleDateChange = (val: Date) => {
    setDate(val);
  };

  const handlePeriodChange = (val: CardPeriod) => {
    setPeriod(val);
  };

  const handleSummaryViewChange = (val: CardView) => {
    setSummaryView(val);
  };

  const handleHistoryViewChange = (val: CardView) => {
    setHistoryView(val);
  };

  return (
    <HistoricalCardWrapper
      heading={`${_capitalize(period)} Summary`}
      period={period}
      date={date}
      timezone={timezone}
      dateChangeHandler={handleDateChange}
      periodChangeHandler={handlePeriodChange}
      viewChangeHandler={handleSummaryViewChange}
      summaryView={summaryView}
    >
      <DailySummaryCardDisplay summary={summary} state={state} view={summaryView} />
      <Box my={4} bg="brand.gray.base" height="4px" />
      <Flex align="center" mb={3}>
        <Heading as="h2" variant="subheadline">
          Observation History
        </Heading>
        <Text variant="caption2" color="brand.gray.800" ml={2} mt={2}></Text>
        <ResponsiveRender size={["tablet", "desktop"]}>
          <SegmentedControl
            ml="auto"
            defaultValue={historyView}
            onChange={handleHistoryViewChange}
            {...theme.components.card.historical.unitToggle}
          >
            <Button value={CardView.Graph}>Graphs</Button>
            <Button value={CardView.Tabular}>Tabular</Button>
          </SegmentedControl>
        </ResponsiveRender>
      </Flex>
      <ContentPanel
        chartModel={chartModel}
        state={queryState}
        date={new Date(`${date}T00:00:00`)}
        view={historyView}
        period={period}
      />
    </HistoricalCardWrapper>
  );
};

const HistoricalCardWithErrorBoundary: React.FC<HistoricalCardProps> = (
  props
): React.ReactElement => {
  return (
    <ErrorBoundary variant="card">
      <HistoricalCard {...props} />
    </ErrorBoundary>
  );
};

export default HistoricalCardWithErrorBoundary;
